import React from "react";
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeCol } from '../css/themes';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import "../fonts/akrobat.css";
import "../fonts/gilroy.css";


const Contactpage = () => {

    const themeColor = createTheme(themeCol);

    return(
        <div align="center">
        {document.title = "Контакты / besthashtag.ru"}
        <ThemeProvider theme={themeColor}>
        <Box //---BOX 
          id="main-box"
          sx={{ width: '95%',
                maxWidth: "1000px",
                marginTop: 12,
                paddingBottom: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                alignContent: 'center',
                align: 'center',
                borderRadius: 4, borderColor: 'n2.main', borderStyle: 'solid', boxShadow: 0, borderWidth: 0,
              }}>

        <Avatar sx={{ m: 1, marginTop: 4, bgcolor: 'n4.main' }}>
          <LocalPhoneOutlinedIcon />
        </Avatar> 

        <Typography variant="body1" 
                        gutterBottom 
                        align="center" 
                        sx={{marginTop: 0.5, mb: 4, paddingLeft: 0, 
                            color: 'n4.main',
                            fontSize: '40px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 200,}}>
        Контакты
        </Typography>              
            <Typography variant="body1" 
                            gutterBottom 
                            align="center" 
                            sx={{marginTop: 1, 
                                paddingLeft: 0,
                                color: 'n5.main',
                                fontSize: '18px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', 
                                fontWeight: 400, fontStyle: 'normal',
                            }}>

Благов Роман Юрьевич<br></br><br></br>

<b>ИНН:</b> 230103307430<br></br><br></br>

<b>Адрес электронной почты:</b> <a href="mailto:tekuiza@mail.ru">admin@besthashtag.ru</a><br></br>
<b>Тел.:</b> +7-928-4005-405<br></br><br></br>
            
            </Typography>


        </Box>  
        <Box>
            <Typography mt={4} component="h1" variant="caption" align='center'>
                <Link href="/">
                    {"На главную"}
                </Link>
            </Typography>  
        </Box>                   
        </ThemeProvider>   
        </div>
    )
}

export {Contactpage};