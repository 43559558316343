import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { themeCol, stylePasswordField } from '../css/themes';
import Typography from '@mui/material/Typography';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import "../fonts/akrobat.css";
import "../fonts/gilroy.css";

/************************************************************************************************** */
//***************************      Диалог добавления категории на оплату      ***********************/
/************************************************************************************************** */

export function DialogAddTariff(props) {

  const themeColor = createTheme(themeCol);

  const [selectedTariff, setSelectedTariff] = useState("");     //  Выбранный тариф
  const [menuTariff, setMenuTariff] = useState([]);             //  Список тарифов для нисподающего меню
  const [nameProfession, setNameProfession] = useState();       //  Наименование профессии для заголовка
  


  const { onClose, 
          tariff,               // Список всех тарифов
          selectedCategory,     // id Категории на которой нажали кнопку
          categories,           // Категории пользователя
          setBills,             // Фукция установки счетов пользователя
          setBillAmountAll,     // Функция счета общего
          open } = props;
  
  
  useEffect(() => {           
    if (open) {   
        // Определяем тип категории - Основная или дополнительная
        let typeCategory = 1;
        let menuTariffTemp = [];
        let firstIter = true;

        categories.forEach((item, index, array) => {
            if (Number(item.id) === Number(selectedCategory)) { // Ищем выбранную категорию
                typeCategory = item.main_category;
                setNameProfession(item.name_profession);
            }
        });
       
        // Формируем источник строк для выпадающего меню, заодно находим минимальное значение для списка
        tariff.forEach((item, index, array) => {
            if (Number(item.main_category) === Number(typeCategory)) {
                if (firstIter) {
                    setSelectedTariff(item.id);
                    firstIter = false;
                };
                menuTariffTemp.push({"id": item.id, "tariff": item.tariff_name + " - " + item.tariff_amount + "₽"});
            }
        });
        setMenuTariff(menuTariffTemp);
    }   
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  async function addTariff(id) {
    
    let obj = categories.find(o => Number(o.id) === Number(selectedCategory));

    if (!obj) {
        return;
    }
    
    let chooseTariff = tariff.find(o => Number(o.id) === Number(id));
    if (!chooseTariff) {
        return;
    }

    // ----------------------- В bills добавляем счет

    //  id              - глобальный id профессии пользователя 
    //  id_user         - id пользователя (auth_users)
    //  id_profession   - id профессии
    //  name_profession - наименование профессии
    //  main_category   - флаг основной категории
    //  id_tariff       - id тарифа (s_tariff)
    //  tariff_name     - наименование тарифа
    //  amount          - сумма
    let newBill = [{
        id: Number(selectedCategory),
        id_user: obj.id_user,
        id_profession: obj.id_profession,
        name_profession: obj.name_profession,
        main_category: obj.main_category,
        id_tariff: chooseTariff.id,
        tariff_name: chooseTariff.tariff_name,
        amount:  chooseTariff.tariff_amount
    }]   
    
    setBills(bills => [...bills, ...newBill]);
    setBillAmountAll(billAmountAll => billAmountAll + chooseTariff.tariff_amount);
    
  }    
  

  const handleAdd = () => {
    addTariff(selectedTariff);
    onClose();

  }

  const handleClose = () => {
    onClose();
  }
     
  return (
          <>
            <ThemeProvider theme={themeColor}> 
            <Dialog open={open} onClose={handleClose} fullWidth>
                
                <DialogTitle sx={{color: 'n5.main'}}>
                <Typography variant="body1" 
                            gutterBottom 
                            align="left" 
                            sx={{marginTop: 0.5, mb: 0, paddingLeft: 0, 
                                color: 'n5.main',
                                fontSize: '20px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400,}}>
                Выберите тариф
                </Typography> 
                </DialogTitle>
              
                <DialogContent>
                <Typography variant="body1" 
                            align="center" 
                            color="n5.main"
                            fontWeight="400"
                            sx={{marginTop: 0.2, mb: 0, paddingLeft: 0, 
                                 fontSize: '16px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;',}}>
                {nameProfession}
                </Typography>

                <FormControl sx={{ mt: 1 }} variant="outlined" fullWidth align="left"  >
                <InputLabel id="select-sex-label" sx={{
                        fontSize: '16px',
                        fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,
                    }}>Тариф</InputLabel>
                <Select labelId="select-tariff-label"
                        id="input-select-tariff-label"
                        defaultValue = ""
                        value={selectedTariff}
                        label="Тариф"
                        sx ={stylePasswordField} 
                        inputProps={{  //Работает
                                    sx: {
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                        paddingLeft: '20px',
                                        fontSize: '14px',
                                        fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,
                                    },
                                    }} 
                        onChange={(e) => setSelectedTariff(e.target.value)}>
                    {menuTariff?.map((t) => (
                        <MenuItem key={t.id} 
                                value={t.id} 
                                sx={{fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,}}>
                        {t.tariff}
                        </MenuItem>))
                    } 
                </Select>
                </FormControl>
                </DialogContent>
              
                <DialogActions>
                    <Button onClick={handleAdd} sx={{color: 'loginUser.main'}}>OK</Button>
                    <Button onClick={handleClose}>ОТМЕНА</Button>
                </DialogActions>
            </Dialog>
            </ThemeProvider>
          </>
  );
}
