import React from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { themeCol } from '../css/themes';
import { Link } from "react-router-dom";
import AppRegistrationRoundedIcon from '@mui/icons-material/AppRegistrationRounded';

import "../fonts/akrobat.css";
import "../fonts/gilroy.css";

/************************************************************************************************** */
//***************************      Кнопка регистрации                 *******************************/
/************************************************************************************************** */
export function ButtonRegistration(props) {
  
    const themeColor = createTheme(themeCol);
  
    return (
          <>
            <ThemeProvider theme={themeColor}> 
            <Button size="small" 
                    variant="contained"
                    startIcon={<AppRegistrationRoundedIcon />} 
                    component={Link} 
                    to={"/registration"}
                    sx={{borderRadius: '10px',
                        bgcolor: 'guestUser.main',
                        color: 'black.main', 
                        '&:hover': {
                                    backgroundColor: 'guestUserLight.main'
                        }, 
                        mt: 2, 
                        mb: 2, 
                        width: 300, 
                        height: 40,
                        fontSize: '16px', fontFamily: 'Akrobat, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
                        }}
                    >
                Зарегистрироваться
            </Button>
            </ThemeProvider>
          </>
  );
}
