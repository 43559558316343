import React from "react";
//import { useContext } from "react";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeCol } from '../css/themes';

import line from "../images/guide/line.png";
import "../fonts/akrobat.css";
import "../fonts/gilroy.css";

import { observer } from "mobx-react-lite"
//import { ContextStore } from '../index';


const Notfoundpage = () => {
    // const {store} = useContext(ContextStore);
    const themeColor = createTheme(themeCol);

    return (
        <div align="center">
        {document.title = "404 / besthashtag.ru"}
            <Box //---BOX Частотностностью общих хештегов
                  sx={{
                        width: '95%',
                        maxWidth: "1000px",
                        marginTop: 12,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: 4,
                        borderColor: 'n2.main',
                        borderStyle: 'solid',
                        boxShadow: 0,
                        borderWidth: 0,
                        pt: 0,
                        pl: 0,
                        pr: 0,
                        pb: 0,
                  }}
        
                  >
        <ThemeProvider theme={themeColor}>
            <Typography variant="body1" 
                            gutterBottom 
                            component="p"
                            align="center" 
                            sx={{marginTop: 0, 
                                mb:0,
                                paddingLeft: 0,
                                color: 'n3.main',
                                fontSize: '240px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 200,
                            }}>
            #404
            </Typography>
            <Typography variant="body1" 
                            gutterBottom 
                            component="p"
                            align="center" 
                            sx={{marginTop: 0, 
                                mb:3,
                                paddingLeft: 0,
                                color: 'n5.main',
                                fontSize: '20px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 200,
                            }}>
            Прекрасная была страница, каких больше не делают
            </Typography> 
            <img src={line} alt="line" width="300px" />
            <Typography variant="body1" 
                            gutterBottom 
                            component="p"
                            align="center" 
                            sx={{marginTop: 3, 
                                mb:0,
                                paddingLeft: 0,
                                color: 'n5.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 200,
                            }}>
            Попробуйте начать все сначала и поищите что-нибудь другое.
            </Typography>
            <Typography variant="body1" 
                            gutterBottom 
                            component="p"
                            align="center" 
                            sx={{marginTop: 1, 
                                mb:3,
                                paddingLeft: 0,
                                color: 'n5.main',
                                fontSize: '12px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 200,
                            }}>
            Любопытный факт - в одной известной 
            соцсети хештег #404 встречается около полумиллиона раз
            </Typography>
           </ThemeProvider> 
           <Button size="small" 
                    variant="contained"
                    startIcon={<CottageOutlinedIcon />} 
                    component={Link} 
                    to={"/"}
                    sx={{borderRadius: '10px',
                        bgcolor: 'n5.main',
                        color: 'white', 
                        '&:hover': {
                                    backgroundColor: 'n4_1.main'
                        }, 
                        mt: 0, 
                        width: 300, 
                        height: 40,
                        fontSize: '16px', fontFamily: 'Akrobat, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
                        }}
            >
                На главную
            </Button>
           </Box> 
        </div>
    )
}

export default observer(Notfoundpage);