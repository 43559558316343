import React, { useContext, useState, useEffect } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { themeCol, stylePasswordField } from '../css/themes';
import Typography from '@mui/material/Typography';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import DataService from "../services/DataService";
import { ContextStore } from '../index';

import "../fonts/akrobat.css";
import "../fonts/gilroy.css";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

/************************************************************************************************** */
//***************************      Диалог добавления категории                ***********************/
/************************************************************************************************** */

export function DialogAddCategory(props) {

  const {store} = useContext(ContextStore);
  const themeColor = createTheme(themeCol);

  const [selectedProfession, setSelectedProfession] = useState("");     //  Выбранная профессия
  const [menuProfession, setMenuProfession] = useState([]);             //  Список профессий для нисподающего меню

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarText, setSnackBarText] = useState('');
  const [snackBarStatus, setSnackBarStatus] = useState('');

  const [firstCategory, setFirstCategory] = useState(false);
    
  const { onClose, 
          profession,               // Список всех профессий (справочник)
          categories,               // Список всех категорий пользователя
          setCategories,            // Функция изменения категорий пользователя
          open 
        } = props;
  
  
  useEffect(() => {           
    
    if (open) {   
        
        let menuProfessionTemp = [];
        let firstIter = true;
        
        if (Object.keys(categories).length === 0) {
            setFirstCategory(true);
        }    

        // Формируем источник строк для выпадающего меню
        profession.forEach((item, index, array) => {
            if (firstIter) {
                setSelectedProfession(item.id);
                firstIter = false;
            };
            menuProfessionTemp.push({"id": item.id, "profession": item.profession_name});
        });
        
        setMenuProfession(menuProfessionTemp);
    }   

  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps
    
  async function addProfession(id) {
    
    const userID = store.user.userID;
    let main_category = 0;
    if (Object.keys(categories).length === 0) {
        main_category = 1;
    } else {
        main_category = 0;
    }
    const response = await DataService.addCategory(userID, id, main_category);
    
    if (response.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
      store.setAuth(false);
      store.setStatus(response.status);
      store.setUser();
      store.setErrorStatusMessage(response.data);
      return;
    }
    
    //Считываем новый список шаблонов
    const responseList = await DataService.getCategories(userID);
    if (response.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
      store.setAuth(false);
      store.setStatus(response.status);
      store.setUser();
      store.setErrorStatusMessage(response.data);
      return;
    }
    
    //Передаем родителю выбранный шаблон и список всех шаблонов 
    setCategories(responseList.data);
    setSnackBarText("Добавлена категория"); setSnackBarStatus('success'); setOpenSnackBar(true);
    
  }    
  

  const handleAdd = () => {
    
    let chooseProfession = categories.find(o => o.id_profession === selectedProfession)
    if (chooseProfession) {
        setSnackBarText("Такая категория есть"); setSnackBarStatus('error'); setOpenSnackBar(true);
        return;
    }
    
    addProfession(selectedProfession);
    onClose();

  }

  const handleClose = () => {
    onClose();
  }

  const handleCloseSnackBar = (event, reason) => {  //----- Закрытие снекбара 
    if (reason === 'clickaway') {
      return;
    }
      setOpenSnackBar(false);
};
     
  return (
          <>
            <ThemeProvider theme={themeColor}> 
            <Dialog open={open} onClose={handleClose} fullWidth>
                
                <DialogTitle sx={{color: 'n5.main'}}>
                <Typography variant="body1" 
                            gutterBottom 
                            align="left" 
                            sx={{marginTop: 0.5, mb: 0, paddingLeft: 0, 
                                color: 'n5.main',
                                fontSize: '20px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400,}}>
                Выберите категорию
                </Typography> 
                </DialogTitle>
              
                <DialogContent>
                {firstCategory &&
                <Typography variant="body1" 
                            align="center" 
                            color="n5.main"
                            fontWeight="400"
                            sx={{marginTop: 0.2, mb: 0, paddingLeft: 0, 
                                 fontSize: '16px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;',}}>
                Выбранная категория будет основной
                </Typography>
                }                

                <FormControl sx={{ mt: 1 }} variant="outlined" fullWidth align="left"  >
                <InputLabel id="select-sex-label" sx={{
                        fontSize: '16px',
                        fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,
                    }}>Категория</InputLabel>
                <Select labelId="select-tariff-label"
                        id="input-select-tariff-label"
                        defaultValue = ""
                        value={selectedProfession}
                        label="Категория"
                        sx ={stylePasswordField} 
                        inputProps={{  //Работает
                                    sx: {
                                        paddingTop: '10px',
                                        paddingBottom: '10px',
                                        paddingLeft: '20px',
                                        fontSize: '14px',
                                        fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,
                                    },
                                    }} 
                        onChange={(e) => setSelectedProfession(e.target.value)}>
                    {menuProfession?.map((t) => (
                        <MenuItem key={t.id} 
                                value={t.id} 
                                sx={{fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,}}>
                        {t.profession}
                        </MenuItem>))
                    } 
                </Select>
                </FormControl>
                </DialogContent>
              
                <DialogActions>
                    <Button onClick={handleAdd} sx={{color: 'loginUser.main'}}>OK</Button>
                    <Button onClick={handleClose}>ОТМЕНА</Button>
                </DialogActions>
            </Dialog>

            {/************************************************************************************************/}
        {/*************************               СНЕК - БАР                ******************************/}
        {/************************************************************************************************/} 
        <Snackbar open={openSnackBar} 
                  autoHideDuration={3000} 
                  onClose={handleCloseSnackBar}
                  anchorOrigin={{ vertical: 'bottom',
                                  horizontal: 'center', }}>
          <Alert onClose={handleCloseSnackBar} severity={snackBarStatus} variant='standard' sx={{ width: '100%' }}>
            {snackBarText}
          </Alert>
        </Snackbar>         

            </ThemeProvider>
          </>
  );
}
