import React, { useState, useContext, useEffect } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';


import { themeCol, styleTextField } from '../css/themes';

import DataService from "../services/DataService";
import { ContextStore } from '../index';

/************************************************************************************************** */
//***************************      Диалог РЕДАКТИРОВАНИЯ набора в БД      *******************************/
/************************************************************************************************** */
export function DialogEditUserTemplate(props) {

  const {store} = useContext(ContextStore);
  const themeColor = createTheme(themeCol);

  const { onClose, setUserTemplate, setChooseTemplateUser, chooseTemplateUser, 
          setSnackBarText, setSnackBarStatus, setOpenSnackBar, open } = props;
  const [editTemplateUserText, setEditTemplateUserText] = useState("chooseTemplateUser.nameTemplate");

   useEffect(() => { 

     setEditTemplateUserText((chooseTemplateUser?.nameTemplate) ? chooseTemplateUser.nameTemplate : '');

   }, [chooseTemplateUser]);
  
  async function editTemplateUser(id, userID, nameTemplate) {

    
    //Редактируем набор
    if (!nameTemplate) {
      setOpenSnackBar({show: true, text:"Пустое имя набора", status: "error"});
      return;
    }
    const response = await DataService.editTemplate(id, userID, nameTemplate);
    
    if (response.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
      store.setAuth(false);
      store.setStatus(response.status);
      store.setUser();
      store.setErrorStatusMessage(response.data);
      return;
    }
    //const idTemplate = id;

    //Считываем новый список наборов
    const responseList = await DataService.getTemplateUser(userID);
    if (response.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
      store.setAuth(false);
      store.setStatus(response.status);
      store.setUser();
      store.setErrorStatusMessage(response.data);
      return;
    }
    
    //Передаем родителю выбранный набор и список всех наборов 
    setUserTemplate(responseList.data);
    setChooseTemplateUser({id: id, nameTemplate: nameTemplate})
    //setEditTemplateUserText('');
    onClose();
    setSnackBarText("Имя набора отредактировано"); setSnackBarStatus('success'); setOpenSnackBar(true);
  }    
  
  const handleEdit = () => {
    editTemplateUser(chooseTemplateUser.id, store.user.userID, editTemplateUserText);
  }

  const handleClose = () => {
    //setEditTemplateUserText('');
    onClose();
  }
  
  
  return (
          <>
            <ThemeProvider theme={themeColor}> 
            <Dialog open={open} onClose={handleClose} fullWidth>
              <DialogTitle>
              <Typography variant="body1" 
                            gutterBottom 
                            align="left" 
                            sx={{marginTop: 0.5, mb: 0, paddingLeft: 0, 
                                color: 'n4.main',
                                fontSize: '20px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400,}}>
                Редактирование наименования набора
              </Typography>  
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                <Typography variant="body1" 
                            align="center" 
                            color="n5.main"
                            fontWeight="400"
                            sx={{marginTop: 0.2, mb: 0, paddingLeft: 0, 
                                 fontSize: '16px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;',}}>
                  Введите новое имя набора
                </Typography>  
                </DialogContentText>
                <TextField
                  autoFocus
                  size='small'
                  margin="dense"
                  id="nameTemplate"
                  label="Набор"
                  fullWidth
                  variant="outlined"
                  //defaultValue={chooseTemplateUser.nameTemplate}
                  value={editTemplateUserText}
                  onChange={(e) => setEditTemplateUserText(e.target.value)}
                  sx = {styleTextField}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleEdit} sx={{color: 'loginUser.main'}}>Сохранить</Button>
                <Button onClick={handleClose}>Отмена</Button>
              </DialogActions>
            </Dialog>
            </ThemeProvider>
          </>
  );
}
