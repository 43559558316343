
import React from "react";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeCol } from '../../css/themes';
import { observer } from 'mobx-react-lite'
//import { ContextStore } from '../../index';


const SendingEmailRecoveryPasswordPage = () => {
    
    //const {store} = useContext(ContextStore); 
    const themeColor = createTheme(themeCol);
    
    return(

        <div style={{
                     position: 'absolute', left: '50%', top: '50%',
                     transform: 'translate(-50%, -50%)'
                    }}>
            <ThemeProvider theme={themeColor}>             
            <Alert severity="success" 
                   
                   variant="outlined" 
                   sx={{borderRadius: 5, 
                        backgroundColor: "superLightLoginUser.main",
                        borderColor: "darkLoginUser.main"
                       }}>
                    
                    <AlertTitle sx={{color: 'n5.main', mb:3}}>Письмо с ссылкой на сброс пароля было отправлено на почту. Откройте письмо, 
                        чтобы завершить процедуру восстановление пароля.
                    </AlertTitle>
                    Если вы не получили письмо в течении 15 минут, проверьте в почте папку СПАМ (SPAM, Junk mail). Если вы обнаружили там письмо сброса пароля, пометьте его "Не спам"
                     и активируйте ссылку.
            </Alert>
            </ThemeProvider>    
        </div>
        
    )
}

export default observer(SendingEmailRecoveryPasswordPage);