import React from "react";
import { useContext } from "react";
import Typography from '@mui/material/Typography';
import { observer } from "mobx-react-lite"
import { ContextStore } from '../../index';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeCol } from '../../css/themes';
import { ButtonProfile } from "../../component/ButtonProfile"

import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import "../../fonts/akrobat.css";
import "../../fonts/gilroy.css";

const ErrorNotPay = () => {
    
    const {store} = useContext(ContextStore);
    const themeColor = createTheme(themeCol);

    return(
        <div align="center">
        {document.title = "Нет средств / besthashtag.ru"}
        <ThemeProvider theme={themeColor}>
            <Avatar sx={{ mb: 4, marginTop: 16, bgcolor: 'guestUserDark.main' }}>
                <AccountCircleIcon />
            </Avatar>
            <Typography variant="body1" 
                                gutterBottom 
                                align="center" 
                                sx={{marginTop: 0, 
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    color: 'guestUserDark.main',
                                    fontSize: '40px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 200,
                                }}>
                {store.isAuth ? `Добро пожаловать, ${store.user.email}` : 'Добро пожаловать, гость' }
            </Typography>
            <Typography variant="body1" 
                                gutterBottom 
                                align="center" 
                                sx={{marginTop: 3, 
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    color: 'n5.main',
                                    fontSize: '18px', fontFamily: 'Gilroy, Arial, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                                }}>
                Вы авторизованы, но средства на счету закончились. Пополните счёт в личном кабинете.
            </Typography>

            <ButtonProfile />

        </ThemeProvider>    
        </div>
    )
}

export default observer(ErrorNotPay);