import React, { useContext, useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PsychologyAltOutlinedIcon from '@mui/icons-material/PsychologyAltOutlined';
import Countdown, { zeroPad } from 'react-countdown';

import "../../fonts/akrobat.css";
import "../../fonts/gilroy.css";

import { themeCol, styleTextField } from '../../css/themes';
import { observer } from 'mobx-react-lite'
import { ContextStore } from '../../index';

//-----------------DEPLOY
const countSec = 60000; // Счетчик на сброс - 60 сек

const RecoveryPasswordPage = () => {

  const themeColor = createTheme(themeCol);
  const {store} = useContext(ContextStore);

  //-----Состояния
  const [email, setEmail] = useState("");
  const [visibleError, setVisibleError] = useState(' ');
  const [loading, setLoading] = useState(false);
  const [colorHelpText, setColorHelpText] = useState(true);
  const [timer, setTimer] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const [sendingMail, setSendingMail] = useState(false);

  const renderer = ({ hours, minutes, seconds, completed, props }) => {
    if (completed) {      
      return <span></span>;
    } else {
      return <span>До следующего сброса {zeroPad(minutes)}:{zeroPad(seconds)}</span>;
    }
  };

   useEffect( () => { 
     const t = localStorage.getItem('timerRecoveryPassword');
     if (t) {  
       if (t - (Date.now()) > 0) {  
         setTimer(Date.now() + (t - (Date.now())));
       }
     }  
   }, []);

  
  const handleSubmit = async (event) => {         //-----Хендлер на кнопку ВОЙТИ
    event.preventDefault();
    
    setLoading(true);
    await store.recoveryPassword(email);
    //localStorage
    setLoading(false);

    if (store.status !== 200) {
      localStorage.setItem('timerRecoveryPassword', Date.now() + countSec);
      setTimer(Date.now() + countSec);  
      setColorHelpText(true);
      setVisibleError(store.errorStatusMessage); 
      return;   
    }
    if (store.status === 200 && parseInt(store.errorStatusMessage) === 1) {  
      setColorHelpText(false);
      setVisibleError("Успех");
      setSendingMail(true);
      return;
    }
    if (store.status === 200 && parseInt(store.errorStatusMessage) === 0) {        
      setTimer(Date.now() + countSec);
      localStorage.setItem('timerRecoveryPassword',toString(Date.now() + countSec))
      setColorHelpText(true);
      setVisibleError("Возможна ошибка при вводе E-mail");
      }

  }

/******************************************************************************************************************************/
/*                                               Основной блок                                                                */
/******************************************************************************************************************************/  

  return(
    <div>
    {(sendingMail) ? <Navigate to="/sendingemailrecoverypassword" /> : null}
    {document.title = "Восстановление пароля / besthashtag.ru"}
    <ThemeProvider theme={themeColor}>
      <Container component="main" maxWidth="xs">
      <CssBaseline />
        <Box //---Основной BOX который содержит все компоненты
          sx={{
            marginTop: 25,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: 4,
            boxShadow: 3,
            pt: 3,
            px: 2,
            pb: 4,
          }}
        >  
          <Avatar sx={{ m: 1, 
                        bgcolor: 'n4.main' 
                      }}>
            <PsychologyAltOutlinedIcon />
          </Avatar>
          
          <Typography component="h5" variant="h5" sx={{ mt: 2,
                                                        color: 'n4.main',
                                                        fontSize: '40px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 200,
                                                      }}>
            Сброс пароля
          </Typography>

          <Box component="form" //BOX с поля ввода и кнопкой  
              onSubmit={handleSubmit} 
              noValidate sx={{ mt: 1.5, }}>

            {/* Поле для ввода EMAIL */}
            <FormControl sx={{ mt: 5}} variant="outlined" fullWidth >
              <TextField 
                margin="none"
                required
                fullWidth
                id="email"
                label="Введите E-mail"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx = { styleTextField }
                inputProps={{  //Работает
                    sx: {
                      paddingLeft: '20px',
                      fontSize: '14px',
                    }, 
                }}    
              />
              <FormHelperText id="email-filled-weight-helper-text" sx={{color: (colorHelpText ? 'errorUser.main' : 'n5.main')
                                                                        //'#ff0000'
                                                                        }}>
                    {visibleError}
              </FormHelperText>   
            </FormControl> 
            
            {/* Кнопка отправки данных */}
            <Button
              type="submit"
              fullWidth
              disabled={isDisable}
              variant="contained"
              sx={{ 
                    mt: 1, mb: 2, 
                    bgcolor: 'n5.main',
                    color: 'n0.main', 
                    '&:hover': {
                                backgroundColor: 'n4_1.main'
                    },
                    borderRadius: '20px'
                  }}>
                Восстановить
            </Button>
                 
            {/* -----------------------------Бэкдроп-------------------- */}
            <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            
            {/* -----------------------------Сетка с забыли пароль и зарегестрироваться----------- */}
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography component="h1" variant="caption" align='left'>
                  <Link href="/login"  sx={{color: 'n4.main'}}>
                      {"Войти"}
                  </Link>
                </Typography>  
              </Grid>
              <Grid item xs={6}>
                <Typography component="h1" variant="caption" align="right">
                  <Link href="/registration" sx={{color: 'n4.main'}}>
                    {"Зарегистрироваться"}
                  </Link>
                </Typography> 
              </Grid>
            </Grid>
            
          </Box>
        
        </Box>

        <Box sx={{marginTop: 3}}>
            <Typography component="h1" variant="body2" align="center" sx={{color: 'errorUser.main'}}>     
                <Countdown
                    key={timer}
                    date={timer}
                    renderer={renderer}
                    onStart={() => {
                                        setIsDisable(true);
                                }}
                    onComplete={() => {
                                        localStorage.removeItem('timerRecoveryPassword')
                                        setIsDisable(false);
                                }}
                />
            </Typography>
        </Box>  
      </Container>
    </ThemeProvider>
    </div>   
  )
}

export default observer(RecoveryPasswordPage);