import $api from "../http";

export default class AuthService {
    
    static async login(email, password) {
        return new Promise((resolve, reject) => {
                $api.post('/login', {email: email, password:password}).then (
                    (response) => {
                                    //console.log('login AuthSevice');
                                    //console.log(response.data);
                                    return resolve({"status": 200, "response": response.data});
                                  },
                    // (reject) =>   {
                    //                 console.log('NOT login AuthSevice');
                    //                 console.log(reject.data);
                    //                 return resolve({"status": 400, "response": reject.response.data.message});
                    //               },
                    (error) =>   {
                                    console.log('ERROR login AuthSevice');
                                    console.log(error.response);
                                    let statusText = "";
                                    if (error.response.status === 404) {
                                        statusText = "Не найден сервер авторизации"
                                    }
                                        
                                    if (error.response.status === 400) {    
                                        //console.log("-------------");
                                        //console.log(error?.response?.data?.message);
                                        if (error?.response?.data?.message) {
                                            //console.log(error.response.data.message);
                                            statusText = error.response.data.message
                                        } else {
                                            statusText = "Bad request"
                                        }   
                                    } else {
                                        statusText = error.response.statusText
                                    }
                                    
                                    return resolve({"status": error.response.status, "response": statusText});
                                }

                )
        })
    }

    static async registration(email, password, userName, sex) {
        return new Promise((resolve, reject) => { 
            $api.post('/registration', {email: email, password:password, username: userName, sex: sex}).then(
                (response) => {
                                //console.log('registration AuthSevice');
                                //console.log(response.data);
                                return resolve({"status": 200, "response": response.data});
                              },
                (reject)   => {  
                                console.log('NOT registration AuthSevice');
                                //console.log({"status": 400, "response": reject.response.data.message});
                                return resolve({"status": 400, "response": reject.response.data.message});
                              }              
            )
        })
    }

    static async logout() {
        return new Promise((resolve, reject) => { 
            $api.post('/logout').then (
                (response) => {
                                //console.log('logout AuthSevice');
                                //console.log(response.data);
                                return resolve({"status": 200, "response": response.data});
                              },
                (reject)   => {
                                console.log('NOT logout AuthSevice');
                                //console.log({"status": 400, "response": reject.response.data.message}); 
                                return resolve({"status": 400, "response": reject.response.data.message});            
            })
        })
    }

    static async recoveryPassword(email) {
        return new Promise((resolve, reject) => { 
                $api.post('/recoveryPassword', {email: email}).then (
                    (response) => {
                                    //console.log('recoveryPassword AuthSevice');
                                    //console.log(response.data);
                                    return resolve({"status": 200, "response": response.data});
                                  },
                    (reject) =>   {
                                    console.log('NOT recoveryPassword AuthSevice');
                                    //console.log(reject);
                                    return resolve({"status": 400, "response": reject.response.data.message});
                                  }
                )
        })
    }
}