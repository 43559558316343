import React from "react";
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeCol } from '../css/themes';
import Box from '@mui/material/Box';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';

import "../fonts/akrobat.css";
import "../fonts/gilroy.css";


const OfferPage = () => {

    const themeColor = createTheme(themeCol);

    return(
        <div align="center">
        {document.title = "Договор публичной оферты / besthashtag.ru"}
        <Box //---BOX Частотностностью общих хештегов
                  sx={{
                        width: '95%',
                        maxWidth: "600px",
                        marginTop: 12,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: 4,
                        borderColor: 'n2.main',
                        borderStyle: 'solid',
                        boxShadow: 0,
                        borderWidth: 0,
                        pt: 0,
                        pl: 0,
                        pr: 0,
                        pb: 0,
                  }}
        
                  >
        
        <ThemeProvider theme={themeColor}>

            <Avatar sx={{ m: 1, marginTop: 4, bgcolor: 'n4.main' }}>
            <InventoryOutlinedIcon />
            </Avatar>      
            <Typography variant="body1" 
                            gutterBottom 
                            align="center" 
                            sx={{marginTop: 0.5,
                                 mb: 4,  
                                paddingLeft: 0,
                                color: 'n4.main',
                                fontSize: '40px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 200,
                            }}>
            Договор публичной оферты об оказании услуг
            </Typography>  

            <Typography variant="body2" gutterBottom align="left" width="100%" maxWidth="750px"
                            sx={{marginTop: 2, paddingLeft: 2, paddingRight: 2, mb: 3, mr: 0, color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
&nbsp;Благов Роман Юрьевич (далее — Исполнитель) предлагает договор публичной оферты для физических и юридических лиц (далее — Заказчик) о нижеследующем.<br></br>
</Typography>

<Typography variant="body2" align="left" gutterBottom width="100%" maxWidth="750px"
                        sx={{fontSize: '16px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, paddingLeft: 2,}} 
                        color="titleMain.main">
                    1. Термины и определения
</Typography>

<Typography variant="body2" gutterBottom align="left" width="100%" maxWidth="750px"
                            sx={{marginTop: 2, paddingLeft: 2, paddingRight: 2, mb: 3, mr: 0, color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
&nbsp;&nbsp;1.1. Оферта — документ «Договор публичной оферты об оказании услуг», опубликованный  на сайте Исполнителя besthashtag.ru.<br></br>
&nbsp;&nbsp;1.2. Договор Оферты — договор между Исполнителем и Заказчиком об оказании услуг, который заключается через Акцепт Оферты.<br></br>
&nbsp;&nbsp;1.3. Акцепт Оферты — принятие Оферты Заказчиком, когда он обращается к Исполнителю за оказанием услуг.  Акцептом Оферты считается нажатие Заказчиком кнопки «Оплатить» под сформированным заказом на сайте besthashtag.ru.<br></br>
&nbsp;&nbsp;1.4. Тарифы — перечень услуг Исполнителя с ценами, указанными в Прайс-листе на сайте besthashtag.ru.<br></br>
&nbsp;&nbsp;1.5. Заказчик — лицо, совершившее Акцепт Оферты.<br></br>
&nbsp;&nbsp;1.6. Исполнитель — Благов Роман Юрьевич.<br></br>
&nbsp;&nbsp;1.7. Договор не требует скрепления печатями и/или подписания Заказчиком и Исполнителем.<br></br>
</Typography>
<Typography variant="body2" align="left" gutterBottom width="100%" maxWidth="750px"
                        sx={{fontSize: '16px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, paddingLeft: 2,}} 
                        color="titleMain.main">
2. Предмет Договора
</Typography>
<Typography variant="body2" gutterBottom align="left" width="100%" maxWidth="750px"
                            sx={{marginTop: 2, paddingLeft: 2, paddingRight: 2, mb: 3, mr: 0, color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
&nbsp;&nbsp;2.1. Исполнитель оказывает Заказчику услуги в соответствии с условиями Договора Оферты и текущими Тарифами, опубликованными на сайте besthashtag.ru.<br></br>
&nbsp;&nbsp;2.2. Заказчик принимает услуги Исполнителя и полностью их оплачивает.<br></br>
&nbsp;&nbsp;2.3. Если заказчик оставляет отзыв о приобретенных услугах в соцсетях Исполнителя или на его сайте, он дает согласие на размещение персональных данных в отзывах: имени и фамилии.<br></br>
&nbsp;&nbsp;2.4. Заказчик соглашается, что совершая Акцепт Договора он подтверждает, что ознакомлен, согласен, полностью и безоговорочно принимает все условия Договора Оферты.<br></br>
</Typography>
<Typography variant="body2" align="left" gutterBottom width="100%" maxWidth="750px"
                        sx={{fontSize: '16px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, paddingLeft: 2,}} 
                        color="titleMain.main">
3. Срок действия Договора
</Typography>
<Typography variant="body2" gutterBottom align="left" width="100%" maxWidth="750px"
                            sx={{marginTop: 2, paddingLeft: 2, paddingRight: 2, mb: 3, mr: 0, color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
&nbsp;&nbsp;3.1. Договор вступает в силу со дня акцепта заказчиком и действует до полного исполнения сторонами обязательств по Договору.<br></br>
</Typography>
<Typography variant="body2" align="left" gutterBottom width="100%" maxWidth="750px"
                        sx={{fontSize: '16px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, paddingLeft: 2,}} 
                        color="titleMain.main">
4. Стоимость работ и порядок расчетов
</Typography>
<Typography variant="body2" gutterBottom align="left" width="100%" maxWidth="750px"
                            sx={{marginTop: 2, paddingLeft: 2, paddingRight: 2, mb: 3, mr: 0, color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
&nbsp;&nbsp;4.1. Стоимость услуг определяется в соответствии с действующими ценами и прописана на сайте besthashtag.ru.<br></br>
&nbsp;&nbsp;4.2. Оплата производится в рублях перечислением на расчетный счет Исполнителя или платежной картой на сайте besthashtag.ru.<br></br>
</Typography>
<Typography variant="body2" align="left" gutterBottom width="100%" maxWidth="750px"
                        sx={{fontSize: '16px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, paddingLeft: 2,}} 
                        color="titleMain.main">
5. Права и обязанности
</Typography>
<Typography variant="body2" gutterBottom align="left" width="100%" maxWidth="750px"
                            sx={{marginTop: 2, paddingLeft: 2, paddingRight: 2, mb: 3, mr: 0, color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
&nbsp;&nbsp;5.1. Исполнитель обязуется:<br></br>
&nbsp;&nbsp;5.1.1. Оказать Заказчику услуги надлежащего качества в надлежащем объеме и в согласованные Сторонами сроки.<br></br>
&nbsp;&nbsp;5.1.2. Обеспечить полную сохранность предоставленных Заказчиком данных, материалов, а также конфиденциальность информации, полученной им в процессе оказания услуг.<br></br>
&nbsp;&nbsp;5.2. Исполнитель имеет право:<br></br>
&nbsp;&nbsp;5.2.1 Получать от Заказчика необходимые данные и информацию для оказания услуг и полагаться на них без дополнительной проверки.<br></br>
&nbsp;&nbsp;5.2.2. При необходимости привлекать к исполнению услуг сторонних консультантов, специалистов и экспертов.<br></br>
&nbsp;&nbsp;5.3. Заказчик обязуется:<br></br>
&nbsp;&nbsp;5.3.1. Предоставить Исполнителю любые необходимые и достоверные данные и информацию для оказания услуг.<br></br>
&nbsp;&nbsp;5.3.2. Своевременно и полностью оплатить услуги Исполнителя.<br></br>
</Typography>

<Typography variant="body2" align="left" gutterBottom width="100%" maxWidth="750px"
                        sx={{fontSize: '16px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, paddingLeft: 2,}} 
                        color="titleMain.main">
6. Основания и порядок расторжения Договора
</Typography>
<Typography variant="body2" gutterBottom align="left" width="100%" maxWidth="750px"
                            sx={{marginTop: 2, paddingLeft: 2, paddingRight: 2, mb: 3, mr: 0, color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
&nbsp;&nbsp;6.1 После оформления и оплаты заказа  Заказчик имеет право отказаться от услуг Исполнителя и запросить возврат денег, направив письменное уведомление «Отказ от предоставления услуг» на адрес электронной почты admin@besthashtag.ru.<br></br>
&nbsp;&nbsp;6.2 Исполнитель вправе удержать из уплаченной Заказчиком суммы стоимость уже оказанных услуг на момент получения уведомления об отказе.<br></br>
</Typography>
<Typography variant="body2" align="left" gutterBottom width="100%" maxWidth="750px"
                        sx={{fontSize: '16px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, paddingLeft: 2,}} 
                        color="titleMain.main">
7. Разрешение споров
</Typography>
<Typography variant="body2" gutterBottom align="left" width="100%" maxWidth="750px"
                            sx={{marginTop: 2, paddingLeft: 2, paddingRight: 2, mb: 3, mr: 0, color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
&nbsp;&nbsp;7.1. Споры и разногласия по вопросам исполнения Договора разрешаются на переговорах.<br></br>
&nbsp;&nbsp;7.2. Если на переговорах Стороны не смогут достичь согласия, спор передается в суд.<br></br>
</Typography>
<Typography variant="body2" align="left" gutterBottom width="100%" maxWidth="750px"
                        sx={{fontSize: '16px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, paddingLeft: 2,}} 
                        color="titleMain.main">
8. Реквизиты
</Typography>
<Typography variant="body2" gutterBottom align="left" width="100%" maxWidth="750px"
                            sx={{marginTop: 2, paddingLeft: 2, paddingRight: 2, mb: 3, mr: 0, color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
<b>Исполнитель:</b> Благов Роман Юрьевич<br></br><br></br>

<b>ИНН:</b> 230103307430<br></br><br></br>

<b>Р/с:</b> 42301810900049997475<br></br>
<b>Банк:</b> АО «Тинькофф Банк»<br></br>
<b>К/с:</b> 30101810145250000974<br></br>
<b>БИК:</b> 044525974<br></br><br></br>

<b>Адрес электронной почты:</b> admin@besthashtag.ru<br></br>
<b>Тел.:</b> +7-928-20-10-477<br></br><br></br>

______________________  Благов Р.Ю. 
</Typography>
    
    <Box>
        <Typography mt={4} component="h1" variant="caption" align='center'>
              <Link href="/">
                  {"На главную"}
              </Link>
        </Typography>  
    </Box>                     

    </ThemeProvider> 
        
        </Box>
        </div>
    )
}

export {OfferPage};