import React, {useState, useEffect, useContext} from "react";
import "../css/flags/css/flag-icon.css";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Tooltip from '@mui/material/Tooltip';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { Navigate } from "react-router-dom";



import "../App.css";
import DataService from "../services/DataService";
import {observer} from 'mobx-react-lite'
import { themeCol, styleTextField } from '../css/themes';
import { ContextStore } from '../index';
import { DialogAddUserTemplate } from "../component/DialogAddUserTemplate"
import { DialogEditUserTemplate } from "../component/DialogEditUserTemplate"
import { DialogDeleteUserTemplate } from "../component/DialogDeleteUserTemplate"
//import logoVK from "../images/vk-logo-30.png";
//import logoInsta from "../images/insta-logo-30.png";

import "../fonts/akrobat.css";
import "../fonts/gilroy.css";

//const nameServer = window.Configs.hostname
//const fontSizeList = '14px';
const uuid = require('uuid');

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Gettinghashtag = () => {

  const themeColor = createTheme(themeCol);
  const {store} = useContext(ContextStore);
  const {storeData} = useContext(ContextStore);
  const blockTemplate2 = [125, 126, 123, 124]; // Где два блока и более
  const blockTemplate3 = [123, 124];           // Где больше двух блоков 

  const errorNotTown = 'Внимание! Не выбраны топонимы. Отобразятся только глобальные хештеги';

  const [userTemplate, setUserTemplate] = useState([]);                 // БД список пользовательских наборов
  const [commonTemplate, setCommonTemplate] = useState([]);             // БД список общих шаблонов
  const [countryList, setCountryList] = useState([]);                   // БД список регионов
  const [regionList, setRegionList] = useState([]);                     // БД список регионов
  const [townList, setTownList] = useState([]);                         // БД список городов    

  
  const [chooseTemplateCommon, setChooseTemplateCommon] = useState([]); // Выбор общего шаблона
  const [chooseTemplateUser, setChooseTemplateUser] = useState([]);     // Выбор пользовательского набора
  const [chooseCountry, setChooseCountry] = useState([]);               // Выбор страны
  const [chooseRegion, setChooseRegion] = useState([]);                 // Выбор региона
  const [isHaveRegion, setIsHaveRegion] = useState(false);              // Есть ли регионы в стране
  const [chooseTown, setChooseTown] = useState([]);                     // Выбор города 
  const [chooseTownsList, setChooseTownsList] = useState([]);           // Список выбранных городов
 
  const [freqHashtagLocal, setFreqHashtagLocal] = useState(2);          // Выбор частотности локальных
  const [freqHashtagCommon, setFreqHashtagCommon] = useState(3);        // Выбор частотности общих
 
  const [chooseLang, setChooseLang] = useState(1);                      // Выбор языка
  const [chooseSocialNetwork, setChooseSocialNetwork] = useState(1);    // Выбор соцсети

  const [countHashTag, setCountHashTag] = useState(30);                 // Кол-во хештегов всего

  const [idSession, setIdSession] = useState("");                       // Уникальный идентификатор сессии
 
  const [gettingHashtag, setGettingHashtag] = useState("");
  const [chipData, setChipData] = useState([]);


  const [timeWork, setTimeWork] = useState("Время работы");             // Время работы подбора хештегов
  const [countSymbol, setCountSymbol] = useState(0);            // Количество символов у хештегов
  const [visibleLinearProgress, setVisibleLinearProgress] = useState(false);  // Видимость полосы загрузки
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarText, setSnackBarText] = useState('');
  const [snackBarStatus, setSnackBarStatus] = useState('');
    
  const [helperTextHashtag, setHelperTextHashtag] = useState(errorNotTown);   //Вспомогательный текст для поля хештегов (ошибки)
  const [checkedTown, setCheckedTown] = useState([]);                   // Отмеченные пользователем города     

  const [openDialogAddTemplateUser, setOpenDialogAddTemplateUser] = useState(false);                  
  const [openDialogEditTemplateUser, setOpenDialogEditTemplateUser] = useState(false);                  
  //const [templateDialog, setTemplateDialog] = useState("");

  const [openDialogDeleteTemplateUser, setOpenDialogDeleteTemplateUser] = useState(false);                  


  const marksLocal = [
    {
      value: 1,
      label: 'Низкая',
    },
    {
      value: 2,
      label: 'Средняя',
    },
    {
      value: 3,
      label: 'Нормальная',
    },
    {
      value: 4,
      label: 'Высокая',
    },
  ];
  
  const marksCommon = [
    {
      value: 1,
      label: 'Низкая',
    },
    {
      value: 2,
      label: 'Средняя',
    },
    {
      value: 3,
      label: 'Нормальная',
    },
    {
      value: 4,
      label: 'Высокая',
    },
  ];

  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  function valuetext(value) {
    return `${value}`;
  }
  
  function valueLabelFormat(value) {
    return marksLocal[value-1].label;
  }
  
  //---Первоначальная загрузка Шаблонов и регионов. Запускается один раз
  useEffect(() => { 

    if (!store.isAuth) {
      return;
    }   
    //console.log("useEffect ALL");
    async function setParam() {
      //console.log("Вызов store.checkAuth");
      if (!(store.user?.userID))  {
        await store.checkAuth();
      } 
      
      const responseC = await DataService.getCountry();
        if (responseC.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
          // store.setAuth(false);
          // store.setStatus(responseR.status);
          // store.setUser();
          // store.setErrorStatusMessage(responseR.data);
          // return;
        }
        setCountryList(responseC.data);
        //console.log(responseC.data);

      if (!(store.user?.userID))  {
        await store.checkAuth();
      } 
      
      // const responseR = await DataService.getRegions();
      //   if (responseR.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
          
      //   }
      //   setRegionList(responseR.data);
      
      // if (!(store.user?.userID))  {
      //   await store.checkAuth();
      // } 
      const responseTC = await DataService.getTemplateCommon(store.user?.userID, store.user?.category);
      if (responseTC.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
            // store.setAuth(false);
            // store.setStatus(responseTC.status);
            // store.setUser();
            // store.setErrorStatusMessage(responseTC.data);
            // return;
          }
      setCommonTemplate(responseTC.data);
      
      if (!(store.user?.userID))  {
        await store.checkAuth();
      } 
      const responseTU = await DataService.getTemplateUser(store.user?.userID);
        if (responseTU.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
          // store.setAuth(false);
          // store.setStatus(responseTU.status);
          // store.setUser();
          // store.setErrorStatusMessage(responseTU.data);
          // return;
        }
      setUserTemplate(responseTU.data);
      
      const templateParam =  localStorage.getItem('templateParam');
        if (templateParam) {  
           const perem = JSON.parse(templateParam);
           if (perem?.chooseTemplateUser) { setChooseTemplateUser(perem.chooseTemplateUser);}
           if (perem?.chooseTemplateCommon) { setChooseTemplateCommon(perem.chooseTemplateCommon);}
           if (perem?.chooseCountry) { setChooseCountry(perem.chooseCountry);}
           if (perem?.chooseRegion) { setChooseRegion(perem.chooseRegion);}
           if (perem?.chooseTown) { setChooseTown(perem.chooseTown);}
           if (perem?.chooseTownsList) { setChooseTownsList(perem.chooseTownsList);}
           if (perem?.freqHashtagLocal) { setFreqHashtagLocal(perem.freqHashtagLocal);}
           if (perem?.freqHashtagCommon) { setFreqHashtagCommon(perem.freqHashtagCommon);}
           if (perem?.chooseLang) { setChooseLang(perem.chooseLang);}
           if (perem?.chooseSocialNetwork) { setChooseSocialNetwork(perem.chooseSocialNetwork);}
           if (perem?.countHashTag) { setCountHashTag(perem.countHashTag);}
          
        }    
      // Перемещаемся в начало станицы
      window.scrollTo(0, 0);

    }

    setParam();
     
    return () => {

      localStorage.setItem('templateParam', storeData.jsonLocalStorage);
      //window.scrollTo(0, 0);
      //console.log(storeData.jsonLocalStorage);
      //console.log('Вышли со страницы');

    }

    //window.scrollTo(0, 0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //---Отслеживание списка страны (получение списка городов или районов в регионе)
  useEffect(() => {              //Обновление страны
    if (chooseCountry && (chooseCountry.length !== 0 )) {
        //console.log(chooseCountry);
        if (chooseCountry.id === 481) { // Если Россия, то прогружаем регионы
          setIsHaveRegion(false);
          async function rowgetRegions(id) {
            const response = await DataService.getRegions(id);
            if (response.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
              store.setAuth(false);
              store.setStatus(response.status);
              store.setUser();
              store.setErrorStatusMessage(response.data);
              return;
            }
            setRegionList(response.data)
            return response.data;
          }    
          rowgetRegions(chooseCountry.id);
          //console.log()
          setChooseRegion([]);
          setTownList([]);
          setChooseTown([]);
        } else { // Если не Россия, там нет регионов и прогружаем сразу города
          setIsHaveRegion(true);
          async function rowgetTowns(id) {
            const response = await DataService.getTowns(id);
            if (response.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
              store.setAuth(false);
              store.setStatus(response.status);
              store.setUser();
              store.setErrorStatusMessage(response.data);
              return;
            }
            setTownList(response.data)
            return response.data;
          }    
          rowgetTowns(chooseCountry.id);
          //console.log()
          setRegionList([]);
          setChooseRegion([]);
          setChooseTown([]);
        }
    } else {
      setTownList([]);
    }    
  }, [chooseCountry]); // eslint-disable-line react-hooks/exhaustive-deps
  
  //---Отслеживание списка регионов (получение списка городов в регионе)
  useEffect(() => {              //Обновление региона
    if (chooseRegion && (chooseRegion.length !== 0 )) {
        async function rowgetTowns(id) {
          const response = await DataService.getTowns(id);
          if (response.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
            store.setAuth(false);
            store.setStatus(response.status);
            store.setUser();
            store.setErrorStatusMessage(response.data);
            return;
          }
          setTownList(response.data)
          return response.data;
        }    
        rowgetTowns(chooseRegion.id);
        setChooseTown([]);
    } else {
      setTownList([]);
    }    
  }, [chooseRegion]); // eslint-disable-line react-hooks/exhaustive-deps



  useEffect(() => {  //Отслеживание изменения переменных для записив localStorage
      
      let jsonAllParams = "";
      
      jsonAllParams =      `{"chooseTemplateUser": ${JSON.stringify(chooseTemplateUser)}, 
                            "chooseTemplateCommon": ${JSON.stringify(chooseTemplateCommon)}, 
                            "chooseCountry": ${JSON.stringify(chooseCountry)},
                            "chooseRegion": ${JSON.stringify(chooseRegion)}, 
                            "chooseTown": ${JSON.stringify(chooseTown)}, 
                            "chooseTownsList": ${JSON.stringify(chooseTownsList)},                                                                                     
                            "freqHashtagLocal": ${JSON.stringify(freqHashtagLocal)},
                            "freqHashtagCommon": ${JSON.stringify(freqHashtagCommon)},
                            "chooseLang": ${JSON.stringify(chooseLang)},                                                        
                            "chooseSocialNetwork": ${JSON.stringify(chooseSocialNetwork)},
                            "countHashTag": ${JSON.stringify(countHashTag)}}`;
      
      
      storeData.setJsonLocalStorage(jsonAllParams);
      //console.log("Изменилось");
      if (chooseTownsList.length === 0) {
        setHelperTextHashtag(errorNotTown);
      } else {
        setHelperTextHashtag('');
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [chooseCountry, chooseRegion, chooseTemplateCommon, chooseTownsList, freqHashtagLocal, freqHashtagCommon, chooseLang, chooseTown, chooseSocialNetwork, chooseTemplateUser, countHashTag]);

  useEffect(() => {  //Отслеживание изменения выбора набора
    
    if (!store.isAuth) {
      return;
    } 
    // console.log("useEffect loadTemplateUser");
    // storeData.setJsonLocalStorage(jsonAllParams);
    async function loadTemplateUser(id) {           //Заполнение из набора полей
      
      if (!(store.user?.userID))  {
        await store.checkAuth();
      } 

      const response = await DataService.loadUserTemplate(id, store.user?.userID);
      
      if (response.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
        // store.setAuth(false);
        // store.setStatus(response.status);
        // store.setUser();
        // store.setErrorStatusMessage(response.data);
        // return;
      }
      //console.log(id);
      //console.log(response);
      if (response.data[0]) {
        //console.log("Mi tut");
        const templateParam = response.data[0].jsonTemplate;

        if (templateParam) {  
          const perem = JSON.parse(templateParam);
          //if (perem?.chooseTemplateUser) { setChooseTemplateUser(perem.chooseTemplateUser);}
          if (perem?.chooseTemplateCommon) { setChooseTemplateCommon(perem.chooseTemplateCommon);}
          if (perem?.chooseCountry) { setChooseCountry(perem.chooseCountry);}
          if (perem?.chooseRegion) { setChooseRegion(perem.chooseRegion);}
          if (perem?.chooseTown) { setChooseTown(perem.chooseTown);}
          if (perem?.chooseTownsList) { setChooseTownsList(perem.chooseTownsList);}
          if (perem?.freqHashtagLocal) { setFreqHashtagLocal(perem.freqHashtagLocal);}
          if (perem?.freqHashtagCommon) { setFreqHashtagCommon(perem.freqHashtagCommon);}
          if (perem?.chooseLang) { setChooseLang(perem.chooseLang);}
          if (perem?.chooseSocialNetwork) { setChooseSocialNetwork(perem.chooseSocialNetwork);}
          if (perem?.countHashTag) { setCountHashTag(perem.countHashTag);}
        }
      }  
      return;
    }    
    
    if (chooseTemplateUser && chooseTemplateUser.length !== 0) {
      loadTemplateUser(chooseTemplateUser.id)
    }

// eslint-disable-next-line react-hooks/exhaustive-deps  
  }, [chooseTemplateUser]);


  const addCountryToChoose = () => {      //------------------------------------Кнопка добавления города
    
    if (typeof(chooseCountry.id) != "undefined") {
          if (chooseTownsList.some(item => item.id === chooseCountry.id)) {
                console.log("Такой город есть")
          } else {
              let nameTown = "";
              //if (chooseRegion.name) {
              //  nameTown = chooseTown.name + " (" + chooseRegion.name + ")"
              //} else {
              nameTown = chooseCountry.name
              //}  
              setChooseTownsList([...chooseTownsList, {id: chooseCountry.id, name: nameTown}]);
              //(chooseRegion.name ? " (" + chooseRegion.name + ")" : "")
              //setHelperTextHashtag('');
          }
    } else  {
      console.log("Неопределено addCountryToChoose")
    }    
  }

  const addRegionToChoose = () => {      //------------------------------------Кнопка добавления региона
    
    if (typeof(chooseRegion.id) != "undefined") {
          if (chooseTownsList.some(item => item.id === chooseRegion.id)) {
                console.log("Такой город есть")
          } else {
              let nameTown = "";
///              if (chooseRegion.name) {
   //             nameTown = chooseTown.name + " (" + chooseRegion.name + ")"
     //         } else {
              nameTown = chooseRegion.name
       //       }  
              setChooseTownsList([...chooseTownsList, {id: chooseRegion.id, name: nameTown}]);
              //(chooseRegion.name ? " (" + chooseRegion.name + ")" : "")
              //setHelperTextHashtag('');
          }
    } else  {
      console.log("Неопределено addRegionToChoose")
    }    
  }

  const addTownToChoose = () => {      //------------------------------------Кнопка добавления города
    
    if (typeof(chooseTown.id) != "undefined") {
          if (chooseTownsList.some(item => item.id === chooseTown.id)) {
                console.log("Такой город есть")
          } else {
              let nameTown = "";
              if (chooseRegion.name) {
                nameTown = chooseTown.name + " (" + chooseRegion.name + ")"
              } else {
                nameTown = chooseTown.name
              }  
              setChooseTownsList([...chooseTownsList, {id: chooseTown.id, name: nameTown}]);
              //(chooseRegion.name ? " (" + chooseRegion.name + ")" : "")
              //setHelperTextHashtag('');
          }
    } else  {
      console.log("Неопределено addTownToChoose")
    }    
  }

  const deleteTownToChoose = () => {   //---------------------------------Кнопка удаления городов из выборки
    
    const newChecked = [...checkedTown];
    let s = []
    
    if (checkedTown.length === 0) {
      setChooseTownsList([]);
      return;
    }

    for (let i = 0; i < chooseTownsList.length; i++){
      if (!(checkedTown.some(item => Number(item) === Number(chooseTownsList[i].id)))) {
         s.push(chooseTownsList[i])
         //console.log(chooseTownsList[i].id)
      } else {
        newChecked.splice(chooseTownsList[i], 1);
      }  
    }  
    setCheckedTown(newChecked);
    setChooseTownsList(s) 
  }   
  
  const clearFilter = () => {          //------------Сброс всех настроек
    setChooseTemplateUser([]); 
    setChooseTemplateCommon([]); 
    setChooseCountry([]);
    setChooseRegion([]);
    setChooseTown([]);                  // Выбор города 
    setChooseTownsList([]);        // Список выбранных городов
    setFreqHashtagLocal(2);
    setFreqHashtagCommon(3);
    setChooseLang(1);
    setChooseSocialNetwork(1);
    setCountHashTag(30);
    setGettingHashtag("");
    setChipData([]);
  }

  // const countSymbolHashtag = () => {          //------------Подсчет кол-ва символов хештегов
  // function countSymbolHashtag () {
  //   let countSymbol = 0;
  //   for (let i = 1; i < chipData.length; i++) {
  //     countSymbol = chipData[i].name.length + 2;
  //   }
  //   countSymbol = countSymbol - 1;
  //   return(countSymbol);
  // }


  const getHashtag = () => {            //---------Получение хештегов из бекенда
    let params=[]
    let towns = []

    if ((chooseTemplateCommon?.length === 0) || (typeof chooseTemplateCommon === "undefined") || (!chooseTemplateCommon)) {
      setSnackBarText("Не выбран шаблон"); setSnackBarStatus('error'); setOpenSnackBar(true);
      return;
    }

    if (!countHashTag) {
      setSnackBarText("Выберите количество хештегов"); setSnackBarStatus('error'); setOpenSnackBar(true);
      return;
    }

    for (let i = 0; i < chooseTownsList.length; i++){
      towns.push(chooseTownsList[i].id)
    }
    //console.log(towns)
    const idSessionGen = uuid.v4();
    //console.log(idSessionGen);
    setIdSession(idSessionGen);
    //console.log(idSession);
    params = {id_user: store.user?.userID,
              idTemplate: chooseTemplateCommon.id,
              idFreqLocal: freqHashtagLocal,
              idFreqCommon: freqHashtagCommon,
              idLang: chooseLang,
              idSocialNetwork: chooseSocialNetwork,
              idTowns: towns,
              idCountHashTag: countHashTag,
              idSession: idSessionGen
              }
    
    setVisibleLinearProgress(true);
    //console.log(params) 
    
    async function rowgetHashtag() {           //Заполнение хештегов
      const response = await DataService.getHashtag(params);
      if (response.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
        //console.log("TUT");
        store.setAuth(false);
        store.setStatus(response.status);
        store.setUser();
        store.setErrorStatusMessage(response.data);
        return;
      }

      if (response.status === 500) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
        setVisibleLinearProgress(false);
        setGettingHashtag('500: Внутренняя ошибка сервера. ' + response.data.hashtag);
        setChipData([]);
        setTimeWork("Время: " + response.data.timeWork + "сек");
        //setCountAllHashtag(0); 
        return;
      }

      //console.log(response);
      setVisibleLinearProgress(false);
      setGettingHashtag(response.data.hashtag);
      let hashTagArr = JSON.parse(response.data.hashTagArr);
      //console.log(hashTagArr);
      setChipData(hashTagArr);
      setTimeWork("Время: " + response.data.timeWork + "сек");
      let countSymbol = 0;
      for (let i = 0; i < hashTagArr.length; i++) {
        countSymbol = countSymbol + hashTagArr[i].name.length + 2;
      }
      countSymbol = countSymbol - 1;
      if (countSymbol < 0) {
        countSymbol = 0;
      }
      setCountSymbol(countSymbol); 
      return response.data;
    }    
    rowgetHashtag();
  }

  const mixerHashtag = () => {          //---------Перемещивание хештегов
    //Перемешиваем Массив Фишек
    let chipOld = chipData;
    
    for (let i = chipOld.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1)); // случайный индекс от 0 до i
      // поменять элементы местами
      [chipOld[i], chipOld[j]] = [chipOld[j], chipOld[i]];
    }

    setChipData([...chipOld]);
    
    // Формируем окончательную строку
    let countHashTag = chipData.length;
    let countH = 0;
    let s = "";
    //let countSymbol = 0;
    for (let t = 0; t < chipData.length; t++) {
      s = s + "#" + chipData[t].name + " ";
      //countSymbol = countSymbol + 1 + chipData[t].name.length + 1;
      //console.log(countSymbol);
      countH = countH + 1;
      if (countH === 30 && countHashTag > 30) {
          countH = 0;
          s = s + `\n______________________________________\n`;
      }
    }    
    //console.log("Кол-во символов: " + countSymbol);
    s = s.trim();
    setGettingHashtag(s);
    
  }

  async function replaceHashtag () {          //---------Заменяем хештеги
    //Заменяем значения выбранных Фишек
    let chipSTR = JSON.stringify(chipData);
    let isMarked = false;
    for (let i = 0; i < chipData.length; i++){
      if (chipData[i].color === "error") {
        //Есть выделение тега
        isMarked = true;
      }
    } 
    //console.log(isMarked);
    if (isMarked === false) {
      setSnackBarText("Не выбраны теги"); setSnackBarStatus('error'); setOpenSnackBar(true);
      return;
    }  
   // console.log(isMarked);

    const response = await DataService.replaceHashtag(store.user?.userID, chipSTR, idSession);
      if (response.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
        store.setAuth(false);
        store.setStatus(response.status);
        store.setUser();
        store.setErrorStatusMessage(response.data);
        return;
      }
    
    //console.log(response);
    //console.log(response.data.chipArr);
    //console.log(response.data.countReplace);
    if (response.data.countReplace === 0) {
      setSnackBarText("Хештеги исчерпаны. Измените частотность"); setSnackBarStatus('error'); setOpenSnackBar(true);
    };
    
    if (!(response.data.chipArr === undefined)) {
      //console.log(response.data.chipArr);
      let chipArr = JSON.parse(response.data.chipArr);
      //console.log(chipArr);
      if (response.data.chipArr === "{}") {
        setChipData([]);
      } else {  
        setChipData(chipArr);
      }
  
    
      //Формируем окончательную строку
      let countHashTag = chipArr.length;
      let countH = 0;
      let countSymbol = 0;
      let s = "";
      for (let t = 0; t < chipArr.length; t++) {
        s = s + "#" + chipArr[t].name + " ";
        countSymbol = countSymbol + 1 + chipArr[t].name.length + 1;
        // console.log(chipArr[t].name + "   " + chipArr[t].name.length);
        // console.log(countSymbol);
        countH = countH + 1;
        if (countH === 30 && countHashTag > 30) {
            countH = 0;
            s = s + `\n______________________________________\n`;
        }
      }    
      s = s.trim();
      countSymbol = countSymbol - 1;
      if (countSymbol < 0) {
        countSymbol = 0;
      }
      setCountSymbol(countSymbol);
      // console.log("Кол-во символов: " + countSymbol);
    // console.log(s);
      setGettingHashtag(s);

    }
    
  }

  async function deleteHashtag () {          //---------Заменяем хештеги
    //Удаляем выбранные Фишки
    
    let isMarked = false;
    for (let i = 0; i < chipData.length; i++){
      if (chipData[i].color === "error") {
        //Есть выделение тега
        isMarked = true;
      }
    } 
    if (isMarked === false) {
      setSnackBarText("Не выбраны теги"); setSnackBarStatus('error'); setOpenSnackBar(true);
      return;
    }  
   
    let chipOld = chipData;
    let chipNew = [];
    for (let i = 0; i < chipOld.length; i++){
      if (chipOld[i].color === "primary" || chipOld[i].color === "secondary") {
        chipNew.push(chipOld[i]);
      }
    } 
    //console.log(chipNew);
    setChipData([...chipNew]);
  
    //Формируем окончательную строку
    let countHashTag = chipNew.length;
    let countH = 0;
    let countSymbol = 0;
    let s = "";
    for (let t = 0; t < chipNew.length; t++) {
      s = s + "#" + chipNew[t].name + " ";
      countSymbol = countSymbol + 1 + chipNew[t].name.length + 1;
      countH = countH + 1;
      if (countH === 30 && countHashTag > 30) {
          countH = 0;
          s = s + `\n______________________________________\n`;
      }
    }    
    s = s.trim();
    countSymbol = countSymbol - 1;
    if (countSymbol < 0) {
      countSymbol = 0;
    }
    setCountSymbol(countSymbol);
    setGettingHashtag(s);

  }  
  
  
  const handleOpenDialogAddTemplateUser = () => {   //------------ Открытие диалога добавления пользовательского набора
    setOpenDialogAddTemplateUser(true);
  };
  
  const handleCloseDialogAddTemplateUser = () => {  //------------ Закрытие диалога добавления пользовательского набороа
    setOpenDialogAddTemplateUser(false);
  };

  const handleOpenDialogEditTemplateUser = () => {   //------------ Открытие диалога редактирования пользовательского набора
    if (chooseTemplateUser && (chooseTemplateUser.length !== 0)) {
      setOpenDialogEditTemplateUser(true);
      return;
    }  else {
      setOpenSnackBar({show: true, text:"Не выбран набор", status: "error"});
      return;
    }  
  };
  
  const handleCloseDialogEditTemplateUser = () => {  //------------ Закрытие диалога редактирования пользовательского набора
    setOpenDialogEditTemplateUser(false);
  };

  const handleOpenDialogDeleteTemplateUser = () => {   //------------ Открытие диалога удаления пользовательского набора
    if (chooseTemplateUser && (chooseTemplateUser.length !== 0)) {
      setOpenDialogDeleteTemplateUser(true);
      return;
    }  else {
      setSnackBarText("Не выбран набор"); setSnackBarStatus('error'); setOpenSnackBar(true);
      return;
    }
  };
  
  const handleCloseDialogDeleteTemplateUser = () => {  //------------ Закрытие диалога удаления пользовательского набора
      setOpenDialogDeleteTemplateUser(false);
    
  };
 
  const handleSetCountHashTag = (e) => {   //------------ Проверка поля количество хештегов
    // eslint-disable-next-line
     if (isNumeric(e) || (e.length ==0)) {
        setCountHashTag(e);
     }  
    
    //setOpenDialogAddTemplateUser(true);
  };

  const handleSetChooseSocialNetwork = (e) => {   //------------ Проверка поля количество хештегов
    if (Number(e) === 2) {
      if (countHashTag > 10) {
        setCountHashTag(10);
      }
    } 
    
    if (Number(e) === 1) {
      setCountHashTag(30);
    }
    
    setChooseSocialNetwork(e);
  };

  const handleSaveTemplateUser = () => {      //*************** Сохранить пользовательский набор


    //Сохранить набор
    const id =  (chooseTemplateUser?.id) ? chooseTemplateUser?.id : 0;
    const userID = (store.user?.userID) ? store.user?.userID : 0;
    const jsonTemplate = JSON.stringify(JSON.parse(storeData.jsonLocalStorage));
     
    async function saveUserTemplate (id, userID, jsonTemplate) {
      const response = await DataService.saveTemplate(id, userID, jsonTemplate);
      if (response.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
        store.setAuth(false);
        store.setStatus(response.status);
        store.setUser();
        store.setErrorStatusMessage(response.data);
        return;
      }
      
      //setOpenSnackBar({show: true, text:"Набор успешно сохранен", status: "success"});
      setSnackBarText("Набор успешно сохранен"); setSnackBarStatus('success'); setOpenSnackBar(true);
      return response.data;

    }

    //Проверка выбран ли набор
    if ((chooseTemplateUser && (chooseTemplateUser.length !== 0)) && id !== 0 && userID !== 0) {
        saveUserTemplate(id, userID, jsonTemplate)
        ///storeData.jsonLocalStorage; 
    }  else {
      setSnackBarText("Не выбран набор"); setSnackBarStatus('error'); setOpenSnackBar(true);
      return;
    }
   
  }

  const handleCloseSnackBar = (event, reason) => {  //----- Закрытие снекбара 
    if (reason === 'clickaway') {
      return;
    }
      setOpenSnackBar(false);
  };

  function copyToClip (text) {                      //-------Копирование в буфер
    //console.log(text);
    if (text === "" || typeof text == 'undefined') {
      text = "В буффере пусто"
    }
    var input = document.createElement('textarea');
    input.innerHTML = text;
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);
    return result;
  }

  const copyToClipbord = (props) => {
    copyToClip(gettingHashtag);
    setSnackBarText("Скопировано в буфер обмена"); setSnackBarStatus('success'); setOpenSnackBar(true);
  }

  const copyToClipbordBlock1 = (props) => {
    
    let strHashtag = "";
    for (let i = 0; (i < 30) && (i < chipData.length); i ++) {
      strHashtag = strHashtag + "#" + chipData[i].name + " ";
    }
    strHashtag = strHashtag.trim();
    copyToClip(strHashtag);

    setSnackBarText("Скопировано в буфер обмена"); setSnackBarStatus('success'); setOpenSnackBar(true);
  }

  const copyToClipbordBlock2 = (props) => {
    
    let strHashtag = "";
    for (let i = 30; (i < 60) && (i < chipData.length); i ++) {
      strHashtag = strHashtag + "#" + chipData[i].name + " ";
    }
    strHashtag = strHashtag.trim();
    copyToClip(strHashtag);

    setSnackBarText("Скопировано в буфер обмена"); setSnackBarStatus('success'); setOpenSnackBar(true);
  }

  const copyToClipbordBlock3 = (props) => {
    
    let strHashtag = "";
    for (let i = 60; (i < 90) && (i < chipData.length); i ++) {
      strHashtag = strHashtag + "#" + chipData[i].name + " ";
    }
    strHashtag = strHashtag.trim();
    //console.log(strHashtag);

    copyToClip(strHashtag);

    setSnackBarText("Скопировано в буфер обмена"); setSnackBarStatus('success'); setOpenSnackBar(true);
  }

  const handleChekedListTown = (value) => () => {         //------ Добавление города в список отмеченных городов
    const currentIndex = checkedTown.indexOf(value);
    const newChecked = [...checkedTown];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedTown([...newChecked]);
  };

  const handleClickChip = (value) => () => {         //------ Меняем цвет фишки
    let chipOld = chipData;
    
    let objIndex = chipOld.findIndex(obj => obj.key === value);
    
    if (chipOld[objIndex].color === "error") {
      chipOld[objIndex].color = "primary"
    } else {
      chipOld[objIndex].color = "error" 
    }  
    setChipData([...chipOld]);
    
  };
  
  if(!(store.isAuth)) {
         return (<Navigate to="/login" />);
  } else {
          if ((typeof(store.user?.isPay) !== "undefined" &&  typeof(store.user?.isActivated) !== "undefined") && 
               (store.isAuth && !store.user.isPay && store.user.isActivated)) 
          {
               return (<Navigate to="/errornotpay" />);
          } else {
            if ((typeof(store.user?.isActivated) !== "undefined") && 
                (store.isAuth && !store.user.isActivated)) {
                  return (<Navigate to="/errornotactivated" />);
                } else {
  //     // } else {
        
  //     //   if (store.isAuth && !store.user?.isActivated) {
  //     //     return (<Navigate to="/errornotactivated" />);
  //         } else {
  return (
    <>

    <ThemeProvider theme={themeColor}>
    {document.title = "Получение хештегов / besthashtag.ru"}
    {/*document.body.style.backgroundColor = "red"*/}  
      <Container component="main" maxWidth="sm">
      <CssBaseline />
      {/*****************************          Секция с диалогами          *****************/ }  
      <DialogAddUserTemplate onClose={handleCloseDialogAddTemplateUser} 
                             setUserTemplate={setUserTemplate}
                             setChooseTemplateUser={setChooseTemplateUser}
                             clearFilter={clearFilter}
                             setSnackBarText={setSnackBarText}
                             setSnackBarStatus={setSnackBarStatus}
                             setOpenSnackBar={setOpenSnackBar}
                             //selectVal={setTemplateDialog} 
                             open={openDialogAddTemplateUser} /> 
      
      <DialogEditUserTemplate onClose={handleCloseDialogEditTemplateUser} 
                             setUserTemplate={setUserTemplate}
                             setChooseTemplateUser={setChooseTemplateUser}
                             chooseTemplateUser={chooseTemplateUser}
                             setSnackBarText={setSnackBarText}
                             setSnackBarStatus={setSnackBarStatus}
                             setOpenSnackBar={setOpenSnackBar}
                             //selectVal={setTemplateDialog} 
                             open={openDialogEditTemplateUser} />                         
      
      <DialogDeleteUserTemplate onClose={handleCloseDialogDeleteTemplateUser} 
                             id={(chooseTemplateUser?.id) ? chooseTemplateUser.id : null}
                             nameTemplate={(chooseTemplateUser?.nameTemplate) ? chooseTemplateUser.nameTemplate : null}
                             setUserTemplate={setUserTemplate}
                             setChooseTemplateUser={setChooseTemplateUser}
                             clearFilter={clearFilter}
                             setSnackBarText={setSnackBarText}
                             setSnackBarStatus={setSnackBarStatus}
                             setOpenSnackBar={setOpenSnackBar}
                             //selectVal={setTemplateDialog} 
                             open={openDialogDeleteTemplateUser} />                         
         
        











        {/************************************************************************************************/}
        {/**************               Заголовок и кнопка фильтра                  ***********************/}
        {/************************************************************************************************/}
        <Box sx={{ 
                  width: '100%',
                  marginTop: 10,
                  marginBottom: 0,
                  display: 'block',
                  flexDirection: 'column',
                  alignItems: 'center',
                  borderRadius: 0,
                  boxShadow: 0,
                  pt: 0,
                  px: 0,
                  pb: 0,
                }}>
        <Grid container alignItems="center" sx={{width: '100%',}}>
          <Grid item xs={10} align='center'>
            <Typography variant="body1" 
                        gutterBottom 
                        align="center" 
                        sx={{marginTop: 0.7, 
                             paddingLeft: 5,
                             color: 'n4.main',
                             fontSize: '20px', 
                             fontFamily: 'Akrobat, Helvetica, sans-serif;',
                             //fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;',
                        }}>
              Получение хештегов
            </Typography>
          </Grid>  
          <Grid item xs={2} align='center'>
            <Tooltip title="Сбросить настройки" placement="bottom">
              <IconButton 
                          aria-label="clear-filter" size="large" sx={{color: 'errorUser.main' }}
                          onClick={clearFilter}
                          >
                <FilterAltOffIcon />
              </IconButton>
            </Tooltip>     
          </Grid>          
        </Grid>
        </Box>
        

        {/************************************************************************************************/}
        {/*************************                Наборы                  ******************************/}
        {/************************************************************************************************/}
        <Box //---BOX с ПОЛЬЗОВАТЕЛЬСКИМИ НАБОРЫ
              sx={{
                width: '100%',
                marginTop: 1,
                mb: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 4,
                borderColor: 'n2.main',
                borderStyle: 'solid',
                borderWidth: 1,
                boxShadow: 1,
                pt: 1,
                px: 2,
                pb: 2,
              }}
            > 
             <Grid container wrap="nowrap" alignItems="center">
                <Grid item xs='auto' alignItems="left" >
                <Tooltip title="Создать новый набор" placement="bottom">
                  <IconButton 
                              aria-label="add-template" size="large" sx={{color: 'n5.main' }}
                              onClick={handleOpenDialogAddTemplateUser}>
                    <NoteAddOutlinedIcon />
                  </IconButton>
                </Tooltip> 
                <Tooltip title="Сохранить набор" placement="bottom">
                  <IconButton 
                              aria-label="save-template" size="large" sx={{color: 'loginUser.main' }}
                              onClick={handleSaveTemplateUser}
                              >
                    <SaveOutlinedIcon sx={{fontSize: 27}} />
                  </IconButton>
                </Tooltip> 
                <Tooltip title="Редактировать наименование набора" placement="bottom">
                  <IconButton 
                              aria-label="edit-template" size="large" sx={{color: 'n4.main' }}
                              onClick={handleOpenDialogEditTemplateUser}>
                    <ModeEditOutlineOutlinedIcon sx={{fontSize: 28}} />
                  </IconButton>
                </Tooltip> 
                <Tooltip title="Удалить набор" placement="bottom">
                  <IconButton 
                              aria-label="delete-template" size="large" sx={{color: 'errorUser.main' }}
                              onClick={handleOpenDialogDeleteTemplateUser}>
                    <DeleteOutlineOutlinedIcon sx={{fontSize: 28}} />
                  </IconButton>
                </Tooltip> 
                </Grid>
                <Grid item xs={9} sx={{mr: 2}} alignItems="right" ></Grid>
              </Grid>
              <Grid container wrap="nowrap" alignItems="center">
                <Grid item xs={12} >
                <Autocomplete  
                            value={chooseTemplateUser}
                            size="small"
                            fullWidth
                            options={userTemplate}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            getOptionLabel={(option) => option.nameTemplate || ""}
                            id="choose-user-template"
                            //freeSolo
                            autoHighlight
                            renderInput={(params) => (
                              <TextField {...params} label="Ваши наборы" 
                                                    variant="outlined"
                                                    />
                                         )}
                            ListboxProps={{sx: { fontSize: '14px',
                                       fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', 
                                       fontWeight: 400,    },}}                
                            defaultValue=""
                            clearText="Очистить"
                            closeText="Закрыть"
                            openText="Открыть"
                            onChange={(event, newValue)=> {
                                            setChooseTemplateUser(newValue);
                                            }}
                            sx = {styleTextField}  
                            // sx= {{ fontSize: '16px',
                            //  fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;',
                            //  fontWeight: '400'}}
                          />            
                    
                </Grid>
               
              </Grid>                    
        </Box>
        <Box //---BOX со стрелкой вниз (разделитель)
              sx={{
                marginTop: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 4,
                borderColor: 'n2.main',
                borderStyle: 'solid',
                borderWidth: 0,
                boxShadow: 0,
                pt: 0,
                px: 0,
                pb: 0,
              }}
            >
            <ExpandMoreOutlinedIcon align="center" sx={{ fontSize: 60, color: 'n2.main' }}/>
        </Box> 
        
        <Box //---BOX с шаблонами
              sx={{
                marginTop: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 4,
                borderColor: 'n2.main',
                borderStyle: 'solid',
                borderWidth: 1,
                boxShadow: 1,
                pt: 2,
                px: 2,
                pb: 2,
              }}
            >

            <Autocomplete  
              value={chooseTemplateCommon}
              size="small"
              fullWidth
              options={commonTemplate}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              getOptionLabel={(option) => option.name || ""}
              id="choose-set"
              //freeSolo
              autoHighlight
              renderInput={(params) => (
                <TextField {...params} label="Общие шаблоны" 
                                      variant="outlined"
                                      />
                )}
              ListboxProps={{sx: { fontSize: '14px',
                                   fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', 
                                   fontWeight: 400,    },}}     
              defaultValue=""
              clearText="Очистить"
              closeText="Закрыть"
              openText="Открыть"
              onChange={(event, newValue)=> {
                              //handleChooseTemplateCommon(newValue);
                              setChooseTemplateCommon(newValue);
                              }}
              sx = {{...styleTextField
                    //, px: 1
                    }}  
            />
        </Box>

        {/************************************************************************************************/}
        {/*************************               ГЕОГРАФИЯ                 ******************************/}
        {/************************************************************************************************/}
        <Box //---BOX с географией
              sx={{
                marginTop: 1.3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 4,
                borderColor: 'n2.main',
                borderStyle: 'solid',
                borderWidth: 1,
                boxShadow: 1,
                pt: 3,
                px: 2,
                pb: 3,
              }}
            >
            <FormControl sx={{ mt: 0, mb:0.8}} variant="outlined" fullWidth >
            <Autocomplete //*                      Страна               */   
              value={chooseCountry} 
              size="small" 
              fullWidth
              options={countryList}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              getOptionLabel={(option) => option.name || ""}
              id="choose-country"
              autoHighlight
              // TextFieldProps={{
        
              //             InputLabelProps: {
                              
              //                 style: {
              //                     fontSize: 38,
              //                 },
              //             },
              //         }}
              renderInput={(params) => (
                <TextField 
                                      {...params} 
                                      label="Страна" 
                                      variant="outlined" />
                          )}
              ListboxProps={{sx: { fontSize: '14px',
                                   fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', 
                                   fontWeight: 400,    },}}   
              defaultValue=""
              clearText="Очистить"
              closeText="Закрыть"
              openText="Открыть"
              onChange={(event, newValue)=> {
                              setChooseCountry(newValue);
                              setRegionList([]);
                              setChooseRegion([]);
                              setTownList([]);
                              setChooseTown([]);
                              }} 
              sx = {styleTextField}                  
            />
            </FormControl>
            <Button size="small" //   ДОБАВИТЬ СТРАНУ
                    variant="contained" 
                    sx={{borderRadius: '10px',
                        bgcolor: 'lightLoginUser.main',
                        color: 'n0.main', 
                        '&:hover': {
                                    backgroundColor: 'superLightLoginUser.main'
                        }, 
                        mt: 0,
                        mb: 1, 
                        width: 170, 
                        fontSize: 9,}}
                    onClick={addCountryToChoose}>
                Добавить страну
            </Button>
            
            <FormControl sx={{ mt: 0}} variant="outlined" fullWidth >
            <Autocomplete //*                      Регион               */
              value={chooseRegion} 
              size="small" 
              fullWidth
              disabled={isHaveRegion}
              options={regionList}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              getOptionLabel={(option) => option.name || ""}
              id="choose-region"
              autoHighlight
              // TextFieldProps={{
        
              //             InputLabelProps: {
                              
              //                 style: {
              //                     fontSize: 38,
              //                 },
              //             },
              //         }}
              renderInput={(params) => (
                <TextField 
                                      {...params} 
                                      label="Регион" 
                                      variant="outlined" />
                          )}
              ListboxProps={{sx: { fontSize: '14px',
                                   fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', 
                                   fontWeight: 400,    },}}   
              defaultValue=""
              clearText="Очистить"
              closeText="Закрыть"
              openText="Открыть"
              onChange={(event, newValue)=> {
                              setChooseRegion(newValue);
                              setTownList([]);
                              setChooseTown([]);
                              }} 
              sx = {styleTextField}                  
            />
            </FormControl>
            <Button size="small" //   ДОБАВИТЬ РЕГИОН
                    disabled={isHaveRegion}
                    variant="contained" 
                    sx={{borderRadius: '10px',
                        bgcolor: 'lightLoginUser.main',
                        color: 'n0.main', 
                        '&:hover': {
                                    backgroundColor: 'superLightLoginUser.main'
                        }, 
                        mt: 0.8,
                        mb: 1, 
                        width: 170, 
                        fontSize: 9,}}
                    onClick={addRegionToChoose}>
                Добавить регион
            </Button>

            <Autocomplete //*                    Город                  */
              value={chooseTown}
              fullWidth
              size="small"
              options={townList}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              getOptionLabel={(option) => option.name || ""}
              id="choose-town"
              autoHighlight
              renderInput={(params) => (
                <TextField {...params} label="Город" 
                                      variant="outlined" 
                                      />
                )}
              ListboxProps={{sx: { fontSize: '14px',
                                   fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', 
                                   fontWeight: 400,    },}}     
              clearText="Очистить"
              closeText="Закрыть"
              openText="Открыть"
              onChange={(event, newValue)=> {
                              setChooseTown(newValue);
                              }}
              sx = {styleTextField}                   
            />
            {/* </FormControl> */}
            <Button size="small" //   ДОБАВИТЬ ГОРОД
                    variant="contained" 
                    sx={{borderRadius: '10px',
                        bgcolor: 'lightLoginUser.main',
                        color: 'n0.main', 
                        '&:hover': {
                                    backgroundColor: 'superLightLoginUser.main'
                        }, 
                        mt: 0.8, 
                        width: 170, 
                        fontSize: 9,}}
                    onClick={addTownToChoose}>
                Добавить город
            </Button>
            
            <Box //---BOX с выбранными городами
              sx={{
                width: '100%',
                marginTop: 1.2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 4,
                borderColor: 'n2.main',
                borderStyle: 'solid',
                borderWidth: 1,
                pt: 0,
                pl: 1,
                pr: 0,
                pb: 1,
              }}
            > 
             <Typography id="input-slider-socialnetwork" 
                         className='noselect' 
                         gutterBottom variant="body2" align='center' 
                         sx={{mt:1, mb:0, fontSize: '15px', fontFamily: 'calibri, Verdana, Geneva, sans-serif;'}}>
            Выбранные топонимы
          </Typography>
              <Grid container wrap="nowrap" alignItems="center">
                <Grid item xs={12}>
                  <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {chooseTownsList.map((value) => {
                          const labelId = `checkbox-list-secondary-label-${value.id}`;
                          return (
                            <ListItem
                              key={value.id}
                              secondaryAction={
                                <Checkbox
                                  edge="end"
                                  onChange={handleChekedListTown(value.id)}
                                  checked={checkedTown.indexOf(value.id) !== -1}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                />
                              }
                              disablePadding
                            >
                              <ListItemButton>
                                <ListItemText id={labelId} primary={`${value.name}`} primaryTypographyProps={{fontSize: '15px', fontFamily: 'calibri, Verdana, Geneva, sans-serif;' }}  
                                  onClick={handleChekedListTown(value.id)}
                                />
                              </ListItemButton>
                            </ListItem>
                          );
                        })}
                      </List>             
                    
                </Grid>
                <Divider orientation="vertical" flexItem></Divider>
                <Grid  item xs='auto' >
                <Tooltip title="Удалить выбранные города" placement="bottom">
                  <IconButton 
                              aria-label="delete" size="large" sx={{color: 'errorUser.main' }}
                              onClick={deleteTownToChoose}>
                    <DeleteOutlineOutlinedIcon sx={{fontSize: 28}} />
                  </IconButton>
                </Tooltip> 
                </Grid>
              </Grid>                    
            </Box>      
        </Box>

        {/************************************************************************************************/}
        {/*************************               ЧАСТОТНОСТЬ               ******************************/}
        {/************************************************************************************************/}
        <Box //---BOX с частотностью
              sx={{
                marginTop: 1.3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 4,
                borderColor: 'n2.main',
                borderStyle: 'solid',
                borderWidth: 1,
                boxShadow: 1,
                pt: 1.5,
                px: 2,
                pb: 1.5,
              }}>
              <Box //---BOX Частотность географических хештегов
              sx={{
                    width: '95%',
                    marginTop: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: 4,
                    borderColor: 'n2.main',
                    borderStyle: 'solid',
                    borderWidth: 0,
                    pt: 0,
                    pl: 2,
                    pr: 2,
                    pb: 0,
              }}
              >
                <Typography className='noselect' id="input-slider" gutterBottom variant="body2" //sx={{color: 'n5.main'}}
                >
                  Частотность географических хештегов
                </Typography>
                <Slider
                  value={freqHashtagLocal}
                  size = "small"
                  aria-label="Restricted values"
                  defaultValue={2}
                  valueLabelFormat={valueLabelFormat}
                  getAriaValueText={valuetext}
                  step={1}
                  min={1}
                  max={4}
                  valueLabelDisplay="auto"
                  marks={marksLocal}
                  sx={{color: 'n4.main',
                      '& .MuiSlider-markLabel': { //Шрифт меток
                                                  fontSize: 12,
                                                  color: 'n4.main',
                                                }
                      }}
                  onChange = {(e) => setFreqHashtagLocal(e.target.value)}
                />
              </Box>
              <Box //---BOX Частотностностью общих хештегов
                  sx={{
                        width: '95%',
                        marginTop: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: 4,
                        borderColor: 'n2.main',
                        borderStyle: 'solid',
                        borderWidth: 0,
                        pt: 0,
                        pl: 2,
                        pr: 2,
                        pb: 0,
                  }}
                  >
                    <Typography className='noselect' id="input-slider" gutterBottom variant="body2"// sx={{color: 'n5.main'}}
                    >
                      Частотность глобальных хештегов
                    </Typography>
                    <Slider
                      value={freqHashtagCommon}
                      size = "small"
                      aria-label="Restricted values"
                      defaultValue={3}
                      valueLabelFormat={valueLabelFormat}
                      getAriaValueText={valuetext}
                      step={1}
                      min={1}
                      max={4}
                      valueLabelDisplay="auto"
                      marks={marksCommon}
                      sx={{color: 'n4.main',
                          '& .MuiSlider-markLabel': { //Шрифт меток
                                                      fontSize: 12,
                                                      color: 'n4.main',
                                                    }
                      }}
                      onChange = {(e) => setFreqHashtagCommon(e.target.value)}
                    />   
              </Box> 
        </Box> 

        {/************************************************************************************************/}
        {/*************************               ЯЗЫК                      ******************************/}
        {/************************************************************************************************/}              
        <Box //---BOX с выбором ЯЗЫКА
                    sx={{
                marginTop: 1.5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 4,
                borderColor: 'n2.main',
                borderStyle: 'solid',
                borderWidth: 1,
                boxShadow: 1,
                pt: 1,
                px: 1,
                pb: 2,
              }}>
        <FormControl>
          <Typography id="input-slider" className='noselect' gutterBottom variant="body2" align='center' sx={{mb:1}} //sx={{color: 'n5.main'}}
          >
          Язык
          </Typography>
            <RadioGroup
              size = "small"
              row
              aria-labelledby="chooseLang-row-radio-buttons-group-label"
              name="chooseLang-radio-buttons-group"
              value={chooseLang}
              onChange = {(e) => setChooseLang(e.target.value)}
            >
              <FormControlLabel  //-------- RU + EN
                sx={{
                    '& .MuiSvgIcon-root': {
                                          fontSize: 20,
                    },
                    paddingRight: 1, 
                }}
                size = "small" value={1} control={<Radio size="small" />} 
                label={
                  <React.Fragment>
                    <span className="flag-icon flag-icon-ru"></span> + <span className="flag-icon flag-icon-gb"></span>
                  </React.Fragment>
                }
              />
              <FormControlLabel  //-------- RU
                sx={{
                    '& .MuiSvgIcon-root': {
                                          fontSize: 20,
                    },
                    paddingRight: 1, 
                }}
                size = "small" value={2} control={<Radio size="small" />} 
                label={
                  <React.Fragment>
                    <span className="flag-icon flag-icon-ru"></span>
                  </React.Fragment>
                } 
              />
              <FormControlLabel  //-------- EN
                sx={{
                    '& .MuiSvgIcon-root': {
                                          fontSize: 20,
                    },
                    paddingRight: 1, 
                    paddingLeft: 1, 
                }}
                size = "small" value={3} control={<Radio />} 
                label={
                  <React.Fragment>
                    <span className="flag-icon flag-icon-gb"></span>
                  </React.Fragment>
                } 
              />
            </RadioGroup>
          </FormControl>
        </Box>              
        
        {/************************************************************************************************/}
        {/*************************               Соцсеть                   ******************************/}
        {/************************************************************************************************/}              
        <Box //---BOX с выбором СОЦСЕТИ
                    sx={{
                marginTop: 1.5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 4,
                borderColor: 'n2.main',
                borderStyle: 'solid',
                borderWidth: 1,
                boxShadow: 1,
                pt: 1,
                px: 1,
                pb: 2,
              }}>
        <FormControl>
          <Typography id="input-slider-socialnetwork" className='noselect' gutterBottom variant="body2" align='center' sx={{mb:1}}>
            Соцсеть
          </Typography>
            <RadioGroup
              size = "small"
              row
              aria-labelledby="choosesocialsetwork-row-radio-buttons-group-label"
              // align='center'
              // inputProps={{ style: { align: 'center' }
              //                  }}
              name="choosesocialnetwork-radio-buttons-group"
              value={chooseSocialNetwork}
              onChange = {(e) => handleSetChooseSocialNetwork(e.target.value)}
            >
              <FormControlLabel  //-------- Инстаграмм
                sx={{
                    '& .MuiSvgIcon-root': {
                                          fontSize: 20,
                    },
                    paddingLeft: 2,
                    paddingRight: 4, 
                }}
                size = "small" value={1} control={<Radio size="small" />} 
                label={
                  <React.Fragment>
                  <Typography variant="body2" align='center' sx={{color: 'n5.main',
                                fontSize: '14px', fontFamily: 'Clear Sans, Arial, sans-serif;', fontWeight: 50, fontStyle: 'normal',
                                                                }}>
                  Инстаграмм*
                  </Typography>
                    {/* <img src={logoInsta} alt="VK" width={30} height={30}/> */}
                  </React.Fragment>
                }
              />
              <FormControlLabel  //-------- Вконтакте
                sx={{
                    '& .MuiSvgIcon-root': {
                                          fontSize: 20,
                    },
                    paddingLeft: 0,
                    paddingRight: 0, 
                }}
                size = "small" value={2} control={<Radio size="small" />} 
                label={
                  <React.Fragment>
                  <Typography variant="body2" align='center' sx={{color: 'n5.main',
                                fontSize: '14px', fontFamily: 'Clear Sans, Arial, sans-serif;', fontWeight: 50, fontStyle: 'normal',
                                                                }}>
                  Вконтакте
                  </Typography>
                    {/* <img src={logoVK} alt="VK" width={30} height={30}/> */}
                  </React.Fragment>
                } 
              />
            </RadioGroup>
            
            <Typography id="input-slider-socialnetwork" className='noselect' gutterBottom variant="body2" align='center' sx={{mt:3}}>
              Количество хештегов
            </Typography>
                <TextField
                  //type = "number"
                  autoFocus
                  size='small'
                  margin="dense"
                  id="countHashtag"
                  // label="Количество хештегов"
                  // inputProps={{min: 0}}
                  inputProps={{
                               style: { textAlign: 'center' }
                               }}
                  fullWidth
                  variant="outlined"
                  align = "center"
                  //defaultValue={chooseTemplateUser.nameTemplate}
                  value={countHashTag}
                  //onChange={(e) => setCountHashTag(e.target.value)}
                  onChange={(e) => handleSetCountHashTag(e.target.value)}
                  sx = {styleTextField}
                />  
          </FormControl>
        </Box>                      

        {/************************************************************************************************/}
        {/*************************           ЗАПРОС К СЕРВЕРУ                 ***************************/}
        {/************************************************************************************************/} 
        <Box 
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 0,
                boxShadow: 0,
                pt: 0,
                px: 0,
                pb: 0,
              }}>         
        <Button size="small" variant="contained" color="primary" spacing={1} align='center'
                disabled={visibleLinearProgress}
                sx={{borderRadius: '10px',
                        bgcolor: 'n5.main',
                        color: 'n0.main', 
                        '&:hover': {
                                  backgroundColor: 'n4_1.main'
                        },         
                        mt: 0, 
                        width: '100%', 
                        fontSize: 10,}}
                onClick={getHashtag}>
              Получить хештеги
        </Button>
        </Box> 

        {/************************************************************************************************/}
        {/*************************           ПРОГРЕСС - БАР                ******************************/}
        {/************************************************************************************************/}                 
        <Box sx={{ ///*********** ПРогресс- БАР */
                marginTop: 1,
                marginBottom: 0,
                display: 'block',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 0,
                boxShadow: 0,
                pt: 0,
                px: 1,
                pb: 0,
              }}>
            {visibleLinearProgress && <LinearProgress />}
        </Box> 

        {/************************************************************************************************/}
        {/****************               ХЕШТЕГИ И СТАТИСТИКА ЗАПРОСА       ******************************/}
        {/************************************************************************************************/}       
        <Box //---Хештеги и статистика
              sx={{
                marginTop: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 0,
                boxShadow: 0,
                pt: 0,
                px: 0,
                pb: 0,
              }}>
            <TextField
                      sx={{m: 1, width: '100%'}}
                      inputProps={{ 
                                    // id: "idinputProps-getting-hashtag", 
                                    // name: "inputProps-getting-hashtag", 
                                    style: {fontSize: 13, lineHeight: 1.4}}}
                      InputLabelProps={{
                        //  id:"InputLabelProps-getting-hashtag",
                        //  name: "InputLabelProps-getting-hashtag",
                         shrink: true }}
                      id="id-getting-hashtag"
                      name="name-getting-hashtag"
                      autoComplete='off'
                      label="Хештеги"
                      multiline
                      rows={8}
                      value={gettingHashtag} /> 
            <FormHelperText className='noselect'variant="filled" 
                            //error={true}
                            sx={{color: "errorUser.main"}}
                            >
              {helperTextHashtag}
            </FormHelperText>
            <Typography variant="caption" gutterBottom align="left" sx={{mt:1}}>
              {//timeWork + " | Хештегов: " + countAllHashtag + " | Символов: " + gettingHashtag.length
                timeWork + " | Хештегов: " + chipData.length + " | Символов: " + countSymbol
              }
            </Typography>       
        </Box>

        {/************************************************************************************************/}
        {/************               КНОПКИ КОПИРОВАНИЯ И ПЕРЕМЕШИВАНИЯ             ***********************/}
        {/************************************************************************************************/} 
        <Box sx={{ ///*********** Кнопки копирования и перемещивания */
                width: '100%',
                marginTop: 0,
                marginBottom: 0,
                display: 'block',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 0,
                boxShadow: 0,
                pt: 1,
                px: 1,
                pb: 1,
              }}>
          <Grid container alignItems="center" sx={{width: '100%',}}>
            <Grid item xs={6} align='center'>  
                <Button size="small" variant="contained"
                        sx={{borderRadius: '10px',
                              bgcolor: 'n5.main',
                              color: 'n0.main', 
                              '&:hover': {
                                        backgroundColor: 'n4_1.main'
                              },         
                              mt: 0,
                              width: '80%', 
                              fontSize: 10,}}
                        onClick={mixerHashtag}>
                      Перемешать
                </Button>
            </Grid>    
            <Grid item xs={6} align='center'>  
              <Button size="small" variant="contained"
                      sx={{borderRadius: '10px',
                            bgcolor: 'n5.main',
                            color: 'n0.main', 
                            '&:hover': {
                                      backgroundColor: 'n4_1.main'
                            },         
                            mt: 0, 
                            width: '80%', 
                            fontSize: 10,}}
                      onClick={copyToClipbord}>
                    В буффер
              </Button>
            </Grid>  
          </Grid> 
          
          <Grid ///*********** Кнопки копирования в буффер поблочно ***********/
                container alignItems="center" sx={{width: '100%',}}
          >
            <Grid item xs={12} align='center'
                  display={(blockTemplate2.indexOf(chooseTemplateCommon.id) !== -1) ? "true" : "none"}
            >  
                <Button size="small" variant="contained"
                        sx={{borderRadius: '10px',
                              bgcolor: 'n4.main',
                              color: 'n0.main', 
                              '&:hover': {
                                        backgroundColor: 'n4_1.main'
                              },         
                              mt: 2,
                              width: '60%', 
                              fontSize: 10,}}
                        onClick={copyToClipbordBlock1}>
                      В буффер первый блок
                </Button>
            </Grid>    
            <Grid item xs={12} align='center'
                  display={(blockTemplate2.indexOf(chooseTemplateCommon.id) !== -1) ? "true" : "none"}
            >  
              <Button size="small" variant="contained"
                      sx={{borderRadius: '10px',
                            bgcolor: 'n4.main',
                            color: 'n0.main', 
                            '&:hover': {
                                      backgroundColor: 'n4_1.main'
                            },         
                            mt: 1, 
                            width: '60%', 
                            fontSize: 10,}}
                      onClick={copyToClipbordBlock2}>
                    В буффер второй блок
              </Button>
            </Grid>
            <Grid item xs={12} align='center'
                  display={(blockTemplate3.indexOf(chooseTemplateCommon.id) !== -1) ? "true" : "none"}
            >  
              <Button size="small" variant="contained"
                      sx={{borderRadius: '10px',
                            bgcolor: 'n4.main',
                            color: 'n0.main', 
                            '&:hover': {
                                      backgroundColor: 'n4_1.main'
                            },         
                            mt: 1, 
                            width: '60%', 
                            fontSize: 10,}}
                      onClick={copyToClipbordBlock3}>
                    В буффер третий блок
              </Button>
            </Grid>    
          </Grid>
        </Box>  

        {/************************************************************************************************/}
        {/****************               ФИШКИ С ХЕШТЕГАМИ                  ******************************/}
        {/************************************************************************************************/}       
        <Box //---ФИШКИ С ХЕШТЕГАМИ
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 4,
                borderColor: 'n2.main',
                borderStyle: 'solid',
                borderWidth: 1,
                boxShadow: 1,
                pt: 1,
                px: 1,
                pb: 2,
              }}>
              <Typography variant="body2" 
                            gutterBottom 
                            align="center" 
                            width="100%"
                            maxWidth="599px"
                            sx={{marginTop: 1, 
                                paddingLeft: 0,
                                paddingRight: 0,
                                mb: 0,
                                color: 'black',
                                fontSize: '14px', fontFamily: 'Clear Sans, Arial, sans-serif;', fontWeight: 50, fontStyle: 'normal',
                            }}>
            Расширенные возможности
            </Typography>      
            <Typography variant="body2" 
                            gutterBottom 
                            align="center" 
                            width="100%"
                            maxWidth="599px"
                            sx={{marginTop: 0, 
                                paddingLeft: 0,
                                paddingRight: 0,
                                mb: 1,
                                color: 'n5.main',
                                fontSize: '12px', fontFamily: 'Clear Sans, Arial, sans-serif;', fontWeight: 50, fontStyle: 'normal',
                            }}>
            <i>(Кликните на тег чтобы выделить его)</i>
            </Typography>   
            <Paper
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    listStyle: 'none',
                    pt: 1.2,
                    pb: 0.9,
                    pl: 0.2,
                    pr: 0.2,
                    m: 0,
                    borderRadius: 1,
                    boxShadow: 0,
                    //borderColor: 'primary',
                    borderColor: 'n2.main',
                    borderStyle: 'solid',
                    borderWidth: 1,
                    width: "100%",
                  }}
                  component="ul"
            >
              {chipData.map((data) => {
                
                return (
                  //<ListItem key={data.key}>
                    <Chip
                      variant="outlined"
                      color={data.color}
                      key={data.key}
                      clickable={true}
                      size="small"
                      label={data.name}
                      sx={{ mb:0.3, ml: 0.2}}
                      onClick={handleClickChip(data.key)}
                    />
                  //</ListItem>
                );
              })}
            </Paper>   
            <Grid container alignItems="center" sx={{width: '100%',}}>
            <Grid item xs={6} align='center'>  
                <Button size="small" variant="contained"
                        sx={{borderRadius: '10px',
                              bgcolor: 'n5.main',
                              color: 'n0.main', 
                              '&:hover': {
                                        backgroundColor: 'n4_1.main'
                              },         
                              mt: 2,
                              width: '80%', 
                              fontSize: 10,}}
                        onClick={replaceHashtag}>
                      Заменить теги
                </Button>
            </Grid>  
            <Grid item xs={6} align='center'>  
                <Button size="small" variant="contained"
                        sx={{borderRadius: '10px',
                              bgcolor: 'errorUserDark.main',
                              color: 'n0.main', 
                              '&:hover': {
                                        backgroundColor: 'errorUserLight.main'
                              },         
                              mt: 2,
                              width: '80%', 
                              fontSize: 10,}}
                        onClick={deleteHashtag}>
                      Удалить теги
                </Button>
            </Grid> 
            </Grid>   
        </Box>      
        <br /><br /><br />              
        <Typography variant="body2" 
                            gutterBottom 
                            align="center" 
                            width="100%"
                            maxWidth="599px"
                            sx={{marginTop: 9, 
                                paddingLeft: 0,
                                paddingRight: 3,
                                mb: 7,
                                color: 'n4.main',
                                fontSize: '12px', fontFamily: 'Clear Sans, Arial, sans-serif;', fontWeight: 50, fontStyle: 'normal',
                            }}>
            *Соцсети Instagram и Facebook запрещены в РФ; они принадлежат корпорации Meta, которая признана в РФ экстремистской.                  
            </Typography>                          




        {/************************************************************************************************/}
        {/*************************               СНЕК - БАР                ******************************/}
        {/************************************************************************************************/} 
        <Snackbar open={openSnackBar} 
                  autoHideDuration={3000} 
                  onClose={handleCloseSnackBar}
                  anchorOrigin={{ vertical: 'bottom',
                                  horizontal: 'center', }}>
          <Alert onClose={handleCloseSnackBar} severity={snackBarStatus} variant='standard' sx={{ width: '100%' }}>
            {snackBarText}
          </Alert>
        </Snackbar>                      
    </Container>
</ThemeProvider>
  </>
  ) 
//}}
                        }
                   }
} 
}

export default observer(Gettinghashtag);
