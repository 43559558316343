import React from "react";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeCol, styleTextField, stylePasswordField } from '../../css/themes';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
// import Divider from '@mui/material/Divider';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Tooltip from '@mui/material/Tooltip';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Link from '@mui/material/Link';

import { pulse  } from "react-animations";
import styled, { keyframes } from "styled-components";

import DataService from "../../services/DataService";

import { DialogAddTariff } from "../../component/DialogAddTariff"
import { DialogAddCategory } from "../../component/DialogAddCategory"
// import TextFieldName from "../../component/TextFieldName"


import { useContext } from "react";

import { observer } from "mobx-react-lite"
import { ContextStore } from '../../index';

import logoYoKassa from "../../images/yookassa-logo.svg";

import "../../fonts/akrobat.css";
import "../../fonts/gilroy.css";

const uuid = require('uuid');

const PulseAnimation = keyframes`${pulse}`;
const PulseDiv = styled.div`
  animation: infinite 3s ${PulseAnimation};
`;


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


// const WrappedTextField = ({ value, error, onChange }) => (
//   <TextField
//               margin='none'
//               required
//               fullWidth
//               id="text-field-username"
//               label="Имя или псевдоним"
//               name="username"
//               autoComplete="username"
//               value={value}
//               onChange={onChange}
//               error={error}
//               // sx = { styleTextField }
//               InputLabelProps = {{ 
//                 sx: {   
//                         fontSize: '16px',
//                         fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
//                          }
//               }}
//               inputProps={{  //Работает
//                       sx: {
//                         paddingLeft: '20px',
//                         fontSize: '14px',
//                         fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
//                           }, 
//                          }}   
//             /> 
//   // <TextField name="author" label="author" value={value} onChange={onChange} />
// );

// async getTimer = (strSQL) => {
//   return new Promise((resolve, reject)=>{
//       setTimeout(() => {  console.log("Задержка в функции!"); return resolve("qq"); }, 5000); 
//   });
// };

const Profilepage = () => {
    
    const {store} = useContext(ContextStore);
    const themeColor = createTheme(themeCol);

    const [email, setEmail] = useState("");
    
    const [userName, setUserName] = useState("");
    const [userNameError, setUserNameError] = useState(false);
    const [visibleUserNameError, setVisibleUserNameError] = useState(' ');
    
    const [userStatus, setUserStatus] = useState("Неизвестен");
    const [userStatusColor, setUserStatusColor] = useState('black');
    
    const [sex, setSex] = useState(1);
    
    const [tariff, setTariff] = useState([]);
    const [profession, setProfession] = useState();
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(0);

    // const [visibleBill, setVisibleBill] = useState(true);

    
    //  id              - глобальный id профессии пользователя 
    //  id_user         - id пользователя (auth_users)
    //  id_profession   - id профессии
    //  name_profession - наименование профессии
    //  main_category   - флаг основной категории
    //  id_tariff       - id тарифа (s_tariff)
    //  tariff_name     - наименование тарифа
    //  amount          - сумма
    const [bills, setBills] = useState([]);
    const [billAmountAll, setBillAmountAll] = useState(0);
    const [nBill, setNBill] = useState('');
    const [idempotenceKey, setIdempotenceKey] = useState('');
    const [paymentHistory, setPaymentHistory] = useState([]);

    //////////////////   ДИАЛОГИ
    const [openDialogAddTariff, setOpenDialogAddTariff] = useState(false);
    const [openDialogAddCategory, setOpenDialogAddCategory] = useState(false);

    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [snackBarText, setSnackBarText] = useState('');
    const [snackBarStatus, setSnackBarStatus] = useState('');
    const [loading, setLoading] = useState(false);
    
    const [valueTabs, setValueTabs] = React.useState(0);

    const [showPasswordOld, setShowPasswordOld] = useState(false);
    const [showPasswordNew, setShowPasswordNew] = useState(false);
    const [passwordOld, setPasswordOld] = useState("");
    const [passwordErrorOld, setPasswordErrorOld] = useState(false);
    
    const [passwordNew, setPasswordNew] = useState("");
    const [passwordErrorNew, setPasswordErrorNew] = useState(false);

    const [visiblePasswordErrorOld, setVisiblePasswordErrorOld] = useState(' ');
    const [visiblePasswordErrorNew, setVisiblePasswordErrorNew] = useState(' ');

  const handleChangeName = e => setUserName(e.target.value);

  const handleClickShowPasswordOld = () => {
    setShowPasswordOld(!showPasswordOld);    
  };
  
  const handleClickShowPasswordNew = () => {
    setShowPasswordNew(!showPasswordNew);    
  };

  ///////////////// TABS
  // function TabPanel(props) {
  //   const { children, value, index, ...other } = props;
  
  //   return (
  //     <Box
  //       role="tabpanel"
  //       hidden={value !== index}
  //       id={`simple-tabpanel-${index}`}
  //       aria-labelledby={`simple-tab-${index}`}
  //       {...other}
  //       alignItems="center"
  //       align="center"
  //     >
  //       {value === index && (
  //         <Box sx={{ p: 3 }}>
  //           {children}
  //         </Box>
  //       )}
  //     </Box>
  //   );
  // }
  
  // TabPanel.propTypes = {
  //   children: PropTypes.node,
  //   index: PropTypes.number.isRequired,
  //   value: PropTypes.number.isRequired
  // };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      //sx: {color: 'errorUser.main',}
    };
  }  


  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };
  /////////////////// TABS
    
    useEffect(() => {

        //console.log("useEffect");
        document.title = "Личный кабинет / besthashtag.ru";
        async function setParametrs () { 
            const responseT = await DataService.getTariff();
            if (responseT.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
                console.log ("401");
                store.setAuth(false);
                store.setStatus(responseT.status);
                store.setUser();
                store.setErrorStatusMessage(responseT.data);
                return;
            }
            setTariff(responseT.data);

            const responseP = await DataService.getProfession();
            if (responseP.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
                console.log ("401");
                store.setAuth(false);
                store.setStatus(responseP.status);
                store.setUser();
                store.setErrorStatusMessage(responseP.data);
                return;
            }
            setProfession(responseP.data);

            const responseK = await DataService.getCategories(store.user.userID);
            if (responseK.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
                console.log ("401");
                store.setAuth(false);
                store.setStatus(responseK.status);
                store.setUser();
                store.setErrorStatusMessage(responseK.data);
                return;
            }
            setCategories(responseK.data);

            setEmail(store.user.email);
            setUserName(store.user.userName);
            setSex(store.user.userSex);
            if (store.user.isPay && store.user.isActivated) {
                setUserStatus("Активен");
                setUserStatusColor("loginUser.main");
            }

            if (!store.user.isPay && store.user.isActivated) {
                setUserStatus("На счету нет средств");
                setUserStatusColor("guestUserDark.main");
            }

            if (!store.user.isActivated) {
              setUserStatus("Аккаунт неактивирован (откройте письмо для активации)");
              setUserStatusColor("guestUserDark.main");
            }

        }
        setParametrs ();
        window.scrollTo(0, 0);
      }, []); // eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {  // Отслеживаем Выбранные услуги. Генерируем уникальный ключ          
      
      const idemKey = uuid.v4();
      setIdempotenceKey(idemKey);
      
    }, [bills]); // eslint-disable-line react-hooks/exhaustive-deps  

    const handleCloseSnackBar = (event, reason) => {  //----- Закрытие снекбара 
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackBar(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(userName);
        let err = false;
        
        if (!userName) {
          setUserNameError(true);
          setVisibleUserNameError('Пустое поле'); err = true;
        } else {
          if (userName.length > 50) {
            setUserNameError(true);
            setVisibleUserNameError('Длина имени должна быть меньше 50 символов'); err = true;
          } else {
            setUserNameError(false);
            setVisibleUserNameError(' ');
          }  
        }
        
        if (err) {
          return;
        }  

        setLoading(true);
        const response = await DataService.saveUserData(store.user.userID, userName, sex);
        if (response.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
            console.log ("401");
            store.setAuth(false);
            store.setStatus(response.status);
            store.setUser();
            store.setErrorStatusMessage(response.data);
            return;
        }
        
        // eslint-disable-next-line
        const responseStore = await store.checkAuth();
        setUserName(store.user.userName);
        setSex(store.user.userSex);
        setLoading(false);
       
        setSnackBarText("Успешно сохранено"); setSnackBarStatus('success'); setOpenSnackBar(true);   
    }

    const handleSubmitPassword = async (event) => {  // ------   Смена пароля
      event.preventDefault();
      //console.log(passwordOld);
      //console.log(passwordNew);
      let err = false;
      
      if (!passwordNew) {
        setPasswordErrorNew(true);
        setVisiblePasswordErrorNew('Пустое поле'); err = true;
      } else { 
        if (passwordNew.length>32 || passwordNew.length<5) {
          setPasswordErrorNew(true);
          setVisiblePasswordErrorNew('Длина пароля должна быть от 5 до 32'); err = true;  
        } else {
          setPasswordErrorNew(false);
          setVisiblePasswordErrorNew(' ');
        }  
      }

      if (!passwordOld) {
        setPasswordErrorOld(true);
        setVisiblePasswordErrorOld('Пустое поле'); err = true;
      } else { 
        if (passwordOld.length>32 || passwordOld.length<5) {
          setPasswordErrorOld(true);
          setVisiblePasswordErrorOld('Длина пароля должна быть от 5 до 32'); err = true;  
        } else {
          setPasswordErrorOld(false);
          setVisiblePasswordErrorOld(' ');
        }  
      }
      
      if (err) {
        return;
      }  

      setLoading(true);
      const response = await DataService.changePassword(store.user.userID, passwordOld, passwordNew);

      if (response.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
          console.log ("401");
          store.setAuth(false);
          store.setStatus(response.status);
          store.setUser();
          store.setErrorStatusMessage(response.data);
          setLoading(false);
          return;
      }
      if (response.status === 400) {  //Если пароль неверен
        console.log ("400");
        setSnackBarText("Пароль неверен"); setSnackBarStatus('error'); setOpenSnackBar(true);  
        setLoading(false); 
        return;
      }
      if (response.status === 200) {  //Если пароль неверен
        console.log ("200");
        setLoading(false);
        setSnackBarText("Пароль изменен"); setSnackBarStatus('success'); setOpenSnackBar(true);
        return;
      }
      
      // // eslint-disable-next-line
      // const responseStore = await store.checkAuth();
      // setUserName(store.user.userName);
      // setSex(store.user.userSex);
      setLoading(false);
     
      setSnackBarText("Что то пошло не так"); setSnackBarStatus('error'); setOpenSnackBar(true);   
  }
    
    const handleDeleteBill = async (event) => {
        const newBills = bills.filter((b) => Number(b.id) !== Number(event.target.id));

        let newAmount = 0;
        newBills.forEach((item, index, array) => {
            newAmount = newAmount + Number(item.amount);
            });

        setBillAmountAll(newAmount);    
        setBills(newBills);
    }

    const handleOpenDialogAddTariff = (event) => {   //------------ Открытие диалога продления тарифа
        // eslint-disable-next-line
        let obj = bills?.find(o => o.id == event.target.id);
        
        if (obj) {
            setSnackBarText("Категория уже выбрана"); setSnackBarStatus('error'); setOpenSnackBar(true);
            return;
        }
        
        setSelectedCategory(event.target.id);
        setOpenDialogAddTariff(true);
    };
      
    const handleCloseDialogAddTariff = () => {  //------------ Закрытие диалога продления тарифа
      setOpenDialogAddTariff(false);
    };

    const handleOpenDialogAddCategory = (event) => {   //------------ Открытие диалога добавления категории
      setOpenDialogAddCategory(true);
    };
    
    const handleCloseDialogAddCategory = () => {  //------------ Закрытие диалога добавления категории
      setOpenDialogAddCategory(false);
    };

    const handlePay = async (event) => {  //------------ Оплата счета !!!!!!!!!!ОСНОВНОЕ
     
      if (bills.length === 0) {
        setSnackBarText("Услуги не выбраны"); setSnackBarStatus('error'); setOpenSnackBar(true);
        return;
      }
      const orderID = idempotenceKey.substring(0,8);
      setNBill(orderID);
      const response = await DataService.createPayment(store.user.userID,
                                                       store.user.email,
                                                       billAmountAll,
                                                       idempotenceKey,
                                                       orderID,
                                                       bills
                                                       );
      if (response.status === 400) {
        setSnackBarText("Не пройдена валидация"); setSnackBarStatus('error'); setOpenSnackBar(true);
      }                                                       
      const token = response.data.confirmation_token;
      


    const checkout = new window.YooMoneyCheckoutWidget({
        confirmation_token: token, //Токен, который перед проведением оплаты нужно получить от ЮKassa
        // ---------------- DEPLOY -----------------
        return_url: `https://besthashtag.ru/api/paymentDone?link=${idempotenceKey}`, //Ссылка на страницу завершения оплаты
        // ---------------- DEVELOP -----------------
        //return_url: `http://localhost:3001/api/paymentDone?link=${idempotenceKey}`,

        //Настройка виджета
        customization: {
            //Настройка способа отображения
            modal: true,
            colors: {
              //Цвет акцентных элементов: кнопка Заплатить, выбранные переключатели, опции и текстовые поля
              control_primary: '#1f5f8b' //Значение цвета в HEX
            }
        },
        error_callback: function(error) {
            //Обработка ошибок инициализации
        }
    });
    
    //Отображение платежной формы в модальном окне
    checkout.render()
    //Метод возвращает Promise, исполнение которого говорит о полной загрузке платежной формы (можно не использовать).
      .then(() => {
         //Код, который нужно выполнить после отображения платежной формы.
         console.log("pay then");
    });




    };

    const handleHistory = async (event) => {
      //event.preventDefault();
      
      setLoading(true);
      const response = await DataService.getPaymentHistory(store.user.userID);
      if (response.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
          console.log ("401");
          store.setAuth(false);
          store.setStatus(response.status);
          store.setUser();
          store.setErrorStatusMessage(response.data);
          return;
      }
      
      setPaymentHistory(response.data);

      // const responseStore = await store.checkAuth();
      // setUserName(store.user.userName);
      // setSex(store.user.userSex);
      setLoading(false);
     
      // setSnackBarText("Успешно сохранено"); setSnackBarStatus('success'); setOpenSnackBar(true);   
  }
    
    if (!store.isAuth) { 
      return (<Navigate to="/" />);
    } else {  
      
    return(
      <div align='center'> 
      <ThemeProvider theme={themeColor}>
      <Box //---BOX 
          id="main-box"
          sx={{ width: '95%',
                maxWidth: "1000px",
                marginTop: 12,
                paddingBottom: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                alignContent: 'center',
                align: 'center',
                borderRadius: 4, borderColor: 'n2.main', borderStyle: 'solid', boxShadow: 1, borderWidth: 1,
              }}>

      {/* //////////////////////          Диалог продления тарифа //////////////////////////////// */}
      <DialogAddTariff onClose={handleCloseDialogAddTariff} 
                        tariff={tariff}
                        selectedCategory={selectedCategory}
                        categories={categories}
                        bills={bills}
                        setBills={setBills}
                        billAmountAll={billAmountAll}
                        setBillAmountAll={setBillAmountAll}
                        setIdempotenceKey={setIdempotenceKey}
                        open={openDialogAddTariff} />
      
      <DialogAddCategory onClose={handleCloseDialogAddCategory} 
                          profession={profession}
                          categories={categories}
                          setCategories={setCategories}
                          open={openDialogAddCategory} />                 

      <Avatar sx={{ m: 1, marginTop: 4, bgcolor: 'n4.main' }}>
          <AccountCircleIcon />
      </Avatar> 

      <Typography variant="body1" 
                      gutterBottom 
                      align="center" 
                      sx={{marginTop: 0.5, mb: 4, paddingLeft: 0, 
                          color: 'n4.main',
                          fontSize: '40px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 200,}}>
      Личный кабинет
      </Typography> 
          
          
      {/* ////////////////////////////////////////////////////////////////////////////////////////////////////
      /////////////////////////////////    Данные пользователя            ///////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <Box component="form" //BOX с данными пользователя и вкладками
        id="user-email-status"
        //onSubmit={handleSubmit} 
        noValidate sx={{ width: '95%', maxWidth: "1000px", 
                        mt: 1.5 , paddingLeft: 2, paddingRight: 2,
                        borderRadius: 4, borderColor: 'n2.main', borderStyle: 'solid', boxShadow: 1, borderWidth: 1,
                      }}>
      
      
        <Grid container spacing={1} width="100%" sx={{mt: 2, mb: 0, paddingLeft: 2, paddingRight: 2}}>
              
              <Grid item xs={3}>
              <Typography variant="body1" 
                          gutterBottom 
                          align="left" 
                          sx={{marginTop: 0, mb: 0, paddingLeft: 0, 
                              color: 'n5.main',
                              fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,}}>
                  Аккаунт
              </Typography> 
              </Grid>   
              <Grid item xs={9}>
              <Typography variant="body1" 
                          gutterBottom 
                          align="left" 
                          sx={{marginTop: 0, mb: 0, paddingLeft: 0, 
                              color: 'n4.main',
                              fontSize: '14px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400,}}>
                  {email}
              </Typography> 
              </Grid>
              <Grid item xs={3}>
              <Typography variant="body1" 
                          gutterBottom 
                          align="left" 
                          sx={{marginTop: 0, mb: 0, paddingLeft: 0, 
                              color: 'n5.main',
                              fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,}}>
                  Статус
              </Typography> 
              </Grid>   
              <Grid item xs={9}>
              <Typography variant="body1" 
                          gutterBottom 
                          align="left"
                          color={userStatusColor}
                          sx={{marginTop: 0, mb: 0, paddingLeft: 0, 
                              //color: "#21b841",
                              fontSize: '14px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400,}}>
                  {userStatus}
              </Typography> 
              </Grid>   
        </Grid>
      
          {/* ////////////////////////////////////////////////////////////////////////////////////////////////////
          //////////////////////////////      ВКЛАДКИ         ///////////////////////////////////////////////
          ////////////////////////////////////////////////////////////////////////////////////////////////////                       */}
          <Box id="main-box-tabs" mb sx={{ width: "100%", marginTop: 2, mb: 2, borderColor: 'n2.main', borderStyle: 'solid', boxShadow: 0, borderWidth: 1 }} >
          <Box id="main-box-tabs-zagolovok" sx={{ borderBottom: 1, borderColor: "divider", }}>
            <Tabs   /////////   Заголовки вкладок
              value={valueTabs}
              onChange={handleChangeTabs}
              aria-label="basic tabs example"
            >
              <Tab label="Личные данные" sx={{color: 'n3.main',
                                              fontSize: '12px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 600,
                                            }} {...a11yProps(0)} />             
              <Tab label="Сменить пароль" sx={{color: 'n3.main',
                                              fontSize: '12px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 600,
                                            }} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <Box  value={valueTabs} index={0}  /////    Вкладка с именем пользователя и полом - 0
                role="tabpanel"
                hidden={valueTabs !== 0}
                id={`simple-tabpanel-1`}
                aria-labelledby={`simple-tab-1`}
                
                alignItems="center"
                align="center">
            {valueTabs === 0 && (
              <Box sx={{ p: 3 }}>
                  <FormControl id="form-field-username" sx={{ mt: 2 }} variant="outlined" fullWidth> 
        
                  <TextField
                      margin='none'
                      required
                      fullWidth
                      id="text-field-username"
                      label="Имя или псевдоним"
                      name="username"
                      autoComplete="username"
                      value={userName}
                      onChange={handleChangeName}
                      error={userNameError}
                      sx = { styleTextField }
                      InputLabelProps = {{ 
                        sx: {   
                                fontSize: '16px',
                                fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
                                }
                                        }}
                      inputProps={{  //Работает
                              sx: {
                                paddingLeft: '20px',
                                fontSize: '14px',
                                fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
                                  }, 
                                  }}   
                      /> 
                    <FormHelperText id="help-username" sx={{color: '#ff0000'}}>
                      {visibleUserNameError}
                    </FormHelperText>  
                  </FormControl>
                  
                  <FormControl id="form-field-sex" sx={{ mt: 1 }} variant="outlined" fullWidth align="left"  >
                    <InputLabel id="select-sex-label" sx={{
                            fontSize: '16px',
                            fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,
                        }}>Выберите пол</InputLabel>
                    <Select
                      
                      labelId="select-sex-label"
                      id="input-select-sex-label"
                      value={sex}
                      label="Выберите пол"
                      sx ={stylePasswordField} 
                      inputProps={{  //Работает
                        sx: {
                            paddingLeft: '20px',
                            fontSize: '14px',
                            fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,
                        },
                      }} 
                      onChange={(e) => setSex(e.target.value)}
                      >
                        <MenuItem value={1} sx={{fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,}}>
                            Мужской</MenuItem>
                        <MenuItem value={2} sx={{fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,}}>
                            Женский</MenuItem>
                    </Select>
                    <FormHelperText id="help-input-select-sex-label" sx={{color: '#ff0000'}}>
                      {' '}
                    </FormHelperText>  
                  </FormControl>
    
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{ mt: 1, 
                          mb: 1, 
                          width: "300px",
                          height: '25px',
                          bgcolor: 'n5.main',
                          fontSize: '12px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
                          color: 'n0.main', 
                            '&:hover': {
                                        backgroundColor: 'n4_1.main'
                            }, 
                          borderRadius: '20px'}}>
                    Сохранить
                  </Button>
              </Box>
            )}
            </Box> 

            {/* /////////////////////////////////////////////////////////////////////////////
          ////////////////////////      СМЕНИТЬ ПАРОЛЬ    ////////////////////////////
          ///////////////////////////////////////////////////////////////////////////// */}                     
          <Box  value={valueTabs} index={1}  /////    Вкладка с паролем - 1
                role="tabpanel"
                hidden={valueTabs !== 1}
                id={`simple-tabpanel-2`}
                aria-labelledby={`simple-tab-2`}
                alignItems="center"
                align="center">
            {valueTabs === 1 && (
              <Box sx={{ p: 3 }}>
                <FormControl sx={{ mt: 2 }} variant="outlined" fullWidth>
                  <InputLabel required htmlFor="select-password-label-old"
                        sx={{
                            fontSize: '16px',
                            fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,
                            }}>Введите старый пароль</InputLabel>
                  <OutlinedInput 
                    id="select-password-label-old"
                    type={showPasswordOld ? 'text' : 'password'}
                    value={passwordOld}
                    onChange={(e) => setPasswordOld(e.target.value)}
                    label="Введите старый пароль"
                    error={passwordErrorOld}
                    sx ={stylePasswordField}      
                    inputProps={{  //Работает
                        sx: {
                          paddingLeft: '20px',
                          fontSize: '16px',
                          fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,
                        },
                    }}
                    endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPasswordOld}
                                      //onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPasswordOld ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                    }/>
                  <FormHelperText id="help-select-password-label" sx={{color: '#ff0000'}}>
                          {visiblePasswordErrorOld}
                  </FormHelperText>  
                </FormControl>

                <FormControl sx={{ mt: 1 }} variant="outlined" fullWidth>
                  <InputLabel required htmlFor="select-password-label-new"
                        sx={{
                            fontSize: '16px',
                            fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,
                            }}>Введите новый пароль</InputLabel>
                  <OutlinedInput 
                    id="select-password-label-new"
                    type={showPasswordNew ? 'text' : 'password'}
                    value={passwordNew}
                    onChange={(e) => setPasswordNew(e.target.value)}
                    label="Введите новый пароль"
                    error={passwordErrorNew}
                    sx ={stylePasswordField}      
                    inputProps={{  //Работает
                        sx: {
                          paddingLeft: '20px',
                          fontSize: '16px',
                          fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,
                        },
                    }}
                    endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPasswordNew}
                                      //onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPasswordNew ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                    }/>
                  <FormHelperText id="help-select-password-label" sx={{color: '#ff0000'}}>
                          {visiblePasswordErrorNew}
                  </FormHelperText>  
                </FormControl>    

                <Button
                  //type="submit"
                  onClick={handleSubmitPassword}
                  variant="contained"
                  sx={{ mt: 1, 
                        mb: 1, 
                        width: "300px",
                        height: '25px',
                        bgcolor: 'n5.main',
                        fontSize: '12px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
                        color: 'n0.main', 
                          '&:hover': {
                                      backgroundColor: 'n4_1.main'
                          }, 
                        borderRadius: '20px'}}

                >
                  Сменить пароль
                </Button>
              </Box>
            )}
          </Box>                                   
        </Box>
      </Box>      
      

      {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////
       ////////////////////////////////       СПИСОК КАТЕГОРИЙ      ///////////////////////////////////////////
       //////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <Box component="form" //Список категорий
          noValidate sx={{ width: '95%', maxWidth: "1000px", 
                          mt: 1.5 , paddingLeft: 0.5, paddingRight: 0, paddingBottom:3,
                          borderRadius: 4, borderColor: 'n2.main', borderStyle: 'solid', boxShadow: 1, borderWidth: 1,
                        }}>
      
        <Grid container spacing={1} width="95%" alignItems="center" //Список категорий - Заголовок и кнопка добавления
              sx={{mt: 2, mb: 2, paddingLeft: 0, paddingRight: 0, borderStyle: 'solid', boxShadow: 0, borderWidth: 0}}>
          <Grid item xs={8} >
          <Typography variant="body1" 
                            gutterBottom 
                            align="center" 
                            sx={{marginTop: 0, mb: 0, paddingLeft: 0, 
                                borderRadius: 4, borderColor: 'n2.main', borderStyle: 'solid', boxShadow: 0, borderWidth: 0,
                                color: 'n5.main',
                                fontSize: '18px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,}}>
            Список категорий
          </Typography> 
          </Grid>
          <Grid item xs={4} >
                
                <Tooltip title="Добавить категорию">
                <Button id={"addCategoty"}
                        variant="outlined"
                        onClick={handleOpenDialogAddCategory}
                        sx={{ mt: 0, 
                            mb: 0, 
                            width: "95%",
                            maxWidth: "100px",
                            maxHeight: "25px",
                            fontSize: '12px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
                            boxShadow: 1,
                            backgroundColor: 'n5.main',
                            color: 'white', 
                                '&:hover': {
                                            borderWidth: 0,
                                            backgroundColor: 'n4_1.main'
                                }, 
                            borderRadius: '10px'}}>
                    Добавить
                </Button>
                </Tooltip>
          </Grid>
        </Grid>

      <Grid container spacing={1} width="95%"  // Список категорий - шапка таблицы
            sx={{mt: 0, mb: 0, paddingLeft: 0, paddingRight: 0, borderStyle: 'solid', boxShadow: 0, borderWidth: 0}}>    
        <Grid item xs={3} sx={{borderWidth: 2, borderBottom: 2, borderTop: 2, borderColor: 'n3.main'}}>
        <Typography variant="body1" 
                    //gutterBottom 
                    align="center" 
                    sx={{marginTop: 0, mb: 1, paddingLeft: 0, 
                        color: 'n5.main',
                        fontSize: '14px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 600,}}>
          Категория
        </Typography>
        </Grid>
        <Grid item xs={3} sx={{borderWidth: 2, borderBottom: 2, borderTop: 2, borderColor: 'n3.main'}}>
        <Typography variant="body1" 
                    //gutterBottom 
                    align="center" 
                    sx={{marginTop: 0, mb: 1, paddingLeft: 0, 
                        color: 'n5.main',
                        fontSize: '14px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 600,}}>
          Оплачено до
        </Typography>  
        </Grid>
        <Grid item xs={3} sx={{borderWidth: 2, borderBottom: 2, borderTop: 2, borderColor: 'n3.main'}}>
        <Typography variant="body1" 
                    //gutterBottom 
                    align="center" 
                    sx={{marginTop: 0, mb: 1, paddingLeft: 0, 
                        color: 'n5.main',
                        fontSize: '14px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 600,}}>
          Кол-во генераций
        </Typography>  
        </Grid>
        <Grid item xs={3} sx={{borderWidth: 2, borderBottom: 2, borderTop: 2, borderColor: 'n3.main'}}>
        <Typography variant="body1" 
                    //gutterBottom 
                    align="center" 
                    sx={{marginTop: 0, mb: 1, paddingLeft: 0, 
                        color: 'n5.main',
                        fontSize: '14px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 600,}}>
          Продлить/Активировать
        </Typography>  
        </Grid>
            
        {categories.map((category) => ( // Список категорий пользователя из БД
        // Enterprise card is full width at sm breakpoint
        <React.Fragment key={"fragmentCategory" + category.id}>
        <Grid item xs={3} sx={{borderBottom: 1, borderColor: 'n3.main'}}>
          <Typography variant="body1" 
                      //gutterBottom
                      align="justify" 
                      color="n5.main"
                      fontWeight={category.fontWeight}
                      sx={{marginTop: 0.2, mb: 0, paddingLeft: 0, 
                            fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;',}}>
              {category.name_profession}
          </Typography>    
        </Grid>
        <Grid item xs={3}  sx={{borderBottom: 1, borderColor: 'n3.main'}}>
          <Typography variant="body1" 
                      alignSelf="center"
                      justifySelf="center"
                      align="center" 
                      color={category.colorDate}
                      fontWeight="400"
                      sx={{marginTop: 0.2, mb: 0, paddingLeft: 0, 
                            fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;',}}>
              {category.paidDate}
          </Typography>    
        </Grid>
        <Grid item xs={3}  sx={{borderBottom: 1, borderColor: 'n3.main'}}>
          <Typography variant="body1" 
                      alignSelf="center"
                      justifySelf="center"
                      align="center" 
                      color="n5.main"
                      fontWeight="400"
                      sx={{marginTop: 0.2, mb: 0, paddingLeft: 0, 
                            fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;',}}>
              {category.countGeneration}
          </Typography>    
        </Grid>
        <Grid item xs={3} sx={{borderBottom: 1, borderColor: 'n3.main'}}>
          <Button id={category.id}
                  key={"category" + category.id}
                  variant="outlined"
                  onClick={handleOpenDialogAddTariff}
                  sx={{ mt: 0, 
                      mb: 0.2, 
                      width: "95%",
                      maxWidth: "100px",
                      height: "70%",
                      maxHeight: "30px",
                      fontSize: '12px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
                      boxShadow: 1,
                      backgroundColor: 'lightLoginUser.main',
                      color: 'white', 
                          '&:hover': {
                                      borderWidth: 0,
                                      backgroundColor: 'superLightLoginUser.main'
                          }, 
                      borderRadius: '10px'}}>
              {(category.paidDate) ? "Продлить" : "Активация"}
          </Button>
        </Grid>
        </React.Fragment>
        ))}     
      </Grid>
      
      
    {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////               СЧЕТ              ///////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      
      <Box align="center" alignItems="center" alignContent="center"
          noValidate sx={{ width: '100%', maxWidth: "1000px", 
                          mt: 5 , paddingLeft: 0, paddingRight: 0, paddingBottom:1,
                          borderRadius: 4, borderColor: 'n2.main', borderStyle: 'solid', boxShadow: 0, borderWidth: 0,
                        }}>
        
        <Typography variant="body1" 
                            gutterBottom 
                            align="center" 
                            sx={{marginTop: 2, mb: 0, paddingLeft: 0, 
                                borderRadius: 4, borderColor: 'n2.main', borderStyle: 'solid', boxShadow: 0, borderWidth: 0,
                                color: 'n5.main',
                                fontSize: '18px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,}}>
            Выбранные услуги
        </Typography>

        <Typography variant="body1" 
                        alignSelf="center"
                        justifySelf="middle"
                        align="center" 
                        color="n5.main"
                        fontWeight="600"
                        sx={{marginTop: 2, mb: 2, paddingLeft: 0, 
                              fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;',}}>
            Счет №{!(nBill) ? 'б/н' : nBill}                    
        </Typography> 

        <Grid container spacing={1} width="95%" 
            sx={{mt: 0, mb: 0, paddingLeft: 0.5, paddingRight: 0, borderStyle: 'solid', boxShadow: 0, borderWidth: 0}}>
          {/* ///////////////////      Шапка - Выбранные услуги  ///////////////////////// */}
          <Grid item xs={4} sx={{borderWidth: 2, borderBottom: 2, borderTop: 2, borderColor: 'n3.main'}}>
          <Typography variant="body1" 
                      align="center" 
                      sx={{marginTop: 0, mb: 1, paddingLeft: 0, 
                          color: 'n5.main',
                          fontSize: '14px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 600,}}>
            Категория
          </Typography>
          </Grid>
          <Grid item xs={3} sx={{borderWidth: 2, borderBottom: 2, borderTop: 2, borderColor: 'n3.main'}}>
          <Typography variant="body1" 
                      //gutterBottom 
                      align="center" 
                      sx={{marginTop: 0, mb: 1, paddingLeft: 0, 
                          color: 'n5.main',
                          fontSize: '14px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 600,}}>
            Тариф
          </Typography>  
          </Grid>
          <Grid item xs={2} sx={{borderWidth: 2, borderBottom: 2, borderTop: 2, borderColor: 'n3.main'}}>
          <Typography variant="body1" 
                      //gutterBottom 
                      align="center" 
                      sx={{marginTop: 0, mb: 1, paddingLeft: 0, 
                          color: 'n5.main',
                          fontSize: '14px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 600,}}>
            Сумма
          </Typography>  
          </Grid>
          <Grid item xs={3} sx={{borderWidth: 2, borderBottom: 2, borderTop: 2, borderColor: 'n3.main'}}>
          <Typography variant="body1" 
                      //gutterBottom 
                      align="center" 
                      sx={{marginTop: 0, mb: 1, paddingLeft: 0, 
                          color: 'n5.main',
                          fontSize: '14px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 600,}}>
            Удалить
          </Typography>  
          </Grid>

          {bills?.map((bill) => (
            <React.Fragment key={"fragmentBill" + bill.id}>
            <Grid item xs={4} sx={{borderBottom: 1, borderColor: 'n3.main'}}>
              <Typography variant="body1" 
                          //gutterBottom
                          align="justify" 
                          color="n5.main"
                          fontWeight="400"
                          sx={{marginTop: 0.2, mb: 0, paddingLeft: 0, 
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;',}}>
                  {bill.name_profession}
              </Typography>    
            </Grid>
            <Grid item xs={3}  sx={{borderBottom: 1, borderColor: 'n3.main'}}>
              <Typography variant="body1" 
                          alignSelf="center"
                          justifySelf="center"
                          align="center" 
                          color="n5.main"
                          fontWeight="400"
                          sx={{marginTop: 0.2, mb: 0, paddingLeft: 0, 
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;',}}>
                  {bill.tariff_name}
              </Typography>    
            </Grid>
            <Grid item xs={2}  sx={{borderBottom: 1, borderColor: 'n3.main'}}>
              <Typography variant="body1" 
                          alignSelf="center"
                          justifySelf="center"
                          align="center" 
                          color="n5.main"
                          fontWeight="400"
                          sx={{marginTop: 0.2, mb: 0, paddingLeft: 0, 
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;',}}>
                  {bill.amount} ₽
              </Typography>    
            </Grid>
            <Grid item xs={3} sx={{borderBottom: 1, borderColor: 'n3.main'}}>
              <Button key={"billButton " + bill.id}
                      id={bill.id}
                      variant="outlined"
                      onClick={handleDeleteBill}
                      sx={{ mt: 0, 
                          mb: 0.2, 
                          maxWidth: "5px",
                          height: "70%",
                          maxHeight: "30px",
                          fontSize: '12px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
                          boxShadow: 1,
                          backgroundColor: 'errorUser.main',
                          borderWidth: 0,
                          color: 'white', 
                              '&:hover': {
                                          borderWidth: 0, 
                                          backgroundColor: 'errorUserLight.main'
                              }, 
                          borderRadius: '10px'}}
                  >
                      &#10006;
              </Button>
            </Grid>
            </React.Fragment>
          ))}
            
          {/* ////////////////////////  ИТОГО    /////////////////////////////// */}
          <Grid item xs={4} sx={{borderBottom: 0, borderColor: 'n3.main'}}> 
          </Grid>
          <Grid item xs={3}  sx={{borderBottom: 0, borderColor: 'n3.main'}} >
            <Typography variant="body1" 
                        alignSelf="center"
                        justifySelf="center"
                        align="center" 
                        color="n5.main"
                        fontWeight="600"
                        sx={{marginTop: 0.2, mb: 0, paddingLeft: 0, 
                              fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;',}}>
                Итого:
            </Typography>    
          </Grid>
          <Grid item xs={2}  sx={{borderBottom: 0, borderColor: 'n3.main'}}>
            <Typography variant="body1" 
                        alignSelf="center"
                        justifySelf="center"
                        align="center" 
                        color="n5.main"
                        fontWeight="600"
                        sx={{marginTop: 0.2, mb: 0, paddingLeft: 0, 
                              fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;',}}>
                {billAmountAll} ₽
            </Typography>    
          </Grid>
          <Grid item xs={3} sx={{borderBottom: 0, borderColor: 'n3.main'}}>
          </Grid>
        </Grid>                                
        
        {/* ////////////////////  РОБОКАССА  ////////////////////// */}
        <Grid container spacing={0} width="95%" alignItems="center"
            sx={{mt: 3, mb: 0, paddingLeft: 0, paddingRight: 0, paddingTop: 0, borderStyle: 'solid', boxShadow: 0, borderWidth: 0}}
            //disableEqualOverflow='true'
            >
        <Grid item xs={6} sx={{borderBottom: 0, borderColor: 'n3.main', borderWidth: 0 }}> 
          <Typography variant="body1" 
                        alignSelf="center"
                        justifySelf="middle"
                        align="center" 
                        color="n5.main"
                        fontWeight="400"
                        sx={{marginTop: 0, mb: 0, paddingLeft: 0, 
                              fontSize: '16px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;',}}>
            Оплатить через:                   
          </Typography>
        </Grid>
        <Grid item xs={4} sx={{borderBottom: 0, borderColor: 'n3.main'}}> 
          <PulseDiv>
          <Button 
                      //type="submit"
                      onClick={handlePay}
                      align="center"
                      variant="contained"
                      sx={{ mt: 0, 
                            mb: 0, 
                            width: "150px",
                            height: "40px",
                            bgcolor: 'n5.main',
                            color: 'black.main', 
                              '&:hover': {
                                          backgroundColor: 'n3.main'
                              }, 
                            borderRadius: '20px'}}
                    >
                      <img src={logoYoKassa} alt="my" width={"100px"} />
          </Button>
          </PulseDiv>  
        </Grid>  
        <Grid item xs={2} sx={{borderBottom: 0, borderColor: 'n3.main'}}> 
        </Grid> 
        </Grid>  
        <Typography mt={6} component="h1" variant="caption" align='center'>
            <Link href="/offer" target="_blank">
                    {"Договор публичной оферты"}
            </Link> 
        </Typography>
      </Box>
      </Box>    
          

      {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////
      ////////////////////////////////       ИСТОРИЯ ПЛАТЕЖЕЙ       ///////////////////////////////////////////
      //////////////////////////////////////////////////////////////////////////////////////////////////////// */}
      <Box noValidate sx={{ width: '95%', maxWidth: "1000px", 
                            mt: 1.5 , paddingLeft: 0, paddingRight: 0, paddingBottom:3,
                            borderRadius: 4, borderColor: 'n2.main', borderStyle: 'solid', boxShadow: 1, borderWidth: 1,
                          }}>
        
        <Typography variant="body1" 
                          gutterBottom 
                          align="center" 
                          sx={{marginTop: 2, mb: 0, paddingLeft: 0, 
                              borderRadius: 4, borderColor: 'n2.main', borderStyle: 'solid', boxShadow: 0, borderWidth: 0,
                              color: 'n5.main',
                              fontSize: '18px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,}}>
          История платежей
        </Typography> 

        <Grid container spacing={1} width="95%" 
              sx={{mt: 0, mb: 0, paddingLeft: 0.5, paddingRight: 0, borderStyle: 'solid', boxShadow: 0, borderWidth: 0}}>
              
          <Grid item xs={4} sx={{borderWidth: 2, borderBottom: 4, borderColor: 'n3.main'}}>
            <Typography variant="body1" 
                        //gutterBottom 
                        align="center" 
                        sx={{marginTop: 0, mb: 1, paddingLeft: 0, 
                            color: 'n5.main',
                            fontSize: '14px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 600,}}>
              Номер счета
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{borderWidth: 2, borderBottom: 4, borderColor: 'n3.main'}}>
            <Typography variant="body1" 
                        //gutterBottom 
                        align="center" 
                        sx={{marginTop: 0, mb: 1, paddingLeft: 0, 
                            color: 'n5.main',
                            fontSize: '14px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 600,}}>
              Дата оплаты
            </Typography>  
          </Grid>
          <Grid item xs={4} sx={{borderWidth: 2, borderBottom: 4, borderColor: 'n3.main'}}>
            <Typography variant="body1" 
                        //gutterBottom 
                        align="center" 
                        sx={{marginTop: 0, mb: 1, paddingLeft: 0, 
                            color: 'n5.main',
                            fontSize: '14px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 600,}}>
              Сумма
            </Typography>  
          </Grid>
            {paymentHistory.map((history) => (
            <React.Fragment key={"fragmentCategory" + history.id}>
            <Grid item xs={4} sx={{borderBottom: 1, borderColor: 'n3.main'}}>
              <Typography variant="body1" 
                          //gutterBottom
                          align="justify" 
                          color="n5.main"
                          fontWeight="400"
                          sx={{marginTop: 0.2, mb: 0, paddingLeft: 0, 
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;',}}>
                  {history.nBill}
              </Typography>    
            </Grid>
            <Grid item xs={4}  sx={{borderBottom: 1, borderColor: 'n3.main'}}>
              <Typography variant="body1" 
                          alignSelf="center"
                          justifySelf="center"
                          align="center" 
                          color="n5.main"
                          fontWeight="400"
                          sx={{marginTop: 0.2, mb: 0, paddingLeft: 0, 
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;',}}>
                  {history.paidDate}
              </Typography>    
            </Grid>
            <Grid item xs={4} sx={{borderBottom: 1, borderColor: 'n3.main'}}>
              <Typography variant="body1" 
                          alignSelf="center"
                          justifySelf="center"
                          align="center" 
                          color="n5.main"
                          fontWeight="400"
                          sx={{marginTop: 0.2, mb: 0, paddingLeft: 0, 
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;',}}>
                  {history.amountAll} ₽
              </Typography>
            </Grid>
            </React.Fragment>
            ))}
        </Grid>
        
        
          

        <Button onClick={handleHistory}
                align="center"
                variant="contained"
                sx={{ mt: 2, 
                      mb: 0, 
                      width: "150px",
                      height: "25px",
                      bgcolor: 'n5.main',
                      fontSize: '12px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
                      color: 'white', 
                        '&:hover': {
                                    backgroundColor: 'n4_1.main'
                        }, 
                      borderRadius: '20px'}}>
            Получить
        </Button>
      </Box>       
      
      {/************************************************************************************************/}
      {/*************************               СНЕК - БАР                ******************************/}
      {/************************************************************************************************/} 
      <Snackbar open={openSnackBar} 
                autoHideDuration={3000} 
                onClose={handleCloseSnackBar}
                anchorOrigin={{ vertical: 'bottom',
                                horizontal: 'center', }}>
        <Alert onClose={handleCloseSnackBar} severity={snackBarStatus} variant='standard' sx={{ width: '100%' }}>
          {snackBarText}
        </Alert>
      </Snackbar>

      {/* -----------------------------Бэкдроп-------------------- */}
      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}>
          <CircularProgress color="inherit" />
      </Backdrop> 

      </Box>
      </ThemeProvider>
      </div>              
    )
  }  
}

export default observer(Profilepage);