import React, { useContext, useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import AppRegistrationRoundedIcon from '@mui/icons-material/AppRegistrationRounded';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Navigate } from "react-router-dom";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


import Input from '@mui/material/Input';

//import GlobalStyles from '@mui/material/GlobalStyles';


import { themeCol, styleTextField, stylePasswordField } from '../../css/themes';
import { observer } from 'mobx-react-lite'
import { ContextStore } from '../../index';

import "../../fonts/akrobat.css";
import "../../fonts/gilroy.css";


//-----------------------------------Задержка таймера на X сек-------------------
// function getTimer (sec) {
//   return new Promise((resolve, reject)=>{
//       console.log("Начало задержка в функции!");
//       setTimeout(() => {  console.log("Задержка в функции!"); return resolve("qq"); }, sec*1000); 
//       //return resolve("qq");
//   });
// };
//-----------------------------------Задержка таймера на X сек-------------------

const validate = require("validate.js");
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

let textDialog = "";

const RegistrationPage = () => {
   
  const {store} = useContext(ContextStore);
  const themeColor = createTheme(themeCol);

  //--Шаблон для пакета validate.js на проверку корректности E-mail
  var constraintsEmail = {
    from: {
      email: true
    }
  };

  const [userName, setUserName] = useState("");
  const [userNameError, setUserNameError] = useState(false);
  const [visibleUserNameError, setVisibleUserNameError] = useState(' ');

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [visibleEmailError, setVisibleEmailError] = useState(' ');
    
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [visiblePasswordError, setVisiblePasswordError] = useState(' ');

  const [sex, setSex] = useState(1);
    
  const [category, setCategory] = useState(1);
  // const [categoryError, setCategoryError] = useState(false);
  // const [visibleCategoryError, setVisibleCategoryError] = useState(' ');
    
  const [visibleError, setVisibleError] = useState(' ');
  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [sendingMail, setSendingMail] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);    
  };  

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }
    
  const handleSubmit = async (event) => {
        event.preventDefault();
        
        let err = false;
        
        if (!userName) {
          setUserNameError(true);
          setVisibleUserNameError('Пустое поле'); err = true;
        } else {
          if (userName.length > 50) {
            setUserNameError(true);
            setVisibleUserNameError('Длина имени должна быть меньше 50 символов'); err = true;
          } else {
            setUserNameError(false);
            setVisibleUserNameError(' ');
          }  
        }

        if (!email) {
          setEmailError(true);
          setVisibleEmailError('Пустое поле'); err = true;
        } else { 
          if (validate({from: email}, constraintsEmail)) {
            setEmailError(true);
            setVisibleEmailError('Некорректный E-mail'); err = true;
          } else {
            setEmailError(false);
            setVisibleEmailError(' ');
          }
        }

        if (!password) {
          setPasswordError(true);
          setVisiblePasswordError('Пустое поле'); err = true;
        } else { 
          if (password.length>32 || password.length<5) {
            setPasswordError(true);
            setVisiblePasswordError('Длина пароля должна быть от 5 до 32'); err = true;  
          } else {
            setPasswordError(false);
            setVisiblePasswordError(' ');
          }  
        }
        if (!checked) {
          err = true;
        }
        
        if (err) {
           return;
        }  
        

        //console.log('Прошли проверку')
        setLoading(true);
        //await getTimer(1);
        //console.log('Задержка кончилась')
        
        //Если ошибок нет - отправляем форму на сервер
        await store.registration(email, password, userName, sex, category);
        setLoading(false);

        if (store.status !== 200) {
              setVisibleError(store.errorStatusMessage);  
        } else {
              setVisibleError(' ');
              //textDialog = `${email}`
              //setOpenDialog(true);
              setUserName('');
              setEmail('');
              setPassword('');
              setSex(1);
              setSendingMail(true);
              setCategory(1);
          }
  };

/******************************************************************************************************************************/
/*                                               Основной блок                                                                */
/******************************************************************************************************************************/
return(
  <>
  {(sendingMail) ? <Navigate to="/sendingemail" /> : null}
  {document.title = "Регистрация / besthashtag.ru"}
  <ThemeProvider theme={themeColor}>
    <Container component="main" maxWidth="xs">
    <CssBaseline />
    {/* <GlobalStyles styles={{ body: { backgroundColor: themeColor.palette.n0.main } }} /> */}
      <Box
        sx={{
          marginTop: 15,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: 4,
          boxShadow: 1,
          borderWidth: 1,
          pt: 3,
          px: 2,
          pb: 4,
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'n4.main' }}>
          <AppRegistrationRoundedIcon />
        </Avatar>
        <Typography component="h5" variant="h5" sx={{ mt: 1, 
                                                      color: 'n4.main',
                                                      fontSize: '40px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 200,
                                                    }}>
          Регистрация
        </Typography>
        <Box component="form" //BOX с поля ввода и кнопкой
             onSubmit={handleSubmit} 
             noValidate sx={{ mt: 1.5 }}>
          
          {/* Поле для ввода ИМЯ ПОЛЬЗОВАТЕЛЯ */}
          <FormControl sx={{ mt: 1 }} variant="outlined" fullWidth>
            <TextField
              margin='none'
              required
              fullWidth
              id="username"
              label="Введите имя или псевдоним"
              name="username"
              //autoComplete='off'
              //autoComplete="username"
              autoFocus={true}
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              error={userNameError}
              sx = { styleTextField }
              inputProps={{  //Работает
                      autocomplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                      sx: {
                        // paddingTop: '10px',
                        // paddingBottom: '10px',
                        paddingLeft: '20px',
                        fontSize: '16px',
                        fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,
                      }, 
                  }}
              InputLabelProps = {{ 
                             sx: {   
                                  fontSize: '16px',
                                  fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,
                                 }
                                }}       
            />
            <FormHelperText id="help-username" sx={{color: '#ff0000'}}>
              {visibleUserNameError}
            </FormHelperText>  
          </FormControl> 
        
          {/* Поле для ввода EMAIL */}
          <FormControl sx={{ mt: 1 }} variant="outlined" fullWidth>
            <TextField
              margin='none'
              required
              fullWidth
              id="email"
              label="Введите E-mail"
              name="email"
              //autoComplete='off'
              //autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error = {emailError}
              sx = { styleTextField }
              inputProps={{  //Работает
                      autocomplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                      sx: {
                        // paddingTop: '10px',
                        // paddingBottom: '10px',
                        paddingLeft: '20px',
                        fontSize: '16px',
                        fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,
                      }, 
                  }}
              InputLabelProps = {{ 
                             sx: {   
                                  fontSize: '16px',
                                  fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,
                                 }
                                }}     
            />
            <FormHelperText id="help-email" sx={{color: '#ff0000'}}>
              {visibleEmailError}
            </FormHelperText>  
          </FormControl> 

          {/* Поле для ввода PASSWORD */}
          <FormControl sx={{ mt: 1 }} variant="outlined" fullWidth>
          <InputLabel required htmlFor="select-password-label"
                sx={{
                    fontSize: '16px',
                    fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,
                    }}>Введите пароль</InputLabel>
          <OutlinedInput 
            id="select-password-label"
            type={showPassword ? 'text' : 'password'}
            autoComplete='off'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label="Введите пароль"
            error={passwordError}
            sx ={stylePasswordField}      
            inputProps={{  //Работает
                autocomplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                sx: {
                  paddingLeft: '20px',
                  fontSize: '16px',
                  fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,
                },
            }}
            endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              //onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
            }/>
            <FormHelperText id="help-select-password-label" sx={{color: '#ff0000'}}>
                    {visiblePasswordError}
            </FormHelperText>  
          </FormControl>

          {/* Поле для ввода SEX */}  
          <FormControl id="FormControl-select-sex-label" sx={{ mt: 1 }} variant="outlined" fullWidth>
            <InputLabel id="InputLabel-select-sex-label"
                        sx={{
                            fontSize: '16px',
                            fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,
                            }}>Выберите пол 

            {/* </InputLabel> */}
            <Input
              id="input-with-icon-adornment" 
              //type="hidden" 
              disabled='true'  
              disableUnderline='true'            
              /></InputLabel>
            <Select
              
              labelId="Select-sex-label"
              id="Select-select-sex-label"
              value={sex}
              label="Выберите пол"
              defaultValue={1}
              sx ={stylePasswordField} 
              inputProps={{  //Работает
                id: 'inputProps-Select-sex-label',
                label: "Выберите пол1111",
                sx: {
                  paddingLeft: '20px',
                  fontSize: '16px',
                  fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 500,
                },
              }}
              onChange={(e) => setSex(e.target.value)}
              >
                <MenuItem id="id-sex1" value={1} sx={{fontSize: '16px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,}}>Мужской</MenuItem>
                <MenuItem id="id-sex2" value={2} sx={{fontSize: '16px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,}}>Женский</MenuItem>
            </Select>
            <FormHelperText id="help-input-select-sex-id" label="help-input-select-sex-label" sx={{color: '#ff0000'}}>
              {' '}
            </FormHelperText>  
          </FormControl>

          {/* Поле для ввода CATEGORY */}     
          {/* <FormControl sx={{ mt: 1 }} variant="outlined" fullWidth>
            <InputLabel id="select-category-label">Выберите категорию</InputLabel>
            <Select
              
              labelId="select-category-label"
              id="input-select-category-label"
              value={category}
              label="Выберите категорию"
              sx ={stylePasswordField} 
              inputProps={{  //Работает
                sx: {
                  paddingLeft: '20px',
                  fontSize: '14px',
                },
              }}
              onChange={(e) => setCategory(e.target.value)}
              error={categoryError}
            >
              <MenuItem value={1}>Фотограф</MenuItem>
              <MenuItem value={2}>Визажист</MenuItem>
            </Select>
            <FormHelperText id="help-input-select-category-label" sx={{color: '#ff0000'}}>
                {visibleCategoryError}
            </FormHelperText>   
          </FormControl>*/}

          <Typography component="h1" variant="caption" align='center' color="error">
              {visibleError}
          </Typography>     
          
          {/* <Typography component="h1" variant="caption" align='left' color='n5.main' sx={{ mt: 1, 
                                                      color: 'n4.main',
                                                      fontSize: '10px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 200,
                                                    }}> */}
            <FormGroup>
                <FormControlLabel id="soglasie-obrabotka"
                                  required 
                                  checked={checked}
                                  onChange={(e) => setChecked(e.target.checked)}
                                  control={<Checkbox id="soglasie-obrabotka-Checkbox" size="small"
                                                    //  checked={checked}
                                          />} 
                                  label={<Box component="div" fontSize={14} fontFamily={'Akrobat, Helvetica, sans-serif;'} color={checked ? 'n4.main' : 'error.main'}>
                                         Даю согласие на обработку персональных данных
                                         </Box>}
                                  />
            </FormGroup>
          {/* </Typography> */}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, 
                  mb: 3, 
                  bgcolor: 'n5.main',
                  color: 'n0.main', 
                    '&:hover': {
                                backgroundColor: 'n4_1.main'
                    }, 
                  borderRadius: '20px'}}
          >
            Зарегистрироваться
          </Button>
          
          {/* БЭКДРОП */} 
          <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
          </Backdrop>
          
          {/* Сетка для ссылки ВВОЙТИ */} 
          {/* <Grid container alignItems="center">
            <Grid item xs={6}>
            <Typography component="h1" variant="caption" align='left'>
              <Link href="#">
                  {"Забыли пароль?"}
              </Link>
            </Typography>  
            </Grid>
            <Grid item xs={6}>
            <Typography component="h1" variant="caption" align='right'>
              Нет аккаунта?<br />
              <Link href="#">
                {"Зарегистрироваться"}
              </Link>
              </Typography> 
            </Grid>
          </Grid> */}
        </Box>
        <Typography component="h1" variant="caption" align='center'>
              Уже зарегистрированы?<br />
              <Link href="/login">
                  {"Войти"}
              </Link>
          </Typography>
        <Typography mt={4} component="h1" variant="caption" align='center'>
              <Link href="/privacy" target="_blank">
                  {"Политика обработки персональных данных"}
              </Link>
        </Typography>  
      </Box>
    </Container>
    
    {/* Диалоговое окно */}
    <Dialog
            open={openDialog}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseDialog}
            aria-describedby="alert-dialog-slide-description">
      <DialogTitle sx={{color: "n5.main"}}>{"Регистрация прошла успешно"}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{color: "n4_1.main"}}>
          Вам на почту <strong>{textDialog}</strong> было выслано письмо. Откройте почту, перейдите по ссылке и активируйте аккаунт
        </DialogContentText> 
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>OK</Button>
      </DialogActions>
    </Dialog>
  </ThemeProvider>
  </>   
  )
}

export default observer(RegistrationPage);