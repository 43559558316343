import React from "react";
import { useEffect,useContext } from "react";
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeCol } from '../css/themes';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import TollOutlinedIcon from '@mui/icons-material/TollOutlined';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { ButtonRegistration } from "../component/ButtonRegistration"
import { ButtonProfile } from "../component/ButtonProfile"
import Link from '@mui/material/Link';

import { ContextStore } from "../index"
import { observer } from "mobx-react-lite"

import "../fonts/akrobat.css";
import "../fonts/gilroy.css";


const costText = [{color: 'n5.main',
                   fontSize: '30px', 
                   fontFamily: 'Gilroy, Helvetica, sans-serif;', 
                   fontWeight: 600,}]

const costTextLine = [{textDecoration: 'line-through', 
                       color: 'n5.main',
                       fontSize: '30px', 
                       fontFamily: 'Gilroy, Helvetica, sans-serif;', 
                       fontWeight: 600,}]

const textContentCard = [{color: 'n4.main',
                          fontSize: '14px', 
                          fontFamily: 'Akrobat, Helvetica, sans-serif;', 
                          fontWeight: 400,}]                 


const Pricingpage = () => {
    
    const {store} = useContext(ContextStore);
    const themeColor = createTheme(themeCol);

    useEffect(() => {
        document.title = "Тарифы / besthashtag.ru"
        window.scrollTo(0, 0);
      }, []);

    return(
        <div align="center">
        <Box //---BOX 
            sx={{ width: '95%',
                maxWidth: "1000px",
                marginTop: 12,
                paddingBottom: 5,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: 4, borderColor: 'n2.main', borderStyle: 'solid', boxShadow: 0, borderWidth: 1,
            }}>

            <ThemeProvider theme={themeColor}>

            <Avatar sx={{ m: 1, marginTop: 4, bgcolor: 'n4.main' }}>
                <TollOutlinedIcon />
            </Avatar>      
            
            <Typography variant="body1" 
                            gutterBottom 
                            align="center" 
                            sx={{marginTop: 0.5, mb: 4, paddingLeft: 0, 
                                color: 'n4.main',
                                fontSize: '40px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 200,}}>
            Тарифы
            </Typography> 
            
            {/* ////////////////////////////////////////////////////////////////////////////////////////////////////
            /////////////////////////////////    Первая категория        ///////////////////////////////////
            //////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <Typography variant="body1" 
                            align="center" 
                            sx={{marginTop: 0.5, mb: 4, paddingLeft: 0,
                                color: 'n4.main',
                                fontSize: '20px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 600,}}>
            Основная категория
            </Typography>
            
            <Container maxWidth="md" component="main">
            <Grid container spacing={5} alignItems="flex-end">
                <Grid item key="1 месяц" xs={12} sm={6} md={4}>
                <Card>
                    <CardHeader title="1 месяц" 
                                titleTypographyProps={{ align: 'center', fontSize: '20px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400 }} 
                                sx={{ backgroundColor: "n2.main" }} />
                    
                    <CardContent>
                        <Box sx={{height: "100px", justifyContent: 'center', justifyItems: 'middle', alignItems: 'center', alignContent: 'center',}}>
                            <Typography sx={costText}>
                            150&nbsp;₽
                            </Typography>
                        </Box>    
                    
                        <Box sx={{height: "80px", display: 'flex', justifyContent: 'center', justifyItems: 'middle', alignItems: 'center', 
                                  alignContent: 'center',}}>
                            <Typography sx={textContentCard}>
                            Цена за месяц за категорию
                            </Typography>
                        </Box> 
                    </CardContent>
                    
                </Card>
                </Grid>

                <Grid item key="3 месяца" xs={12} sm={6} md={4}>
                <Card>
                    <CardHeader title="3 месяца" 
                                titleTypographyProps={{ align: 'center', fontSize: '20px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400 }} 
                                sx={{ backgroundColor: "n2.main" }} />
                    
                    <CardContent>
                        <Box sx={{height: "100px", justifyContent: 'center', justifyItems: 'middle', alignItems: 'center', alignContent: 'center',}}>
                            <Typography sx={costText}>
                            450&nbsp;₽
                            </Typography>
                        </Box>    
                    
                        <Box sx={{height: "80px", display: 'flex', justifyContent: 'center', justifyItems: 'middle', alignItems: 'center', 
                                  alignContent: 'center',}}>
                            <Typography sx={textContentCard}>
                            Цена за месяц за категорию
                            </Typography>
                        </Box> 
                    </CardContent>
                    
                </Card>
                </Grid>

                <Grid item key="6 месяцев" xs={12} sm={6} md={4}>
                <Card>
                    <CardHeader title="6 месяцев"
                                titleTypographyProps={{ align: 'center', fontSize: '20px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400 }}
                                sx={{ backgroundColor: "n3.main" }} />
                    
                    <CardContent>
                        <Box sx={{height: "100px", justifyContent: 'center', justifyItems: 'middle', alignItems: 'center', alignContent: 'center',}}>
                            <Typography sx={costTextLine}>
                            900&nbsp;₽
                            </Typography>
                            <Typography sx={costText}>
                            800&nbsp;₽
                            </Typography>
                        </Box>    
                        
                        <Box sx={{height: "80px", display: 'flex', justifyContent: 'center', justifyItems: 'middle', alignItems: 'center', 
                                  alignContent: 'center',}}>
                            <Typography sx={textContentCard}>
                            При единовременной оплате за полгода скидка составит 100₽<br />
                            Цена за полгода за категорию
                            </Typography>
                        </Box>  
                    </CardContent>    
                </Card>
                </Grid>                    

                <Grid item key="12 месяцев" xs={12} sm={6} md={4}>
                <Card>
                    <CardHeader title="12 месяцев &#128293;"
                                titleTypographyProps={{ align: 'center', fontSize: '20px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400 }}
                                sx={{ backgroundColor: "#f85f73" }} />
                    
                    <CardContent>
                    <Box sx={{height: "100px", justifyContent: 'center', justifyItems: 'middle', alignItems: 'center', alignContent: 'center',}}>
                        <Typography sx={costTextLine}>
                        1800&nbsp;₽
                        </Typography>
                        <Typography sx={costText}>
                        1400&nbsp;₽
                        </Typography>
                                
                    </Box>    
                    <Box sx={{height: "80px", display: 'flex', justifyContent: 'center', justifyItems: 'middle', alignItems: 'center', 
                                  alignContent: 'center',}}>
                        <Typography sx={textContentCard}>
                            При единовременной оплате за год скидка составит 400₽<br />
                            Цена за год за категорию
                        </Typography>
                    </Box>
                    </CardContent>    
                </Card>
                </Grid>                     
            </Grid>
            </Container>

            {/* ////////////////////////////////////////////////////////////////////////////////////////////////////
            /////////////////////////////////    Следующая категория         ///////////////////////////////////
            /////////////////////////////////////////////////////////////////////////////////////////////////////// 
            <Typography variant="body1" 
                            align="center" 
                            sx={{marginTop: 15, mb: 2, paddingLeft: 0,
                                color: 'n4.main',
                                fontSize: '20px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 600,}}>
            Каждая следующая категория
            </Typography>

            <Typography variant="body1" 
                            align="left" 
                            sx={{marginTop: 0, mb: 4, paddingLeft: 4,
                                color: 'n4.main',
                                fontSize: '16px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 200,}}>
            &nbsp;&nbsp;К примеру, вы фотограф, но также занимаетесь видеосъемкой. Просто подключите дополнительную категорию, чтобы начать генерировать 
            хештеги для специальности видеооператора.
            </Typography>


            <Container maxWidth="md" component="main">
            <Grid container spacing={5} alignItems="flex-end">
                
                <Grid item key="1 месяц" xs={12} sm={6} md={4}>
                <Card>
                    <CardHeader title="1 месяц" 
                                titleTypographyProps={{ align: 'center', fontSize: '20px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400 }} 
                                sx={{ backgroundColor: "n2.main" }} />
                    
                    <CardContent>
                        <Box sx={{height: "100px", justifyContent: 'center', justifyItems: 'middle', alignItems: 'center', alignContent: 'center',}}>
                            <Typography sx={costText}>
                            75&nbsp;₽
                            </Typography>
                        </Box>    
                    
                        <Box sx={{height: "80px", display: 'flex', justifyContent: 'center', justifyItems: 'middle', alignItems: 'center', 
                                  alignContent: 'center',}}>
                            <Typography sx={textContentCard}>
                            Цена за один месяц за каждую следующую категорию
                            </Typography>
                        </Box> 
                    </CardContent>
                    
                </Card>
                </Grid>

                <Grid item key="3 месяца" xs={12} sm={6} md={4}>
                <Card>
                    <CardHeader title="3 месяца" 
                                titleTypographyProps={{ align: 'center', fontSize: '20px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400 }} 
                                sx={{ backgroundColor: "n2.main" }} />
                    
                    <CardContent>
                        <Box sx={{height: "100px", justifyContent: 'center', justifyItems: 'middle', alignItems: 'center', alignContent: 'center',}}>
                            <Typography sx={costText}>
                            225&nbsp;₽
                            </Typography>
                        </Box>    
                    
                        <Box sx={{height: "80px", display: 'flex', justifyContent: 'center', justifyItems: 'middle', alignItems: 'center', 
                                  alignContent: 'center',}}>
                            <Typography sx={textContentCard}>
                            Цена за один месяц за каждую следующую категорию
                            </Typography>
                        </Box> 
                    </CardContent>
                    
                </Card>
                </Grid>

                <Grid item key="6 месяцев" xs={12} sm={6} md={4}>
                <Card>
                    <CardHeader title="6 месяцев"
                                titleTypographyProps={{ align: 'center', fontSize: '20px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400 }}
                                sx={{ backgroundColor: "n3.main" }} />
                    
                    <CardContent>
                        <Box sx={{height: "100px", justifyContent: 'center', justifyItems: 'middle', alignItems: 'center', alignContent: 'center',}}>
                            <Typography sx={costTextLine}>
                            450&nbsp;₽
                            </Typography>
                            <Typography sx={costText}>
                            400&nbsp;₽
                            </Typography>
                        </Box>    
                        
                        <Box sx={{height: "80px", display: 'flex', justifyContent: 'center', justifyItems: 'middle', alignItems: 'center', 
                                  alignContent: 'center',}}>
                            <Typography sx={textContentCard}>
                            При единовременной оплате за год скидка составит 50₽<br />
                            Цена за полгода за каждую следующую категорию
                            </Typography>
                        </Box>  
                    </CardContent>    
                </Card>
                </Grid>                    

                <Grid item key="12 месяцев" xs={12} sm={6} md={4}>
                <Card>
                    <CardHeader title="12 месяцев &#128293;"
                                titleTypographyProps={{ align: 'center', fontSize: '20px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400 }}
                                sx={{ backgroundColor: "#f85f73" }} />
                    
                    <CardContent>
                    <Box sx={{height: "100px", justifyContent: 'center', justifyItems: 'middle', alignItems: 'center', alignContent: 'center',}}>
                        <Typography sx={costTextLine}>
                        900&nbsp;₽
                        </Typography>
                        <Typography sx={costText}>
                        700&nbsp;₽
                        </Typography>
                                
                    </Box>    
                    <Box sx={{height: "80px", display: 'flex', justifyContent: 'center', justifyItems: 'middle', alignItems: 'center', 
                                  alignContent: 'center',}}>
                        <Typography sx={textContentCard}>
                            При единовременной оплате за год скидка составит 200₽<br />
                            Цена за год за каждую следующую категорию
                        </Typography>
                    </Box>
                    </CardContent>    
                </Card>
                </Grid>                     
            </Grid>
            </Container>                    
            */}  


            {/* ////////////////////////////////////////////////////////////////////////////////////////////////////
            /////////////////////////////////    Разработка категории        ///////////////////////////////////
            //////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <Typography variant="body1" 
                            align="center" 
                            sx={{marginTop: 5, mb: 4, paddingLeft: 0,
                                color: 'n4.main',
                                fontSize: '20px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 600,}}>
            Разработка категории
            </Typography>
            <Container maxWidth="md" component="main">
            <Grid container spacing={5} alignItems="flex-end">
                <Grid item key="1 месяц" xs={12} sm={6} md={4}>
                <Card>
                    <CardHeader title="Разработка" 
                                titleTypographyProps={{ align: 'center', fontSize: '20px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, color: 'white' }} 
                                sx={{ backgroundColor: "n5.main" }} />
                    
                    <CardContent>
                        <Box sx={{height: "100px", justifyContent: 'center', justifyItems: 'middle', alignItems: 'center', alignContent: 'center',}}>
                            <Typography sx={costText}>
                            от 900&nbsp;₽
                            </Typography>
                        </Box>    
                    
                        <Box sx={{height: "80px", display: 'flex', justifyContent: 'center', justifyItems: 'middle', alignItems: 'center', 
                                  alignContent: 'center',}}>
                            <Typography sx={textContentCard}>
                            Разработка категории - при оплате минимум за полгода
                            </Typography>
                        </Box> 
                    </CardContent>
                    
                </Card>
                </Grid>
                  
            </Grid>
            </Container>


            {/* ////////////////////////////////////////////////////////////////////////////////////////////////////
            /////////////////////////////////    Подборка хештегов        ///////////////////////////////////
            //////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <Typography variant="body1" 
                            align="center" 
                            sx={{marginTop: 5, mb: 4, paddingLeft: 0,
                                color: 'n4.main',
                                fontSize: '20px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 600,}}>
            Подборка хештегов
            </Typography>
            <Container maxWidth="md" component="main">
            <Grid container spacing={5} alignItems="flex-end">
                <Grid item key="1 месяц" xs={12} sm={6} md={4}>
                <Card>
                    <CardHeader title="Подборка" 
                                titleTypographyProps={{ align: 'center', fontSize: '20px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, color: 'white' }} 
                                sx={{ backgroundColor: "n5.main" }} />
                    
                    <CardContent>
                        <Box sx={{height: "100px", justifyContent: 'center', justifyItems: 'middle', alignItems: 'center', alignContent: 'center',}}>
                            <Typography sx={costText}>
                            3000&nbsp;₽
                            </Typography>
                        </Box>    
                    
                        <Box sx={{height: "80px", display: 'flex', justifyContent: 'center', justifyItems: 'middle', alignItems: 'center', 
                                  alignContent: 'center',}}>
                            <Typography sx={textContentCard}>
                            Подборка хештегов по вашей тематике разбитых по частотности (включаются глобальные и вашей выбранной географии).
                            </Typography>
                        </Box> 
                    </CardContent>
                    
                </Card>
                </Grid>
                  
            </Grid>
            </Container>


            <Typography variant="body1" 
                            align="left" 
                            sx={{marginTop: 15, mb: 0, paddingLeft: 0,
                                color: 'n4.main',
                                fontSize: '16px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 200,}}>
            Оплата производится в личном кабинете
            </Typography> 
            
            
            {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////
            //////////////////////////                РЕГИСТРАЦИЯ ИЛИ ЛИЧННЫЙ КАБИНЕТ     ////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}                
            {(store.isAuth) ? <ButtonProfile /> : <ButtonRegistration />}

            <Typography mt={6} component="h1" variant="caption" align='center'>
                <Link href="/offer" target="_blank">
                    {"Договор публичной оферты"}
                </Link>                    
            </Typography>
                
            </ThemeProvider>

        </Box>
        </div>          
    )
}

export default observer(Pricingpage);