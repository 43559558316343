import React, { useContext } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import { themeCol } from '../css/themes';

import DataService from "../services/DataService";
import { ContextStore } from '../index';


/************************************************************************************************** */
//***************************      Диалог УДАЛЕНИЯ набора из БД      *******************************/
/************************************************************************************************** */

export function DialogDeleteUserTemplate(props) {

  const {store} = useContext(ContextStore);
  const themeColor = createTheme(themeCol);

  const { onClose, id, nameTemplate, setUserTemplate, setChooseTemplateUser, 
          clearFilter, setSnackBarText, setSnackBarStatus, setOpenSnackBar, open } = props;
  
  async function deleteTemplateUser(id) {
    
    //  Удаляем набор
    const userID = store.user.userID;
    const response = await DataService.deleteTemplate(id, userID);
    
    if (response.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
      store.setAuth(false);
      store.setStatus(response.status);
      store.setUser();
      store.setErrorStatusMessage(response.data);
      return;
    }
    
    //Считываем новый список наборов
    const responseList = await DataService.getTemplateUser(userID);
    if (response.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
      store.setAuth(false);
      store.setStatus(response.status);
      store.setUser();
      store.setErrorStatusMessage(response.data);
      return;
    }
    
    //Передаем родителю список всех наборов 
    clearFilter();
    setUserTemplate(responseList.data);
    setChooseTemplateUser([]);
    setSnackBarText("Успешно удалено"); setSnackBarStatus('success'); setOpenSnackBar(true);
  }    
  

  const handleDelete = () => {
    deleteTemplateUser(id);
    onClose();

  }

  const handleClose = () => {
    onClose();
  }
     
  return (
          <>
            <ThemeProvider theme={themeColor}> 
            <Dialog open={open} onClose={handleClose} fullWidth>
              <DialogTitle>
              <Typography variant="body1" 
                            gutterBottom 
                            align="left" 
                            sx={{marginTop: 0.5, mb: 0, paddingLeft: 0, 
                                color: 'n4.main',
                                fontSize: '20px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400,}}>
                Удаление набора
              </Typography>  
              
              </DialogTitle>
              {/* <Divider></Divider> */}
              <DialogContent>
                <DialogContentText>
                <Typography variant="body1" 
                            align="center" 
                            color="n5.main"
                            fontWeight="400"
                            sx={{marginTop: 0.2, mb: 0, paddingLeft: 0, 
                                 fontSize: '16px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;',}}>
                  Вы действительно хотите удалить набор <br /><strong>{nameTemplate}</strong>?
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDelete} sx={{color: 'errorUser.main'}}>Удалить</Button>
                <Button onClick={handleClose}>Отмена</Button>
              </DialogActions>
            </Dialog>
            </ThemeProvider>
          </>
  );
}
