import React from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { themeCol } from '../css/themes';
import { Link } from "react-router-dom";
import PetsOutlinedIcon from '@mui/icons-material/PetsOutlined';

import "../fonts/akrobat.css";
import "../fonts/gilroy.css";

/************************************************************************************************** */
//***************************      Кнопка регистрации                 *******************************/
/************************************************************************************************** */
export function ButtonLogin(props) {
  
    const themeColor = createTheme(themeCol);
  
    return (
          <>
            <ThemeProvider theme={themeColor}> 
            <Button size="small" 
                    variant="contained"
                    startIcon={<PetsOutlinedIcon />} 
                    component={Link} 
                    to={"/login"}
                    sx={{   mt: 2, 
                            mb: 2, 
                            width: "300px",
                            height: '40px',
                            bgcolor: 'n5.main',
                            fontSize: '16px', fontFamily: 'Akrobat, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
                            color: 'n0.main',
                            boxShadow: '2',          
                            '&:hover': {
                                backgroundColor: 'n4_1.main'
                                }, 
                            borderRadius: '10px'
                        }}
                    >
                Войти
            </Button>
            </ThemeProvider>
          </>
  );
}
