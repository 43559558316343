import React, { useEffect, useContext } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";

import HomePage from "./pages/HomePage";
import Gettinghashtag from "./pages/Gettinghashtag"
import { GuidePage } from "./pages/GuidePage"
import { Block3Page } from "./pages/Block3Page"
import { PrivacyPage } from "./pages/PrivacyPage"
import { OfferPage } from "./pages/OfferPage"
import PricingPage from "./pages/PricingPage"
import LoginPage from "./pages/auth/LoginPage"
import RegistrationPage from "./pages/auth/RegistrationPage"
import ProfilePage from "./pages/auth/ProfilePage"
import { Contactpage } from "./pages/Contactpage"
import Notfoundpage from "./pages/Notfoundpage"
import SendingEmailPage from "./pages/auth/SendingEmailPage"
import SendingEmailRecoveryPasswordPage from "./pages/auth/SendingEmailRecoveryPasswordPage"
import ErrorNotPay from "./pages/auth/ErrorNotPay"
import ErrorNotActivated from "./pages/auth/ErrorNotActivated"
import SuccessActivated from "./pages/auth/SuccessActivated"
import RecoveryPasswordPage from "./pages/auth/RecoveryPasswordPage"
import SuccessRecoveryPassword from "./pages/auth/SuccessRecoveryPassword"
import Layout from "./component/Layout"
//import CookieConsent from "react-cookie-consent";

import { ContextStore } from "./index"
import { observer } from "mobx-react-lite"


function App() {

  const {store} = useContext(ContextStore);

  useEffect(() => {
      
      if (localStorage.getItem('token')) {
        store.checkAuth();
        //console.log(localStorage.getItem('token'))
      }
      
      window.addEventListener("resize", store.handleResize);

  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
            <Route index path="/" element={<HomePage />} />
            <Route path="/gettinghashtag" element={<Gettinghashtag />} />
            <Route path="/guide" element={<GuidePage />} />
            <Route path="/block3" element={<Block3Page />} />
            <Route path="/privacy" element={<PrivacyPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/offer" element={<OfferPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/registration" element={<RegistrationPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/contact" element={<Contactpage />} />
            <Route path="/contact" element={<Contactpage />} />
            <Route path="/sendingemail" element={<SendingEmailPage />} />
            <Route path="/sendingemailrecoverypassword" element={<SendingEmailRecoveryPasswordPage />} />
            <Route path="/errornotpay" element={<ErrorNotPay />} />
            <Route path="/errornotactivated" element={<ErrorNotActivated />} />
            <Route path="/successactivated" element={<SuccessActivated />} />
            <Route path="/recoverypassword" element={<RecoveryPasswordPage />} />
            <Route path="/successrecoverypassword" element={<SuccessRecoveryPassword />} />
            <Route path="*" element={<Notfoundpage />} />
        </Route>
      </Routes>
      {/* <CookieConsent
      debug={true}
  location="top"
  
  buttonText="Я согласен"
  cookieName="CookieAgry"
  style={{ background: "#2B373B" }}
  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
  expires={365}
>
  Наш сайт использует куки. Продолжая им пользоваться, вы соглашаетесь на обработку персональных данных
</CookieConsent> */}
    </> 
  );
}

export default observer(App);
