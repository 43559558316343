import React from "react";
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeCol } from '../css/themes';
import Box from '@mui/material/Box';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import Avatar from '@mui/material/Avatar';

import "../fonts/akrobat.css";
import "../fonts/gilroy.css";

import point1 from "../images/guide/point1.png";
import point2 from "../images/guide/point2.png";
import point3 from "../images/guide/point3.png";
import point4 from "../images/guide/point4.png";
import point5 from "../images/guide/point5.png";
import point6 from "../images/guide/point6.png";
import point7 from "../images/guide/point7.png";
import point8 from "../images/guide/point8.png";
import createTemplate from "../images/guide/createTemplate.png";
import saveTemplate from "../images/guide/saveTemplate.png";
import editTemplate from "../images/guide/editTemplate.png";
import deleteTemplate from "../images/guide/deleteTemplate.png";
import checkTown from "../images/guide/checkTown.png";
import line from "../images/guide/line.png";


const GuidePage = () => {

    const themeColor = createTheme(themeCol);

    return(
        <div align="center">
        {document.title = "Инструкция / besthashtag.ru"}
        <Box //---BOX Частотностностью общих хештегов
                  sx={{
                        width: '95%',
                        maxWidth: "600px",
                        marginTop: 12,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: 4,
                        borderColor: 'n2.main',
                        borderStyle: 'solid',
                        boxShadow: 0,
                        borderWidth: 0,
                        pt: 0,
                        pl: 0,
                        pr: 0,
                        pb: 0,
                  }}
        
                  >
        
        <ThemeProvider theme={themeColor}>

            <Avatar sx={{ m: 1, marginTop: 4, bgcolor: 'n4.main' }}>
            <AutoStoriesOutlinedIcon />
            </Avatar>      
            <Typography variant="body1" 
                            gutterBottom 
                            align="center" 
                            sx={{marginTop: 0.5,
                                 mb: 4,  
                                paddingLeft: 0,
                                color: 'n4.main',
                                fontSize: '40px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 200,
                            }}>
            Руководство
            </Typography>  
            
            
            <Typography variant="body2" align="left" gutterBottom width="100%" maxWidth="750px"
                        sx={{fontSize: '16px', fontFamily: 'Akrobat, Helvetica, sans-serif;', 
                             fontWeight: 400, fontStyle: 'normal', paddingLeft: 2,}} 
                        color="titleMain.main">
                    <a href="#point1" align="left">
                    1. Пользовательские наборы параметров        
                    </a>
            </Typography>
            <Typography variant="body2" align="left" gutterBottom width="100%" maxWidth="750px"
                        sx={{fontSize: '16px', fontFamily: 'Akrobat, Helvetica, sans-serif;', 
                             fontWeight: 400, fontStyle: 'normal', paddingLeft: 2,}} 
                        color="titleMain.main">
                    <a href="#point2" align="left">
                    2. Общие шаблоны         
                    </a>
            </Typography>
            <Typography variant="body2" align="left" gutterBottom width="100%" maxWidth="750px"
                        sx={{fontSize: '16px', fontFamily: 'Akrobat, Helvetica, sans-serif;', 
                             fontWeight: 400, fontStyle: 'normal', paddingLeft: 2,}} 
                        color="titleMain.main">
                    <a href="#point3" align="left">
                    3. Выбор региона и городов         
                    </a>
            </Typography>
            <Typography variant="body2" align="left" gutterBottom width="100%" maxWidth="750px"
                        sx={{fontSize: '16px', fontFamily: 'Akrobat, Helvetica, sans-serif;', 
                             fontWeight: 400, fontStyle: 'normal', paddingLeft: 2,}} 
                        color="titleMain.main">
                    <a href="#point4" align="left">
                    4. Частотность (популярность) хештегов         
                    </a>
            </Typography>
            <Typography variant="body2" align="left" gutterBottom width="100%" maxWidth="750px"
                        sx={{fontSize: '16px', fontFamily: 'Akrobat, Helvetica, sans-serif;', 
                             fontWeight: 400, fontStyle: 'normal', paddingLeft: 2,}} 
                        color="titleMain.main">
                    <a href="#point5" align="left">
                    5. Язык         
                    </a>
            </Typography>
            <Typography variant="body2" align="left" gutterBottom width="100%" maxWidth="750px"
                        sx={{fontSize: '16px', fontFamily: 'Akrobat, Helvetica, sans-serif;', 
                             fontWeight: 400, fontStyle: 'normal', paddingLeft: 2,}} 
                        color="titleMain.main">
                    <a href="#point6" align="left">
                    6. Соцсети и количество хештегов         
                    </a>
            </Typography>
            <Typography variant="body2" align="left" gutterBottom width="100%" maxWidth="750px"
                        sx={{fontSize: '16px', fontFamily: 'Akrobat, Helvetica, sans-serif;', 
                             fontWeight: 400, fontStyle: 'normal', mb: 5, paddingLeft: 2,}} 
                        color="titleMain.main">
                    <a href="#point7" align="left">
                    7. Получение хештегов        
                    </a>
            </Typography>            



            {/*///////////////////////////////////////////////////////////////////////////////////////////////////
               /////////////////////////////////////  1. Пользовательские наборы параметров    ///////////////////
               //////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <img src={line} alt="line" width="90%" />
            <Typography     id="point1"
                            variant="body1" 
                            gutterBottom 
                            align="center" 
                            sx={{marginTop: 4, 
                                paddingLeft: 0,
                                color: 'titleMain.main',
                                fontSize: '18px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            1. Пользовательские наборы параметров
            </Typography>                
            <Typography variant="h6" align="center" sx={{flexGrow: 1, mt:0, ml:0, mb: 0, paddingLeft: 0.5 }} color="titleMain.main">
                   {/* <img src={point1} alt="point 1" width={250} height={34}/> */}
                   <img src={point1} alt="point-1" width="100%"  />
            </Typography>                
            <Typography variant="body2" 
                            gutterBottom 
                            align="left" 
                            width="100%"
                            maxWidth="600px"
                            sx={{marginTop: 2, 
                                paddingLeft: 2,
                                paddingRight: 2,
                                mb: 7,
                                mr: 0,
                                color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            &bull; В пользовательских наборах вы можете задавать и сохранять параметры генерации, чтобы потом просто выбирать свой набор и получать необходимые
            хештеги. Это очень удобно.<br /><br />
            &nbsp;&nbsp;1. Нажмите кнопку <dfn>&laquo;Создать новый набор&raquo;</dfn>&nbsp;<img src={createTemplate} alt="createTemplate" />, задайте ему наименование.<br /><br />
            &nbsp;&nbsp;2. Установите нужные вам параметры (выберите города, установите частотность, язык и т.п.). <br /><br />
            &nbsp;&nbsp;3. После установки всех параметров хештегов, нажмите кнопку <dfn>&laquo;Сохранить&raquo;&nbsp;</dfn><img src={saveTemplate} alt="saveTemplate" />, чтобы сохранить набор.<br /><br />
            <b>Важно!</b> При изменении параметров хештегов набор автоматически не сохраняется, пока вы не нажмете кнопку <dfn>&laquo;Сохранить&raquo;&nbsp;</dfn><img src={saveTemplate} alt="saveTemplate" />. 
            К примеру, вы можете выбрать свой набор, внести изменения в параметры и сформировать хештеги под вашу текущую задачу. 
            В итоге, ваш изначальный набор останется неизменным.<br /><br />
            &bull; Нажмите кнопку <dfn>&laquo;Редактировать наименование набора&raquo; </dfn><img src={editTemplate} alt="editTemplate" />, чтобы отредактировать наименование набора.<br /><br />            
            &bull; Нажмите кнопку <dfn>&laquo;Удалить набор&raquo; </dfn><img src={deleteTemplate} alt="deleteTemplate" />, чтобы удалить набор.
            </Typography>                

            
            {/*///////////////////////////////////////////////////////////////////////////////////////////////////
               /////////////////////////////////////  2. Общие шаблоны                    ///////////////////////////
               //////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <img src={line} alt="line" width="90%" />     
            <Typography     id="point2"
                            variant="body1" 
                            gutterBottom 
                            align="center" 
                            sx={{marginTop: 4, 
                                paddingLeft: 0,
                                color: 'titleMain.main',
                                fontSize: '18px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            2. Общие шаблоны
            </Typography>                 
            <Typography variant="h6" align="center" sx={{flexGrow: 1, mt:0, paddingLeft: 0.5 }} color="titleMain.main">
                   {/* <img src={point1} alt="point 1" width={250} height={34}/> */}
                   <img src={point2} alt="point-2" width="100%" />
            </Typography>
            <Typography variant="body2" 
                            gutterBottom 
                            align="left" 
                            width="100%"
                            maxWidth="600px"
                            sx={{marginTop: 0, 
                                paddingLeft: 2,
                                paddingRight: 2,
                                mb: 7,
                                color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            Выберите шаблон из представленного списка.
            </Typography>                                 


            {/*///////////////////////////////////////////////////////////////////////////////////////////////////
               /////////////////////////////////////  3. Выбор региона и городов          ///////////////////////////
               //////////////////////////////////////////////////////////////////////////////////////////////////*/} 
            <img src={line} alt="line" width="90%" />                   
            <Typography     id="point3"
                            variant="body1" 
                            gutterBottom 
                            align="center" 
                            sx={{marginTop: 4, 
                                paddingLeft: 0,
                                color: 'titleMain.main',
                                fontSize: '18px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            3. Выбор страны, региона и города (выбор топонимов)
            </Typography>                 
            <Typography variant="h6" align="center" sx={{flexGrow: 1, mt:0, paddingLeft: 0.5 }} color="titleMain.main">
                   {/* <img src={point1} alt="point 1" width={250} height={34}/> */}
                   <img src={point3} alt="point-3" width="100%" />
            </Typography>       
            <Typography variant="body2" 
                            gutterBottom 
                            align="left" 
                            width="100%"
                            maxWidth="600px"
                            sx={{marginTop: 0, 
                                paddingLeft: 2,
                                paddingRight: 2,
                                mb: 7,
                                color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            &bull; Выберите страну, регион, затем выберите город и нажмите соответствующую кнопку для добавления топонима <dfn><font color="#56c8c7">&laquo;Добавить&raquo;</font></dfn>. Рекомендуется выбирать не более 10 топонимов.<br /><br />
            &bull; Для удаления топонима из списка <dfn><font color="#1f5f8b">&laquo;Выбранные топонимы&raquo;</font></dfn>, выберите его <img src={checkTown} alt="checkTown" /> и нажмите кнопку 
            <dfn>&nbsp;&laquo;Удалить&raquo;</dfn>&nbsp;<img src={deleteTemplate} alt="deleteTemplate" />.<br />
            <dfn>Подсказка: </dfn>Для выбора топонима достаточно кликнуть (тапнуть) по его наименованию.<br /><br />
            <b>Важно!</b> Если не отмечен ни один из топонимов, то при нажатии на кнопку <dfn>&laquo;Удалить&raquo;&nbsp;<img src={deleteTemplate} alt="deleteTemplate" /></dfn> очищается весь список.
            </Typography>  



            {/*///////////////////////////////////////////////////////////////////////////////////////////////////
               /////////////////////////////////////  4. Частотность (популярность) хештегов   /////////////////////
               //////////////////////////////////////////////////////////////////////////////////////////////////*/}                 
            <img src={line} alt="line" width="90%" /> 
            <Typography     id="point4"
                            variant="body1" 
                            gutterBottom 
                            align="center" 
                            sx={{marginTop: 4, 
                                paddingLeft: 0,
                                color: 'titleMain.main',
                                fontSize: '18px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            4. Частотность (популярность) хештегов
            </Typography>                 
            <Typography variant="h6" align="center" sx={{flexGrow: 1, mt:0, paddingLeft: 0.5 }} color="titleMain.main">
                   {/* <img src={point1} alt="point 1" width={250} height={34}/> */}
                   <img src={point4} alt="point-4" width="100%" />
            </Typography> 
            <Typography variant="body2" 
                            gutterBottom 
                            align="left" 
                            width="100%"
                            maxWidth="600px"
                            sx={{marginTop: 1, 
                                paddingLeft: 2,
                                paddingRight: 2,
                                mb: 7,
                                color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            <dfn><font color="#1f5f8b">&laquo;Частотность географических хештегов&raquo;</font></dfn><br />
            &bull; Выберите частотность хештегов по географическому принципу. К ним относятся, к примеру, следующие хештеги <dfn><font color="#769fcd">#фотографкраснодар #свадьбамосква #ногтиспб</font></dfn>. 
            Если город или поселок не очень крупные, не ставьте высокие значения. А вообще, пробуйте разные значения, чтобы увеличить охваты. Словом - эксперементируйте!<br /><br />
            <dfn><font color="#1f5f8b">&laquo;Частотность глобальных хештегов&raquo;</font></dfn><br />
            &bull; Выберите частотность общих хештегов. К ним относятся, к примеру, следующие хештеги <dfn><font color="#769fcd">#фотограф #свадьба #ногти</font></dfn>.<br />
            <b>Важно!</b> Если ниша хештегов узкая - не ставьте высокое значение.<br />
            <b>Важно!</b> Алгоритм сам добавит популярные хештеги вашего города, специализированные хештеги общего назначения и т.п.
            </Typography>                               
            

            {/*///////////////////////////////////////////////////////////////////////////////////////////////////
               /////////////////////////////////////  5. Язык                             ///////////////////////////
               //////////////////////////////////////////////////////////////////////////////////////////////////*/} 
            <img src={line} alt="line" width="90%" /> 
            <Typography     id="point5"
                            variant="body1" 
                            gutterBottom 
                            align="center" 
                            sx={{marginTop: 4, 
                                paddingLeft: 0,
                                color: 'titleMain.main',
                                fontSize: '18px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            5. Язык
            </Typography>                 
            <Typography variant="h6" align="center" sx={{flexGrow: 1, mt:0, paddingLeft: 0.5 }} color="titleMain.main">
                   {/* <img src={point1} alt="point 1" width={250} height={34}/> */}
                   <img src={point5} alt="point-5" width="100%" />
            </Typography> 
            <Typography variant="body2" 
                            gutterBottom 
                            align="left" 
                            width="100%"
                            maxWidth="600px"
                            sx={{marginTop: 1, 
                                paddingLeft: 2,
                                paddingRight: 2,
                                mb: 7,
                                color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            Выберите язык хештегов. <br /><br />
            <b>Важно!</b> В хештеги на английском языке также попадают и хештеги написанные транслитом. 
            Пример: <dfn><font color="#769fcd">#fotokrasnodar #rostov #deti</font></dfn>
            </Typography>
            

            {/*///////////////////////////////////////////////////////////////////////////////////////////////////
               /////////////////////////////////////  6. Соцсети и количество хештегов    ///////////////////////////
               //////////////////////////////////////////////////////////////////////////////////////////////////*/} 
            <img src={line} alt="line" width="90%" /> 
            <Typography     id="point6"
                            variant="body1" 
                            gutterBottom 
                            align="center" 
                            sx={{marginTop: 4, 
                                paddingLeft: 0,
                                color: 'titleMain.main',
                                fontSize: '18px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            6. Соцсети и количество хештегов
            </Typography>                 
            <Typography variant="h6" align="center" sx={{flexGrow: 1, mt:0, paddingLeft: 0.5 }} color="titleMain.main">
                   {/* <img src={point1} alt="point 1" width={250} height={34}/> */}
                   <img src={point6} alt="point-6" width="100%" />
            </Typography>                 
            <Typography variant="body2" 
                            gutterBottom 
                            align="left" 
                            width="100%"
                            maxWidth="600px"
                            sx={{marginTop: 1, 
                                paddingLeft: 2,
                                paddingRight: 2,
                                mb: 7,
                                color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            Выберите социальную сеть, для которой вы формируете список хештегов.<br /><br />
            <b>Важно!</b> Количество хештегов ограничено в соответствии с требованием соцсети. Для Инстаграмма* это количество не более 30, 
            для Вконтакте - не более 10. Применяя большее количество хештегов вы можете попасть в теневой бан.
            </Typography>



            {/*///////////////////////////////////////////////////////////////////////////////////////////////////
               /////////////////////////////////////  7. Получение хештегов               ///////////////////////////
               //////////////////////////////////////////////////////////////////////////////////////////////////*/} 
            <img src={line} alt="line" width="90%" /> 
            <Typography     id="point7"
                            variant="body1" 
                            gutterBottom 
                            align="center" 
                            sx={{marginTop: 4, 
                                paddingLeft: 0,
                                color: 'titleMain.main',
                                fontSize: '18px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            7. Получение хештегов
            </Typography>                 
            <Typography variant="h6" align="center" sx={{flexGrow: 1, mt:0, paddingLeft: 0.5 }} color="titleMain.main">
                   {/* <img src={point1} alt="point 1" width={250} height={34}/> */}
                   <img src={point7} alt="point-7" width="100%" />
            </Typography>
            <Typography variant="body2" 
                            gutterBottom 
                            align="left" 
                            width="100%"
                            maxWidth="600px"
                            sx={{marginTop: 1, 
                                paddingLeft: 2,
                                paddingRight: 2,
                                mb: 7,
                                color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            &bull; Чтобы получить хештеги по тем параметрам, что вы указали выше, нажмите кнопку <dfn><font color="#1f5f8b">&laquo;Получить хештеги&raquo;</font></dfn>.<br /><br />
            &bull; Перемешайте хештеги нажав кнопку <dfn><font color="#1f5f8b">&laquo;Перемешать&raquo;</font></dfn>. Рекондуется перемешевать хештеги. Дело в том, 
            что соцсети не любят блоки повторяющегося текста из поста в пост.<br /><br />
            &bull; Скопируйте полученные хештеги в буффер обмена нажав кнопку <dfn><font color="#1f5f8b">&laquo;В буффер&raquo;&nbsp;</font></dfn> и вставьте в свой пост.
            </Typography>                


            {/*///////////////////////////////////////////////////////////////////////////////////////////////////
               /////////////////////////////////////  8. Замена и удаление хештегов               ///////////////////////////
               //////////////////////////////////////////////////////////////////////////////////////////////////*/} 
            <img src={line} alt="line" width="90%" /> 
            <Typography     id="point8"
                            variant="body1" 
                            gutterBottom 
                            align="center" 
                            sx={{marginTop: 4, 
                                paddingLeft: 0,
                                color: 'titleMain.main',
                                fontSize: '18px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            8. Замена и удаление хештегов
            </Typography>                 
            <Typography variant="h6" align="center" sx={{flexGrow: 1, mt:0, paddingLeft: 0.5 }} color="titleMain.main">
                   {/* <img src={point1} alt="point 1" width={250} height={34}/> */}
                   <img src={point8} alt="point-8" width="100%" />
            </Typography>
            <Typography variant="body2" 
                            gutterBottom 
                            align="left" 
                            width="100%"
                            maxWidth="600px"
                            sx={{marginTop: 1, 
                                paddingLeft: 2,
                                paddingRight: 2,
                                mb: 7,
                                color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            &bull; Выберите хештеги, кликнув на них. Чтобы отменить выделение, кликните на хештег повторно.<br /><br />
            &bull; Нажав кнопку <dfn><font color="#1f5f8b">&laquo;Заменить теги&raquo;</font></dfn> сервис попытается подобрать вам теги 
            исходя из ранее выбранных параметров.<br /><br />
            &bull; Нажмите на кнопку <dfn><font color="#1f5f8b">&laquo;Удалить теги&raquo;&nbsp;</font></dfn> чтобы удалить выбранные теги.
            </Typography>                



            {/*///////////////////////////////////////////////////////////////////////////////////////////////////
               /////////////////////////////////////  Мета экстремизм                 ///////////////////////////
               //////////////////////////////////////////////////////////////////////////////////////////////////*/} 
            <Typography variant="body2" 
                            gutterBottom 
                            align="left" 
                            width="100%"
                            maxWidth="600px"
                            sx={{marginTop: 1, 
                                paddingLeft: 2,
                                paddingRight: 2,
                                mb: 7,
                                color: 'n4.main',
                                fontSize: '12px', fontFamily: 'Clear Sans, Arial, sans-serif;', fontWeight: 100, fontStyle: 'normal',
                            }}>
            *Соцсети Instagram и Facebook запрещены в РФ; они принадлежат корпорации Meta, которая признана в РФ экстремистской.                  
            </Typography>
        </ThemeProvider> 
        
        </Box>
        </div>
    )
}

export {GuidePage};