import $api from "../http";


export default class DataService {
    
    static async getTemplateCommon(id, category) {
        return new Promise((resolve, reject) => { 
                //$api.get('/getSetCommon', {id: id}).then (
                $api.post('/getSetCommon', {id: id, category: category}).then (    
                    (response) => {
                                    return resolve({"status": 200, "data": response.data});
                                  },
                    (reject) =>   {
                                    return resolve({"status": reject.response.status, "data": reject.response.data.message});
                                  }
                )
        })
    }

    static async getTemplateUser(id) {
        return new Promise((resolve, reject) => { 
                $api.post('/getUserTemplate', {id: id}).then (
                    (response) => {
                                    return resolve({"status": 200, "data": response.data});
                                  },
                    (reject) =>   {
                                    return resolve({"status": reject.response.status, "data": reject.response.data.message});
                                  }
                )
        })
    }

    static async getCountry() {
        return new Promise((resolve, reject) => { 
            $api.get('/getCountry').then(
                (response) => {
                                return resolve({"status": 200, "data": response.data});
                              },
                (reject)   => {  
                                return resolve({"status": reject.response.status, "data": reject.response.data.message});
                              }              
            )
        })
    }   

    static async getRegions(id) {
        return new Promise((resolve, reject) => { 
            $api.post('/getRegions', {id: id}).then(
                (response) => {
                                return resolve({"status": 200, "data": response.data});
                              },
                (reject)   => {  
                                return resolve({"status": reject.response.status, "data": reject.response.data.message});
                              }              
            )
        })
    }   

    static async getTowns(id) {
        return new Promise((resolve, reject) => { 
            $api.post('/getTowns', {id: id}).then(
                (response) => {
                                return resolve({"status": 200, "data": response.data});
                              },
                (reject)   => {  
                                return resolve({"status": reject.response.status, "data": reject.response.data.message});
                              }              
            )
        })
    }


    static async getHashtag(params) {
        return new Promise((resolve, reject) => { 
            // console.log("getHashtag");
            // console.log(params);
            $api.post('/getHashtag', params).then(
                (response) => {
                                //console.log(response);
                                return resolve({"status": response.data.status, "data": response.data});
                              },
                (reject)   => {  
                                return resolve({"status": reject.response.status, "data": reject.response.data.message});
                              }              
            )
        })
    }

    static async replaceHashtag(userID, chipSTR, idSession) {
        return new Promise((resolve, reject) => { 
            
            //console.log (userID);
            //console.log (chipSTR);
            //console.log (idSession);
            $api.post('/replaceHashtag', {id_user: userID, chipSTR: chipSTR, idSession: idSession}).then(
                (response) => {
                                //console.log(response);
                                return resolve({"status": response.data.status, "data": response.data});
                              },
                (reject)   => {  
                                return resolve({"status": reject.response.status, "data": reject.response.data.message});
                              }              
            )
        })
    }
    

    static async addTemplate(userID, nameTemplate) {
        return new Promise((resolve, reject) => { 
            $api.post('/addUserTemplate', {id: userID, nameTemplate: nameTemplate}).then(
                (response) => {
                                return resolve({"status": 200, "data": response.data});
                              },
                (reject)   => {  
                                return resolve({"status": reject.response.status, "data": reject.response.data.message});
                              }              
            )
        })
    }


    static async deleteTemplate(id, userID) {
        return new Promise((resolve, reject) => { 
            $api.post('/deleteUserTemplate', {id: id, id_user: userID}).then(
                (response) => {
                                return resolve({"status": 200, "data": response.data});
                              },
                (reject)   => {  
                                return resolve({"status": reject.response.status, "data": reject.response.data.message});
                              }              
            )
        })
    }


    static async saveTemplate(id, userID, jsonTemplate) {
        return new Promise((resolve, reject) => { 
            $api.post('/saveUserTemplate', {id: id, id_user: userID, jsonTemplate: jsonTemplate}).then(
                (response) => {
                                return resolve({"status": 200, "data": response.data});
                              },
                (reject)   => {  
                                return resolve({"status": reject.response.status, "data": reject.response.data.message});
                              }              
            )
        })
    }

    static async loadUserTemplate(id, id_user) {
        return new Promise((resolve, reject) => { 
            $api.post('/loadUserTemplate', {id: id, id_user: id_user}).then(
                (response) => {
                                return resolve({"status": 200, "data": response.data});
                              },
                (reject)   => {  
                                return resolve({"status": reject.response.status, "data": reject.response.data.message});
                              }              
            )
        })
    }

    static async editTemplate(id, id_user, nameTemplate) {
        return new Promise((resolve, reject) => { 
            $api.post('/editUserTemplate', {id: id, id_user: id_user, nameTemplate: nameTemplate}).then(
                (response) => {
                                return resolve({"status": 200, "data": response.data});
                              },
                (reject)   => {  
                                return resolve({"status": reject.response.status, "data": reject.response.data.message});
                              }              
            )
        })
    }

    static async getTariff() {
        return new Promise((resolve, reject) => { 
            $api.get('/getTariff').then(
                (response) => {
                                return resolve({"status": 200, "data": response.data});
                              },
                (reject)   => {  
                                return resolve({"status": reject.response.status, "data": reject.response.data.message});
                              }              
            )
        })
    }

    static async getProfession() {
        return new Promise((resolve, reject) => { 
            $api.get('/getProfession').then(
                (response) => {
                                return resolve({"status": 200, "data": response.data});
                              },
                (reject)   => {  
                                return resolve({"status": reject.response.status, "data": reject.response.data.message});
                              }              
            )
        })
    }

    static async addCategory(userID, idCategory, mainCategory) {
        return new Promise((resolve, reject) => { 
            $api.post('/addCategory', {id: userID, idCategory: idCategory, mainCategory: mainCategory}).then(
                (response) => {
                                return resolve({"status": 200, "data": response.data});
                              },
                (reject)   => {  
                                return resolve({"status": reject.response.status, "data": reject.response.data.message});
                              }              
            )
        })
    }

    static async getCategories(userID) {
        return new Promise((resolve, reject) => { 
            $api.post('/getCategories', {id: userID}).then(
                (response) => {
                                return resolve({"status": 200, "data": response.data});
                              },
                (reject)   => {  
                                return resolve({"status": reject.response.status, "data": reject.response.data.message});
                              }              
            )
        })
    }

    static async saveUserData(userID, userName, sex) {
        return new Promise((resolve, reject) => { 
            //console.log(userID);
            $api.post('/saveUserData', {id: userID, username: userName, sex: sex}).then(
                (response) => {
                                return resolve({"status": 200, "data": response.data});
                              },
                (reject)   => {  
                                return resolve({"status": reject.response.status, "data": reject.response.data.message});
                              }              
            )
        })
    }

    static async createPayment(userID, email, billAmountAll, idempotenceKey, nBill, bills) {
        return new Promise((resolve, reject) => { 
            //console.log("tuta createPayment");
            //console.log(idempotenceKey);
            //console.log(bills);
            $api.post('/createPayment', {userid: userID, 
                                         email: email, 
                                         billamountall: billAmountAll, 
                                         idempotenceKey: idempotenceKey,
                                         nbill: nBill,
                                         bills: JSON.stringify(bills)}).then(
                (response) => {
                                return resolve({"status": 200, "data": response.data});
                              },
                (reject)   => {  
                                return resolve({"status": reject.response.status, "data": reject.response.data.message});
                              }              
            )
        })
    }
    
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////           ИСТОРИЯ ПЛАТЕЖЕЙ        /////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    static async getPaymentHistory(userID) {
        return new Promise((resolve, reject) => { 
            //console.log("tuta createPayment");
            $api.post('/getPaymentHistory', {userid: userID}).then(
                (response) => {
                                return resolve({"status": 200, "data": response.data});
                              },
                (reject)   => {  
                                return resolve({"status": reject.response.status, "data": reject.response.data.message});
                              }              
            )
        })
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////           СМЕНА ПАРОЛЯ            /////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
    static async changePassword(userID, passwordOld, passwordNew) {
        return new Promise((resolve, reject) => { 
            //console.log("tuta createPayment");
            $api.post('/changePassword', {userid: userID, passwordold: passwordOld, passwordnew: passwordNew}).then(
                (response) => {
                                return resolve({"status": 200, "data": response.data});
                              },
                (reject)   => {  
                                return resolve({"status": reject.response.status, "data": reject.response.data.message});
                              }              
            )
        })
    }


} 
