import React, { useState, useContext } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import { themeCol, styleTextField } from '../css/themes';

import DataService from "../services/DataService";
import { ContextStore } from '../index';

/************************************************************************************************** */
//***************************      Диалог ДОБАВЛЕНИЯ набора в БД      *******************************/
/************************************************************************************************** */
export function DialogAddUserTemplate(props) {

  const {store} = useContext(ContextStore);
  const themeColor = createTheme(themeCol);

  const { onClose, setUserTemplate, setChooseTemplateUser, clearFilter, 
          setSnackBarText, setSnackBarStatus, setOpenSnackBar, open } = props;
  const [addTemplateUserText, setAddTemplateUserText] = useState("");
  
  async function addTemplateUser(userID, nameTemplate) {
    
    //Добавляем шаблон
    if (!nameTemplate) {
      setSnackBarText("Пустое имя набора"); setSnackBarStatus('error'); setOpenSnackBar(true);
      return;
    }
    const response = await DataService.addTemplate(userID, nameTemplate);
    
    if (response.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
      store.setAuth(false);
      store.setStatus(response.status);
      store.setUser();
      store.setErrorStatusMessage(response.data);
      return;
    }
    const idTemplate = response.data.id;

    //Считываем новый список шаблонов
    const responseList = await DataService.getTemplateUser(userID);
    if (response.status === 401) {  //Если пользователь неавторизован перенаправляем его на страницу Log In
      store.setAuth(false);
      store.setStatus(response.status);
      store.setUser();
      store.setErrorStatusMessage(response.data);
      return;
    }
    
    //Передаем родителю выбранный шаблон и список всех шаблонов 
    clearFilter();
    setUserTemplate(responseList.data);
    setChooseTemplateUser({id: idTemplate, nameTemplate: nameTemplate})
    setAddTemplateUserText('');
    onClose();
    setSnackBarText("Добавлен новый набор"); setSnackBarStatus('success'); setOpenSnackBar(true);
  }    
  
  const handleAdd = () => {
    addTemplateUser(store.user.userID, addTemplateUserText);
  }

  const handleClose = () => {
    setAddTemplateUserText('');
    onClose();
  }
  
  
  return (
          <>
            <ThemeProvider theme={themeColor}> 
            <Dialog open={open} onClose={handleClose} fullWidth>
              <DialogTitle sx={{color: 'n5.main'}}>
              <Typography variant="body1" 
                            gutterBottom 
                            align="left" 
                            sx={{marginTop: 0.5, mb: 0, paddingLeft: 0, 
                                color: 'n4.main',
                                fontSize: '20px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400,}}>
                Добавление набора
              </Typography>  
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                <Typography variant="body1" 
                            align="center" 
                            color="n5.main"
                            fontWeight="400"
                            sx={{marginTop: 0.2, mb: 0, paddingLeft: 0, 
                                 fontSize: '16px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;',}}>
                  Введите имя набора
                </Typography>  
                </DialogContentText>
                <TextField
                  autoFocus
                  size='small'
                  margin="dense"
                  id="nameTemplate"
                  label="Набор"
                  fullWidth
                  variant="outlined"
                  value={addTemplateUserText}
                  onChange={(e) => setAddTemplateUserText(e.target.value)}
                  sx = {styleTextField}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleAdd} sx={{color: 'loginUser.main'}}>Добавить</Button>
                <Button onClick={handleClose}>Отмена</Button>
              </DialogActions>
            </Dialog>
            </ThemeProvider>
          </>
  );
}
