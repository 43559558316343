import { alpha } from '@mui/material/styles';

//НАдпись
//dfe2e9 best hashtag

export const themeCol = {
    palette: {
                primary: {
                    main: "#769fcd",
                },
                secondary: {
                    main: "#2d8f3d",
                },
                n0: {
                    main: '#ffffff'
                },
                n1: {
                    main: '#f9f7f7',
                },
                n1_v2: {
                    main: '#1c3464',
                },
                n2: {
                    main: '#d6e6f2',
                },
                n3: {
                    main: '#b9d7ea',
                },
                n4: {
                    main: '#769fcd',
                },
                n4_1: {
                    main: '#4f83a6',
                },
                n5: {
                    main: '#1f5f8b',
                },
                loginUser: {
                    //main: '#7ac7c4',
                    main: '#21b841',
                    
                },
                errorUser: {
                    main: '#ee4027',
                    //f85f73
                },
                errorUserLight: {
                    main: '#ffa295',
                    //f85f73
                },
                errorUserDark: {
                    main: '#ac5347',
                    //f85f73
                },
                guestUser: {
                    main: '#f8dd5f',
                },
                guestUserLight: {
                    main: '#fdeea7',
                },
                guestUserDark: {
                    main: '#bb9e15',
                },
                titleMain: {
                    main: '#61c0bf',
                },
                darkLoginUser: {
                    //main: '#1c9594',
                    main: '#27ee93',
                },
                lightLoginUser: {
                    main: '#56c8c7',
                },
                superLightLoginUser: {
                    main: '#d9f5f5',
                },
                black: {
                    main: '#000000'
                },
    },
    overrides: {
        MuiButton: {
          raisedPrimary: {
            color: 'white',
          },
        },
      }
  }
  
export const styleTextField = {
      
    bgcolor: 'n1.main',
    borderRadius : '10px',
    

    '& label.MuiInputLabel-root' : {
        fontSize: '16px',
        fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', 
        fontWeight: 400,
        //fontColor: themeCol.palette.n1.main,
    },

    '& label.Mui-focused': {
        //fontSize: '5px',
        
        // marginTop: 0,
        //color: "red",
        color: themeCol.palette.n4.main,
    }, 

    '& .MuiOutlinedInput-root': {
        fontSize: '14px',
        fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,   
      '& fieldset': {
                    borderRadius: '10px',
      },
      '&:hover fieldset': {
                    borderColor: themeCol.palette.n4.main,
      },             
      '&.Mui-focused fieldset': {                        
                    borderColor: themeCol.palette.n4.main,
                    boxShadow: `${alpha(themeCol.palette.n2.main, 0.5)} 0 0 0 0.25rem`,
      },
    }
}

export const  stylePasswordField = {
    bgcolor: 'n1.main',
    borderRadius : '10px',
    
    '&:hover': { //работает, но на не на рамку. Только на поле ввода
    '& fieldset.MuiOutlinedInput-notchedOutline': { //работает, на рамку.
            borderColor: themeCol.palette.n4.main, 
    }, 
    },
    '&.Mui-focused': {
    '& fieldset.MuiOutlinedInput-notchedOutline': { //работает, на рамку.
            borderColor: themeCol.palette.n4.main,
            boxShadow: `${alpha(themeCol.palette.n2.main, 0.5)} 0 0 0 0.25rem`, 
    }, 
    }  
}

















// export const styleTextFieldMargin = {
      
//     bgcolor: 'n1.main',
//     borderRadius : '10px',
    
//     // '& label.MuiOutlinedInput-input': {
//     //     fontSize: '20px',
//     //     color: "red",
//     // }, 

//     '& label.Mui-focused': {
//         //fontSize: '20px',
//         // marginTop: 0,
//         color: themeCol.palette.n4.main,
//     }, 

//     // '& label.Mui-required': {
//     //     //fontSize: '20px',
//     //     marginTop: -1
//     // }, 


//     '& .MuiOutlinedInput-root': {
//       '& fieldset': {
//                     borderRadius: '10px',
//       },
//       '&:hover fieldset': {
//                     borderColor: themeCol.palette.n4.main,
//       },             
//       '&.Mui-focused fieldset': {                        
//                     borderColor: themeCol.palette.n4.main,
//                     boxShadow: `${alpha(themeCol.palette.n2.main, 0.5)} 0 0 0 0.25rem`,
//       },
//     }
// }
