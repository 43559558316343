import axios from "axios";

//------------------------------------------- DEPLOY -------------------------------------------
export const API_URL = `https://besthashtag.ru/api`
//------------------------------------------- DEPLOY -------------------------------------------
//------------------------------------------- DEVELOP -------------------------------------------
//export const API_URL = `http://localhost:3001/api`



const $api = axios.create({
    withCredentials: true,
    baseURL: API_URL
})

$api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    return config;
})

$api.interceptors.response.use((config) => {
    return config;
},                       async (error) =>  {
    const originalRequest = error.config;          //Сохраняем оригинальный запрос пользователя
    if(error.response.status === 401 && error.config && !originalRequest._isRetry) {
        originalRequest._isRetry = true;
        try {
            const response = await axios.get(`${API_URL}/refresh`, {withCredentials: true})
            //console.log('response interceptor');
            //console.log(response);
            localStorage.setItem('token', response.data.accessToken);
            return $api.request(originalRequest);
        } catch(e) {
            //console.log('НЕ АВТОРИЗОВАН');
            //console.log(e);
        }    
    }
    throw error;
})

export default $api;