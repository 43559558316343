import React, { useContext, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PetsOutlinedIcon from '@mui/icons-material/PetsOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import "../../fonts/akrobat.css";
import "../../fonts/gilroy.css";


import { themeCol, styleTextField, stylePasswordField } from '../../css/themes';
import { observer } from 'mobx-react-lite'
import { ContextStore } from '../../index';


const LoginPage = () => {

  const themeColor = createTheme(themeCol);
  const {store} = useContext(ContextStore);

  //-----Состояния
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visibleError, setVisibleError] = useState(' ');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => {         //-----Хендлер на глазик поля ПАРОЛЯ
    setShowPassword(!showPassword);
  };  
     
  // const handleMouseDownPassword = (event) => {    //-----Хендлер на клик глазик поля ПАРОЛЯ
  //   event.preventDefault();
  // };

  const handleSubmit = async (event) => {         //-----Хендлер на кнопку ВОЙТИ
    event.preventDefault();
    
    setLoading(true);
    await store.login(email, password);
    setLoading(false);

    //console.log(store.status);
    //console.log(store.errorStatusMessage);
    if (store.status !== 200) {
      setVisibleError(store.errorStatusMessage);  
    } else {
      setVisibleError(' ');
    }
  }

/******************************************************************************************************************************/
/*                                               Основной блок                                                                */
/******************************************************************************************************************************/  

  return(
    <div>
    {/* Если пользователь залогинен - перекидываем на форму получения хештегов*/}
    {(store.isAuth) ? <Navigate to="/gettinghashtag" /> : null} 
    {document.title = "Войти / besthashtag.ru"}  
    <ThemeProvider theme={themeColor}>
      <Container component="main" maxWidth="xs">
      <CssBaseline />
        <Box //---Основной BOX который содержит все компоненты
          sx={{
            marginTop: 25,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: 4,
            boxShadow: 1,
            borderWidth: 1,
            pt: 3,
            px: 2,
            pb: 4,
          }}
        >  
          <Avatar sx={{ m: 1, bgcolor: 'n4.main' }}>
            <PetsOutlinedIcon />
          </Avatar>
          
          <Typography component="h5" variant="h5" sx={{ mt: 2,
                                                        color: 'n4.main',
                                                        fontSize: '40px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 200,
                                                      }}>
            Войти
          </Typography>

          <Box component="form" //BOX с поля ввода и кнопкой  
              onSubmit={handleSubmit} 
              noValidate sx={{ mt: 1.5, }}>

            {/* Поле для ввода EMAIL */}
            <FormControl sx={{ mt: 2}} variant="outlined" fullWidth >
              <TextField 
                margin="none"
                required
                fullWidth
                id="email"
                label="Введите E-mail"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx = { styleTextField }
                inputProps={{  //Работает
                    sx: {
                      paddingLeft: '20px',
                      fontSize: '14px',
                    }, 
                }}    
              />
              <FormHelperText id="email-filled-weight-helper-text" sx={{color: '#ff0000'}}>
                    {' '}
              </FormHelperText>   
            </FormControl> 
            
            {/* Поле для ввода PASSWORD} */}
            <FormControl sx={{ mt: 1}} variant="outlined" fullWidth>
              <InputLabel required htmlFor="select-password-label"
                sx ={{//color: "n5.main", fontSize: '14px', bgcolor: 'n1.main'
                      '&:hover ': { //рАБОТАЕТ НА ЛЕБЛ (КОТОРЫЙ МАЛЕНЬКИЙ)
                        //borderColor  : 'red',
                        //bgcolor: 'n5.main',
                        //fontSize: '30px',
                      },   
                      '&.Mui-focused': { //рАБОТАЕТ НА ЛЕБЛ (КОТОРЫЙ МАЛЕНЬКИЙ)
                        //fontSize: '17px',
                      }            
                }}
              >Введите пароль
              </InputLabel>
              <OutlinedInput
                margin="none"
                required
                id="select-password-label"
                type={showPassword ? 'text' : 'password'}
                value={password}
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
                label="Введите пароль"
                sx ={stylePasswordField}      
                inputProps={{  //Работает
                    sx: {
                      paddingLeft: '20px',
                      fontSize: '14px',
                    },
                }}
                endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  //onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                }/>
              <FormHelperText id="filled-weight-helper-text" sx={{color: '#ff0000'}}>
                  {visibleError}
              </FormHelperText>        
            </FormControl>
            
            {/* Кнопка отправки данных */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ 
                    mt: 3, mb: 2, 
                    bgcolor: 'n5.main',
                    color: 'n0.main', 
                    '&:hover': {
                                backgroundColor: 'n4_1.main'
                    },
                    borderRadius: '20px'
                  }}>
              Войти
            </Button>

            {/* -----------------------------Бэкдроп-------------------- */}
            <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            
            {/* -----------------------------Сетка с забыли пароль и зарегестрироваться----------- */}
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <Typography component="h1" variant="caption" align='left'>
                  <Link href="/recoverypassword"  sx={{color: 'n4.main'}}>
                      {"Забыли пароль?"}
                  </Link>
                </Typography>  
              </Grid>
              <Grid item xs={6}>
                <Typography component="h1" variant="caption" align="right">
                  <Link href="/registration" sx={{color: 'n4.main'}}>
                    {"Зарегистрироваться"}
                  </Link>
                </Typography> 
              </Grid>
            </Grid>
            
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
    </div>   
  )
}

export default observer(LoginPage);