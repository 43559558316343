import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import App from './App';
import Store from "./store/store";
import StoreData from "./store/store";

const store = new Store();
const storeData = new StoreData();

export const ContextStore = createContext({store, storeData, });


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  
  <ContextStore.Provider value={{store, storeData}}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ContextStore.Provider>  
);
