import React from "react";
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeCol } from '../css/themes';
import Box from '@mui/material/Box';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Avatar from '@mui/material/Avatar';

import "../fonts/akrobat.css";
import "../fonts/gilroy.css";

import point1 from "../images/3block/3block-point1.jpg";
import point2 from "../images/3block/3block-point2.jpg";
import point3 from "../images/3block/3block-point3.jpg";
import point4 from "../images/3block/3block-point4.jpg";
import point5 from "../images/3block/3block-point5.jpg";
import point6 from "../images/3block/3block-point6.jpg";

import line from "../images/guide/line.png";


const Block3Page = () => {

    const themeColor = createTheme(themeCol);

    return(
        <div align="center">
        {document.title = "Как выложить больше 30 хештегов  / besthashtag.ru"}
        <Box //---BOX Частотностностью общих хештегов
                  sx={{
                        width: '95%',
                        maxWidth: "600px",
                        marginTop: 12,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: 4,
                        borderColor: 'n2.main',
                        borderStyle: 'solid',
                        boxShadow: 0,
                        borderWidth: 0,
                        pt: 0,
                        pl: 0,
                        pr: 0,
                        pb: 0,
                  }}
        
                  >
        
        <ThemeProvider theme={themeColor}>

            <Avatar sx={{ m: 1, marginTop: 4, bgcolor: 'n4.main' }}>
            <HelpOutlineOutlinedIcon />
            </Avatar>      
            <Typography variant="body1" 
                            gutterBottom 
                            align="center" 
                            sx={{marginTop: 0.5,
                                 mb: 4,  
                                paddingLeft: 0,
                                color: 'n4.main',
                                fontSize: '40px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 200,
                            }}>
            Как выложить больше 30 хештегов
            </Typography>  
            
            
            {/*///////////////////////////////////////////////////////////////////////////////////////////////////
               /////////////////////////////////////  1. Пользовательские наборы параметров    ///////////////////
               //////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <img src={line} alt="line" width="90%" />
            <Typography     id="point1"
                            variant="body1" 
                            gutterBottom 
                            align="center" 
                            sx={{marginTop: 4, 
                                paddingLeft: 0,
                                color: 'titleMain.main',
                                fontSize: '18px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            1. Создаем новый пост
            </Typography>  
            <Typography variant="body2" 
                            gutterBottom 
                            align="left" 
                            width="100%"
                            maxWidth="600px"
                            sx={{marginTop: 2, paddingLeft: 2, paddingRight: 2, mb: 7, mr: 0, color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            &bull; Создаем новый пост, пишем к нему описание. В посте не ставим никакие хештеги! Размещаем.<br /><br />
            </Typography>              
            <Typography variant="h6" align="center" sx={{flexGrow: 1, mt:0, ml:0, mb: 0, paddingLeft: 0.5 }} color="titleMain.main">
                   {/* <img src={point1} alt="point 1" width={250} height={34}/> */}
                   <img src={point1} alt="point-1" width="280"  />
            </Typography>                
                            

            
            {/*///////////////////////////////////////////////////////////////////////////////////////////////////
               /////////////////////////////////////  2. Общие шаблоны                    ///////////////////////////
               //////////////////////////////////////////////////////////////////////////////////////////////////*/}
            <img src={line} alt="line" width="90%" />     
            <Typography     id="point2"
                            variant="body1" 
                            gutterBottom 
                            align="center" 
                            sx={{marginTop: 4, 
                                paddingLeft: 0,
                                color: 'titleMain.main',
                                fontSize: '18px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            2. Комментируем свой пост смайлом
            </Typography>
            <Typography variant="body2" 
                            gutterBottom 
                            align="left" 
                            width="100%"
                            maxWidth="600px"
                            sx={{marginTop: 2, paddingLeft: 2, paddingRight: 2, mb: 7, mr: 0, color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            &bull; Комментируем свой пост смайлом и отвечаем на этот комментарий (тот, что мы только что разместили "со смайлом"). 
            В этом ответе размещаем первый блок хештегов<br /><br />
            </Typography>                 
            <Typography variant="h6" align="center" sx={{flexGrow: 1, mt:0, paddingLeft: 0.5 }} color="titleMain.main">
                   {/* <img src={point1} alt="point 1" width={250} height={34}/> */}
                   <img src={point2} alt="point-2" width="280" />
            </Typography>
                                           


            {/*///////////////////////////////////////////////////////////////////////////////////////////////////
               /////////////////////////////////////  3. Выбор региона и городов          ///////////////////////////
               //////////////////////////////////////////////////////////////////////////////////////////////////*/} 
            <img src={line} alt="line" width="90%" />                   
            <Typography     id="point3"
                            variant="body1" 
                            gutterBottom 
                            align="center" 
                            sx={{marginTop: 4, 
                                paddingLeft: 0,
                                color: 'titleMain.main',
                                fontSize: '18px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            3. Удаляем комментарий со смайлом
            </Typography>
            <Typography variant="body2" 
                            gutterBottom 
                            align="left" 
                            width="100%"
                            maxWidth="600px"
                            sx={{marginTop: 2, paddingLeft: 2, paddingRight: 2, mb: 7, mr: 0, color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            &bull; Ждем примерно секунд 30. Удаляем комментарий со смайлом. <b>Не комментарий с хештегами!!!</b><br /><br />
            </Typography>                  
            <Typography variant="h6" align="center" sx={{flexGrow: 1, mt:0, paddingLeft: 0.5 }} color="titleMain.main">
                   {/* <img src={point1} alt="point 1" width={250} height={34}/> */}
                   <img src={point3} alt="point-3" width="280" />
            </Typography>       
            

            {/*///////////////////////////////////////////////////////////////////////////////////////////////////
               /////////////////////////////////////  4. Частотность (популярность) хештегов   /////////////////////
               //////////////////////////////////////////////////////////////////////////////////////////////////*/}                 
            <img src={line} alt="line" width="90%" /> 
            <Typography     id="point2"
                            variant="body1" 
                            gutterBottom 
                            align="center" 
                            sx={{marginTop: 4, 
                                paddingLeft: 0,
                                color: 'titleMain.main',
                                fontSize: '18px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            4. Делаем новый комментарий со смайлом
            </Typography>
            <Typography variant="body2" 
                            gutterBottom 
                            align="left" 
                            width="100%"
                            maxWidth="600px"
                            sx={{marginTop: 2, paddingLeft: 2, paddingRight: 2, mb: 7, mr: 0, color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            &bull; Делаем новый комментарий к своей публикации со смайлом и отвечаем на этот смайл новым блоком хештегов<br /><br />
            </Typography>
            <Typography variant="h6" align="center" sx={{flexGrow: 1, mt:0, paddingLeft: 0.5 }} color="titleMain.main">
                   {/* <img src={point1} alt="point 1" width={250} height={34}/> */}
                   <img src={point4} alt="point-4" width="280" />
            </Typography>
            

            {/*///////////////////////////////////////////////////////////////////////////////////////////////////
               /////////////////////////////////////  5. Язык                             ///////////////////////////
               //////////////////////////////////////////////////////////////////////////////////////////////////*/} 
            <img src={line} alt="line" width="90%" /> 
            <Typography     id="point5"
                            variant="body1" 
                            gutterBottom 
                            align="center" 
                            sx={{marginTop: 4, 
                                paddingLeft: 0,
                                color: 'titleMain.main',
                                fontSize: '18px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            5. Удаляем комменатрий со смайлом
            </Typography> 
            <Typography variant="body2" 
                            gutterBottom 
                            align="left" 
                            width="100%"
                            maxWidth="600px"
                            sx={{marginTop: 2, paddingLeft: 2, paddingRight: 2, mb: 7, mr: 0, color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            &bull; Ждем секунд 30 и удаляем комменатрий со смайлом<br /><br />
            </Typography>                
            <Typography variant="h6" align="center" sx={{flexGrow: 1, mt:0, paddingLeft: 0.5 }} color="titleMain.main">
                   {/* <img src={point1} alt="point 1" width={250} height={34}/> */}
                   <img src={point5} alt="point-5" width="280" />
            </Typography> 
            

            {/*///////////////////////////////////////////////////////////////////////////////////////////////////
               /////////////////////////////////////  6. Соцсети и количество хештегов    ///////////////////////////
               //////////////////////////////////////////////////////////////////////////////////////////////////*/} 
            <img src={line} alt="line" width="90%" /> 
            <Typography     id="point6"
                            variant="body1" 
                            gutterBottom 
                            align="center" 
                            sx={{marginTop: 4, 
                                paddingLeft: 0,
                                color: 'titleMain.main',
                                fontSize: '18px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            6. Размещаем третий блок хештегов
            </Typography>                 
            <Typography variant="h6" align="center" sx={{flexGrow: 1, mt:0, paddingLeft: 0.5 }} color="titleMain.main">
                   {/* <img src={point1} alt="point 1" width={250} height={34}/> */}
                   <img src={point6} alt="point-6" width="280" />
            </Typography>                 
            <Typography variant="body2" 
                            gutterBottom 
                            align="left" 
                            width="100%"
                            maxWidth="600px"
                            sx={{marginTop: 1, 
                                paddingLeft: 2,
                                paddingRight: 2,
                                mb: 7,
                                color: 'black.main',
                                fontSize: '14px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
             &bull; Делаем третий комментарий со смайлом. Отвечаем комментарием с третьим блоком хештегов. 
             Этот блок можно оставить, а можно удалить, если вы хотите скрыть воронку ваших хештегов от конкурентов.<br /><br />
            </Typography>

           
            {/*///////////////////////////////////////////////////////////////////////////////////////////////////
               /////////////////////////////////////  Мета экстремизм                 ///////////////////////////
               //////////////////////////////////////////////////////////////////////////////////////////////////*/} 
            <Typography variant="body2" 
                            gutterBottom 
                            align="left" 
                            width="100%"
                            maxWidth="600px"
                            sx={{marginTop: 1, 
                                paddingLeft: 2,
                                paddingRight: 2,
                                mb: 7,
                                color: 'n4.main',
                                fontSize: '12px', fontFamily: 'Clear Sans, Arial, sans-serif;', fontWeight: 100, fontStyle: 'normal',
                            }}>
            *Соцсети Instagram и Facebook запрещены в РФ; они принадлежат корпорации Meta, которая признана в РФ экстремистской.                  
            </Typography>
        </ThemeProvider> 
        
        </Box>
        </div>
    )
}

export {Block3Page};