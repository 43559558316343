import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
//import Box from '@mui/material/Box';
//import { List, ListItem, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
//import InboxIcon from '@mui/icons-material/MoveToInbox';
//import MailIcon from '@mui/icons-material/Mail';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ContextStore } from "../index"
import { observer } from "mobx-react-lite"
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeCol } from '../css/themes';
import Button from '@mui/material/Button';
import TagRoundedIcon from '@mui/icons-material/TagRounded';
import TollOutlinedIcon from '@mui/icons-material/TollOutlined';
import { ButtonProfile } from "../component/ButtonProfile"
import { ButtonRegistration } from "../component/ButtonRegistration"
//import TagIcon from '@mui/icons-material/Tag';

import { pulse  } from "react-animations";
import styled, { keyframes } from "styled-components";

import "../fonts/akrobat.css";
import "../fonts/gilroy.css";

import icons_coverage from "../images/homepage/icons_coverage.png";
import icons_follow from "../images/homepage/icons_follow.png";
import icons_actual from "../images/homepage/icons_actual.png";
import people_with_hashtag from "../images/homepage/people_with_hashtag.png";

import video_example from '../videos/homepage/video_example.mp4'








import AnimatedNumbers from "react-animated-numbers";












const PulseAnimation = keyframes`${pulse}`;
const PulseDiv = styled.div`
  animation: infinite 2s ${PulseAnimation};
`;

const HomePage = () => {
    
  const {store} = useContext(ContextStore);
  const themeColor = createTheme(themeCol);
  
  // eslint-disable-next-line
  const [num, setNum] = useState(16541981);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

    return(
        
        <div align="center">
        {document.title = "Главная / besthashtag.ru"}
                
        <Box //---BOX Частотностностью общих хештегов
                  sx={{
                        width: '95%',
                        maxWidth: "1000px",
                        marginTop: 12,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: 4,
                        borderColor: 'n2.main',
                        borderStyle: 'solid',
                        boxShadow: 0,
                        borderWidth: 0,
                        pt: 0,
                        pl: 0,
                        pr: 0,
                        pb: 0,
                  }}
        
                  >
        <ThemeProvider theme={themeColor}>
            {(store.isAuth) ? 
            <PulseDiv>
            <Button size="small" 
                    variant="contained"
                    component={Link} 
                    to={"/gettinghashtag"}
                    sx={{borderRadius: '30px',
                        bgcolor: 'n4.main',
                        color: 'white', 
                        '&:hover': {
                                    backgroundColor: 'n2.main',
                                    color: 'n4.main',
                        }, 
                        mt: 0, 
                        width: 300, 
                        height: 50,
                        fontSize: '20px', fontFamily: 'Akrobat, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
                        }}
            >
               # Начать работу #
            </Button></PulseDiv> : null }
            <Typography variant="body1" 
                            gutterBottom 
                            component="p"
                            align="center" 
                            sx={{marginTop: 3, 
                                mb:0,
                                paddingLeft: 0,
                                color: 'n4.main',
                                fontSize: '40px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 600,
                            }}>
            Хештеги, которые продают
            </Typography> 

            <Typography variant="body1" 
                            gutterBottom 
                            component="p"
                            align="center" 
                            sx={{marginTop: 5, 
                                mb:2,
                                paddingLeft: 0,
                                color: 'black.main',
                                fontSize: '16px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
                            }}>
            Наш сервис позволяет за несколько нажатий сформировать хештеги для Инстаграмм* или хештеги для Вконтакте, которые помогут поднять популярность и увеличить продажи.
            </Typography> 


             {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////
            //////////////////////////             СЕРВИС ЭТО              ///////////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Typography variant="body1" 
                            gutterBottom 
                            component="p"
                            align="center" 
                            sx={{marginTop: 5, 
                                mb:0,
                                paddingLeft: 0,
                                color: 'n5.main',
                                fontSize: '20px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 600,
                            }}>
            Наш сервис это:
            </Typography> 

            <Grid container spacing={1} width="100%" sx={{mt: 3, mb: 3, paddingLeft: 2, paddingRight: 2}}>
                
                <Grid item xs={4}>
                    <Typography variant="body1" align="center" sx={{width:"100%", maxWidth:"181px", flexGrow: 1, mt:0, ml:0, mb: 0, paddingLeft: 0.5 }} color="titleMain.main">
                    <img src={icons_coverage} alt="Охваты" width="100%" /><br />
                    </Typography>
                    
                    <Typography variant="body1" 
                            align="center" 
                            sx={{color: 'n5.main', fontSize: '16px', fontFamily: 'Akrobat, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 800,
                            }}>
                    Охваты<br />
                    </Typography> 
                    
                    <Typography variant="body1" 
                            align="center" 
                            sx={{mt:2, color: 'black.main', fontSize: '13px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
                            }}>
                    Увеличение охватов и количества лайков
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Typography variant="body1" align="center" sx={{width:"100%", maxWidth:"181px", flexGrow: 1, mt:0, ml:0, mb: 0, paddingLeft: 0.5 }} color="titleMain.main"> 
                    <img src={icons_follow} alt="Охваты" width="100%"  /><br />
                    </Typography>    

                    <Typography variant="body1" 
                            align="center" 
                            sx={{color: 'n5.main', fontSize: '16px', fontFamily: 'Akrobat, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 800,
                            }}>
                    Подписчики<br />
                    </Typography>

                    <Typography variant="body1" 
                            align="center" 
                            sx={{mt:2, color: 'black.main', fontSize: '13px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
                            }}>
                    Выход на целевую аудиторию и новых подписчиков
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <Typography variant="body1" align="center" sx={{width:"100%", maxWidth:"181px", flexGrow: 1, mt:0, ml:0, mb: 0, paddingLeft: 0.5 }} color="titleMain.main"> 
                    <img src={icons_actual} alt="Охваты" width="100%"  /><br />
                    </Typography>        
                    
                    <Typography variant="body1" 
                            align="center" 
                            sx={{color: 'n5.main', fontSize: '16px', fontFamily: 'Akrobat, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 800,
                            }}>
                    Актуальность<br />
                    </Typography>

                    <Typography variant="body1" 
                            align="center" 
                            sx={{mt:2, color: 'black.main', fontSize: '13px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
                            }}>
                    Только актуальные хештеги для увеличения продаж
                    </Typography>
                </Grid>
            </Grid>
            

            {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////
            //////////////////////////                РЕГИСТРАЦИЯ ИЛИ ЛИЧННЫЙ КАБИНЕТ     ////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}                
            {(store.isAuth) ? <ButtonProfile /> : <ButtonRegistration />}
            
            {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////
            //////////////////////////                 ОТЛИЧИЯ                 ///////////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Typography variant="body1" 
                            gutterBottom 
                            component="p"
                            align="center" 
                            sx={{marginTop: 3, 
                                mb:0,
                                paddingLeft: 0,
                                color: 'n5.main',
                                fontSize: '20px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 600,
                            }}>
            Мы выгодно отличаемся от других!
            </Typography> 

            <Typography variant="body1" 
                            gutterBottom 
                            component="p"
                            align="left" 
                            width="100%"
                            sx={{marginTop: 3, 
                                mb:0,
                                paddingLeft: 0,
                                color: 'black.main',
                                fontSize: '16px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
                            }}>
            <TagRoundedIcon sx={{fontSize: '18px', color: "lightLoginUser.main", verticalAlign: "middle",}}/>
            &nbsp;Наш ИИ формирует только актуальные тематические хештеги<br />
            <TagRoundedIcon sx={{fontSize: '18px', color: "lightLoginUser.main", verticalAlign: "middle",}}/>
            &nbsp;Только релевантные гео хештеги. К примеру, если вы выбрали город <dfn><font color="#769fcd">Москва</font></dfn>&nbsp;
            ИИ не предложит вам <dfn><font color="#769fcd">Красноярск</font></dfn><br />
            <TagRoundedIcon sx={{fontSize: '18px', color: "lightLoginUser.main", verticalAlign: "middle",}}/>
            &nbsp;Отсутствие орфографических ошибок и опечаток<br />
            <TagRoundedIcon sx={{fontSize: '18px', color: "lightLoginUser.main", verticalAlign: "middle",}}/>
            &nbsp;В нашей базе отсутствуют мусорные хештеги, которые не дают увеличения охватов (к примеру: <dfn><font color="#769fcd">#весна2018, #мойдедушкафотограф</font></dfn> и т.п.)<br />
            <TagRoundedIcon sx={{fontSize: '18px', color: "lightLoginUser.main", verticalAlign: "middle",}}/>
            &nbsp;Интуитивно понятный интерфейс, с которым легко работать<br />
            <TagRoundedIcon sx={{fontSize: '18px', color: "lightLoginUser.main", verticalAlign: "middle",}}/>
            &nbsp;Предоставление нашим клиентам бонусную полезную информацию, как правильно и эффективно использовать хештеги и наш сервис<br />
            </Typography>  

            {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////
            //////////////////////////                 Количество хештегов                ////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Typography variant="body1" 
                            gutterBottom 
                            component="p"
                            align="center" 
                            sx={{marginTop: 3, 
                                mb:2,
                                paddingLeft: 0,
                                color: 'n5.main',
                                fontSize: '20px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 600,
                            }}>
            Количество хештегов в нашей базе данных:
            </Typography> 
            {/* <Typography variant="body1" gutterBottom align="center" 
                            sx={{marginTop: 2, mb:0, paddingLeft: 0, color: 'errorUser.main',
                                fontSize: '40px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 600,
                            }}> */}
            <AnimatedNumbers
                            includeComma = {true}
                            //className={styles.container}
                            transitions={(index) => ({
                                type: "spring",
                                //type: "circOut",
                                //duration: index + 0.3,
                                duration: index,
                            })}
                            fontStyle={{
                                        fontSize: 40,
                                        color: '#ee4027',
                                        fontFamily: 'Akrobat, Helvetica, sans-serif',
                                        }}
                            animateToNumber={num}
                        />       
            {/* </Typography> */}
            {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////
            //////////////////////////                 КАТЕГОРИИ                 ///////////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Typography variant="body1" gutterBottom component="p" align="center" 
                            sx={{marginTop: 3, mb:0, paddingLeft: 0, color: 'n5.main',
                                fontSize: '20px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 600,
                            }}>
            Какие категории уже пользуются нашими хештегами
            </Typography>
            <Typography variant="body1" gutterBottom component="p" align="center" 
                            sx={{marginTop: 2, mb:0, paddingLeft: 0, color: 'lightLoginUser.main',
                                fontSize: '16px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 600,
                            }}>
            <i>Общая категория:</i>
            </Typography> 
            <Typography variant="body1" gutterBottom component="p" align="left" width="100%" 
                            sx={{marginTop: 3, mb:0, paddingLeft: 0, borderRadius: 0,
                                 borderColor: 'n2.main', borderStyle: 'solid', boxShadow: 0, borderWidth: 0,
                                color: 'black.main',
                                fontSize: '16px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
                            }}>
            <TagRoundedIcon sx={{fontSize: '18px', color: "lightLoginUser.main", verticalAlign: "middle",}}/>
            &nbsp;Теги для охвата<br /><br />
            <i>Подойдут абсолютно всем. Используйте эти хештеги не указывая специализации и вас увидит большое количество 
            пользователей из разных стран, городов, разных категорий и интересов. Если вы хотите, чтобы вас увидели 
            все пользователи в конкретных городах, просто выберите желаемые города в условиях при формировании хештегов.</i>
            </Typography>

            <Typography variant="body1" gutterBottom component="p" align="center" 
                            sx={{marginTop: 3, mb:0, paddingLeft: 0, color: 'lightLoginUser.main',
                                fontSize: '16px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 600,
                            }}>
            <i>Категории по специализации:</i>
            </Typography> 

            <Typography variant="body1" gutterBottom component="p" align="left" width="100%" 
                            sx={{marginTop: 3, mb:0, paddingLeft: 0, borderRadius: 0,
                                 borderColor: 'n2.main', borderStyle: 'solid', boxShadow: 0, borderWidth: 0,
                                color: 'black.main',
                                fontSize: '16px', fontFamily: 'Gilroy, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
                            }}>
            <TagRoundedIcon sx={{fontSize: '18px', color: "lightLoginUser.main", verticalAlign: "middle",}}/>
            &nbsp;Фотограф<br />
            <TagRoundedIcon sx={{fontSize: '18px', color: "lightLoginUser.main", verticalAlign: "middle",}}/>
            &nbsp;Видеограф<br />
            <TagRoundedIcon sx={{fontSize: '18px', color: "lightLoginUser.main", verticalAlign: "middle",}}/>
            &nbsp;Визажист<br />
            <TagRoundedIcon sx={{fontSize: '18px', color: "lightLoginUser.main", verticalAlign: "middle",}}/>
            &nbsp;Стилист<br />
            <TagRoundedIcon sx={{fontSize: '18px', color: "lightLoginUser.main", verticalAlign: "middle",}}/>
            &nbsp;Салон красоты<br />
            <TagRoundedIcon sx={{fontSize: '18px', color: "lightLoginUser.main", verticalAlign: "middle",}}/>
            &nbsp;Флорист<br />
            <TagRoundedIcon sx={{fontSize: '18px', color: "lightLoginUser.main", verticalAlign: "middle",}}/>
            &nbsp;Магазин цветов<br />
            <TagRoundedIcon sx={{fontSize: '18px', color: "lightLoginUser.main", verticalAlign: "middle",}}/>
            &nbsp;Китайская метафизика (Фэн-шуй, Ба Цзы)<br />
            <TagRoundedIcon sx={{fontSize: '18px', color: "lightLoginUser.main", verticalAlign: "middle",}}/>
            &nbsp;СММ (SMM, Маркетинг и т.п.)<br />
            <TagRoundedIcon sx={{fontSize: '18px', color: "lightLoginUser.main", verticalAlign: "middle",}}/>
            &nbsp;Ландшафтный дизайн (Проектирование сада, удох за садом и т.п.)<br /><br />
            <i>Если вы не нашли своей категории, обратитесь к нам, мы подберем для вас хештеги индивидуально. 
            В данный момент в разработке несколько новых категорий. Вы можете узнать о них, обратившись в службу поддержки.</i>
            </Typography>



            {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////
            //////////////////////////                ТАРИФЫ                  ///////////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}                
            <Button size="small" 
                    variant="contained"
                    startIcon={<TollOutlinedIcon />} 
                    component={Link} 
                    to={"/pricing"}
                    sx={{borderRadius: '10px',
                        bgcolor: 'n3.main',
                        color: 'black.main', 
                        '&:hover': {
                                    backgroundColor: 'n2.main'
                        }, 
                        mt: 7, 
                        width: 300, 
                        height: 40,
                        fontSize: '16px', fontFamily: 'Akrobat, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 400,
                        //fontSize: '16px', fontFamily: 'Akrobat, Trebuchet MS, Helvetica, sans-serif;', fontWeight: 800,
                        }}
                    //onClick={addTownToChoose}
                    >
                Тарифы
            </Button>                


            {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////
            //////////////////////////              ВИДЕО ПРИМЕР                   ///////////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Typography variant="body1" 
                            gutterBottom 
                            component="p"
                            align="center" 
                            sx={{marginTop: 6, 
                                mb:2,
                                paddingLeft: 0,
                                color: 'n5.main',
                                fontSize: '20px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 600,
                            }}>
            Как это работает?
            </Typography>
            <Typography variant="body1" align="center" sx={{ borderRadius: 0,
                        borderColor: 'n2.main',
                        borderStyle: 'solid', boxShadow: 3,
                        borderWidth: 1, width:"100%", maxWidth:"700px", flexGrow: 1, mt:0, ml:0, mb: 4, paddingLeft: 0 }} color="titleMain.main"> 
            <video width="100%" controls >
                <source src={video_example} type="video/mp4"/>
            </video>
            </Typography>


           {/* //////////////////////////////////////////////////////////////////////////////////////////////////////////
            //////////////////////////                РЕГИСТРАЦИЯ ИЛИ ЛИЧННЫЙ КАБИНЕТ     ////////////////////////////////
            ////////////////////////////////////////////////////////////////////////////////////////////////////////// */}                
            {(store.isAuth) ? <ButtonProfile /> : <ButtonRegistration />}     


            {/* <Typography variant="h7" 
                            gutterBottom 
                            component="p"
                            align="center" 
                            sx={{marginTop: 2,
                                mb: 2, 
                                paddingLeft: 0,
                                color: 'n4.main',
                                //fontSize: '26px', 
                                fontSize: '18px', fontFamily: 'Clear Sans, Arial, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                            }}>
            {store.isAuth ? `Добро пожаловать, ${store.user.email.toLowerCase()}` : 'Добро пожаловать, Гость' }  <br />            
            </Typography>   */}
        

                <Typography variant="body1" align="center" sx={{width:"100%", maxWidth:"400px", flexGrow: 1, mt:0, ml:0, mb: 0, paddingLeft: 0.5 }} color="titleMain.main">
                    <img src={people_with_hashtag} alt="лого" width="100%" /><br />
                </Typography>  


                {/*///////////////////////////////////////////////////////////////////////////////////////////////////
               /////////////////////////////////////  Мета экстремизм                 ///////////////////////////
               //////////////////////////////////////////////////////////////////////////////////////////////////*/} 
               <Typography variant="body2" 
                            gutterBottom 
                            align="left" 
                            width="100%"
                            sx={{marginTop: 8, 
                                paddingLeft: 2,
                                paddingRight: 2,
                                mb: 0,
                                color: 'n4.main',
                                fontSize: '12px', fontFamily: 'Clear Sans, Arial, sans-serif;', fontWeight: 100, fontStyle: 'normal',
                            }}>
            *Соцсети Instagram и Facebook запрещены в РФ; они принадлежат корпорации Meta, которая признана в РФ экстремистской.                  
            </Typography>
        </ThemeProvider>    
        </Box>
        </div>
    )
}

export default observer(HomePage);