import { makeAutoObservable } from "mobx";
import AuthService from "../services/AuthService";
import axios from "axios";
import { API_URL } from "../http";

export default class Store {    //------------Класс глобального хранилища
    //user: {email: userData.email, 
    //       userID: userData.userID, 
    //       isActivated: userData.isActivated,
    //       isPay: userData.isPay
    //       category: userData.category, 
    //       userName: userData.userName, 
    //       userSex: userData.sex);
    //}
    user = [];
    isAuth = false;
    status = 0;
    
    errorStatusMessage = "";
    jsonLocalStorage = '';
    //userEmailForStatus = "";

    constructor() {
        // Неуверен насколько верны такие костыли
        if (localStorage.getItem('token')) {
            this.isAuth = true;
            //console.log(localStorage.getItem('token'))
        }
        makeAutoObservable(this);
    }

    setStatus(status) {
        this.status = status;
    }
    
    //           -----------------------------  ПОльзователь ----------------------------
    setAuth(bool) {
        this.isAuth = bool;
    }

    setUser(user) {
        this.user = user;

    }

    setErrorStatusMessage (textMessage) {
        this.errorStatusMessage = textMessage;
    }

    setUserEmailForStatus (userEmail) {
        this.userEmailForStatus = userEmail;
    }

    setJsonLocalStorage (props) {
        this.jsonLocalStorage = props;
    }


    async login (email, password) {                 
        try {
            const response = await AuthService.login(email.toLowerCase(), password);
            if (response.status === 200) {
                localStorage.setItem('token', response.response.accessToken);
                this.setAuth(true);
                this.setStatus(response.status);
                this.setErrorStatusMessage("");
                this.setUser(response.response.user);
            } else {
                this.setAuth(false);
                this.setStatus(response.status);
                this.setErrorStatusMessage(response.response);
                localStorage.removeItem('token');
                console.log(response.response);       
            }       
        } catch(e) {
            console.log(e);
        }
    }


    async registration (email, password, userName, sex) {
        try {
            const response = await AuthService.registration(email.toLowerCase(), password, userName, sex);
            if (response.status === 200) {
                localStorage.setItem('token', response.response.accessToken);
                this.setAuth(true);
                this.setStatus(response.status);
                this.setUser(response.response.user);
                //this.setCategory(response.response.category);
            } else {
                this.setStatus(response.status);
                this.setErrorStatusMessage(response.response);
                localStorage.removeItem('token');
                console.log(response.response);       
            }       
        } catch(e) {
            console.log(e);
        }
    }

    async logout() {
        try {
            const response = await AuthService.logout();
            localStorage.removeItem('token');
            this.setAuth(false);
            this.setUser([]);
            this.setStatus(response.status);
        } catch(e) {
            console.log(e);
        }
    }

    async  checkAuth () {
        try {
            console.log('checkAuth');
            const response = await axios.get(`${API_URL}/refresh`, {withCredentials: true})
            localStorage.setItem('token', response.data.accessToken);
            this.setAuth(true);
            this.setUser(response.data.user);
        } catch(e) {
            localStorage.removeItem('token');
            this.setAuth(false);
            this.setUser([]);
            console.log(e.response?.data?.message);
        }
    }


    async recoveryPassword (email) {                 
        try {
            const response = await AuthService.recoveryPassword(email.toLowerCase());
            if (response.status === 200) {
                //localStorage.setItem('token', response.response.accessToken);
                //this.setAuth(true);
                this.setStatus(response.status);
                this.setErrorStatusMessage(response.response.countRows); // Ставим в store Единицу (1) в response
                //this.setUser(response.response.user);
                //console.log('200');
                //console.log(response.response);
            } else {
                //this.setAuth(false);
                this.setStatus(response.status);
                this.setErrorStatusMessage(response.response);
                //localStorage.removeItem('token');
                //console.log(response.response);       
            }       
        } catch(e) {
            console.log(e);
        }
    }


    // handleResize = () => {
    //     if (window.innerWidth < 580) {
    //         //this.setUserEmailForStatus('');
    //         this.setIsMobile(true);
    //     } else {
    //     //     if (this.isAuth) {
    //     //         this.setUserEmailForStatus(this.user.email);    
    //     //     } else {
    //     //         this.setUserEmailForStatus('Гость');
    //     //     }
    //         this.setIsMobile(false);
    //     }
    // }

}