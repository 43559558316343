import * as React from 'react';
import { useState, useContext, useEffect } from "react";
import { Link, Outlet } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { List, ListItem, ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import Drawer from '@mui/material/Drawer';
 import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ContextStore } from '../index';
import { observer } from "mobx-react-lite"
import Logout from '@mui/icons-material/Logout';
import Divider from '@mui/material/Divider';

import CookieConsent from "react-cookie-consent";   

import "../App.css";
import "../fonts/eurofurence.css";


//import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import AppRegistrationRoundedIcon from '@mui/icons-material/AppRegistrationRounded';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TollOutlinedIcon from '@mui/icons-material/TollOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import PetsOutlinedIcon from '@mui/icons-material/PetsOutlined';
import TagIcon from '@mui/icons-material/Tag';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';


import logo from "../images/logoBH1-250x34.png";
import { themeCol } from '../css/themes';


function Layout () {
  
  const {store} = useContext(ContextStore);

  const themeColor = createTheme(themeCol);

  const [userStatusColor, setUserStatusColor] = useState('white');
  
  useEffect(() => {
    function setParametrs () {
      
      if (!store.isAuth) {
        setUserStatusColor("errorUser");
      } else {
        if (store.isAuth && store.user.isPay) {
           setUserStatusColor("loginUser");
           return;
        } 
        if (store.isAuth && !store.user.isPay) {
            setUserStatusColor("guestUserDark");
            return;
        }
        if (store.isAuth && !store.user.isActivated) {
          setUserStatusColor("guestUserDark");
          return;
        }
      }}

    
    setParametrs ();
  }, [store.isAuth, store?.user?.isPay, store?.user?.isActivated]); 


  function Copyright(props) {
    return (
      <Typography variant="body2" color="#FFFFFF" align="center"  {...props} 
                  sx ={{color: 'n5.main',
                        fontSize: '14px', fontFamily: 'Akrobat, Helvetica, sans-serif;', 
                        fontWeight: 400, fontStyle: 'normal',
                      }}>
        {'copyright © '}
        <Link color="#FFFFFF" href="https://besthashtag.ru/" textDecoration="none">
          besthashtag.ru
        </Link>{' '}
        {new Date().getFullYear()}
      </Typography>
    );
}


  const [anchorEl, setAnchorEl] = useState(null);
  const openRightMenu = Boolean(anchorEl);

  // const captionAppBarUser = () => {
  //   if (store.isMobile) {
  //     return " ";
  //   } 

  //   if (store.isAuth && !store.isMobile && !openRightMenu) {
  //     return store.user.email;
  //   }

  //   if (!store.isAuth && !store.isMobile && !openRightMenu) {
  //     return "Гость";
  //   }  
  // }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleLogout = async (event) => {
    await store.logout();
    setAnchorEl(null);
  }

  const [stateLeftMenu, setStateLeftMenu] = useState(false);

  const toggleDrawer1= (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setStateLeftMenu(open);
  };

  const list = (anchor) => (
    
    <Box
      sx={{ width:  250, backgroundColor: "n1.main", height: '100%'}}
      role="presentation"
      onClick={toggleDrawer1(false)}
      onKeyDown={toggleDrawer1(false)}
    >
      <List>
          {/*************************************************************************** */}
          {/**************************    НАСТРОЙКИ ДЛЯ ПУНКТОВ МЕНЮ      ************* */}
          {/*************************************************************************** */}
          <ListItem disablePadding component={Link} to={"/"} 
             >
            <ListItemButton sx={{ //height: 35, 
                                }}>
              <ListItemIcon sx={{ //height: 35, 
                                  alignItems: 'center',
                                }} >
                <CottageOutlinedIcon sx={{ 
                                           color: "n5.main", 
                                           fontSize: 20, 
                                         }}  />
              </ListItemIcon>
              <ListItemText primary={'ДОМОЙ'} primaryTypographyProps={{ display: 'tableCell', 
                                                                        //height: 35 , 
                                                                        color: "n5.main", 
                                                                        fontSize: 18, 
                                                                        fontFamily: "Eurofurence, Arial",  fontWeight: 400,
                                                                      }} 
              /> 
            </ListItemButton>
          </ListItem>
  
          <ListItem disablePadding component={Link} to={"/gettinghashtag"} sx={{ color: "n5.main" }}>
            <ListItemButton>
              <ListItemIcon>
                <TagIcon   sx={{ color: "n5.main", fontSize: 20, }}/>
              </ListItemIcon>
              <ListItemText primary={'ХЕШТЕГИ'}  primaryTypographyProps={{ display: 'tableCell', 
                                                                        //height: 35 , 
                                                                        color: "n5.main", 
                                                                        fontSize: 18, 
                                                                        fontFamily: "Eurofurence, Arial",  fontWeight: 400,
                                                                      }} 
              />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding component={Link} to={"/pricing"} sx={{ color: "n5.main" }}>
            <ListItemButton>
              <ListItemIcon>
                <TollOutlinedIcon   sx={{ color: "n5.main", fontSize: 20, }}/>
              </ListItemIcon>
              <ListItemText primary={'ТАРИФЫ'}  primaryTypographyProps={{ display: 'tableCell', 
                                                                        //height: 35 , 
                                                                        color: "n5.main", 
                                                                        fontSize: 18, 
                                                                        fontFamily: "Eurofurence, Arial",  fontWeight: 400,
                                                                      }} 
              />
            </ListItemButton>
          </ListItem>
          
          <ListItem disablePadding component={Link} to={"/guide"} sx={{ color: "n5.main" }}>
            <ListItemButton>
              <ListItemIcon>
                <AutoStoriesOutlinedIcon   sx={{ color: "n5.main", fontSize: 20, }}/>
              </ListItemIcon>
              <ListItemText primary={'РУКОВОДСТВО'}  primaryTypographyProps={{ display: 'tableCell', 
                                                                        //height: 35 , 
                                                                        color: "n5.main", 
                                                                        fontSize: 18, 
                                                                        fontFamily: "Eurofurence, Arial",  fontWeight: 400,
                                                                    }}/>
            </ListItemButton>
          </ListItem>
          
          <ListItem disablePadding component={Link} to={"/registration"}  sx={{ color: "n5.main" }}>
            <ListItemButton>
              <ListItemIcon>
                <AppRegistrationRoundedIcon  sx={{ color: "n5.main", fontSize: 20, }}/>
              </ListItemIcon>
              <ListItemText primary={'РЕГИСТРАЦИЯ'}  primaryTypographyProps={{ display: 'tableCell', 
                                                                              //height: 35 , 
                                                                              color: "n5.main", 
                                                                              fontSize: 18, 
                                                                              fontFamily: "Eurofurence, Arial",  fontWeight: 400,
                                                                             }}/>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding component={Link} to={"/login"}  sx={{ color: "n5.main" }}>
            <ListItemButton>
              <ListItemIcon>
              <PetsOutlinedIcon  sx={{ color: "n5.main", fontSize: 20, }}/>
              </ListItemIcon>
              <ListItemText primary={'ВОЙТИ'}  primaryTypographyProps={{ display: 'tableCell', 
                                                                        //height: 35 , 
                                                                        color: "n5.main", 
                                                                        fontSize: 18, 
                                                                        fontFamily: "Eurofurence, Arial",  fontWeight: 400,
                                                                       }}/>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding component={Link} to={"/contact"}  sx={{ color: "n5.main" }}>
            <ListItemButton>
              <ListItemIcon>
                <LocalPhoneOutlinedIcon   sx={{ color: "n5.main", fontSize: 20, }}/>
              </ListItemIcon>
              <ListItemText primary={'КОНТАКТЫ'}  primaryTypographyProps={{ display: 'tableCell', 
                                                                            //height: 35 , 
                                                                            color: "n5.main", 
                                                                            fontSize: 18, 
                                                                            fontFamily: "Eurofurence, Arial",  fontWeight: 400,
                                                                          }}/>
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding component={Link} to={"/privacy"}  sx={{ color: "n5.main" }}>
            <ListItemButton>
              <ListItemIcon>
                <SecurityOutlinedIcon   sx={{ color: "n5.main", fontSize: 20, }}/>
              </ListItemIcon>
              <ListItemText primary={'ПРИВАТНОСТЬ'}  primaryTypographyProps={{ display: 'tableCell', 
                                                                            //height: 35 , 
                                                                            color: "n5.main", 
                                                                            fontSize: 18, 
                                                                            fontFamily: "Eurofurence, Arial",  fontWeight: 400,
                                                                          }}/>
            </ListItemButton>
          </ListItem>

      </List>
    </Box>
  );



    return(
      <div>
        <ThemeProvider theme={themeColor}>
        <Box sx={{ flexGrow: 1 }}>
          <CssBaseline />
          <AppBar position="fixed" 
                  color="n1_v2">
             <Toolbar>
              
              {/********************************************************************************************/  
              /*************************        ИКОНКА ЛЕВОГО МЕНЮ        *********************************/  
              /********************************************************************************************/  }
              <IconButton
                          size="large"
                          edge="start"
                          color="n2"
                          aria-label="menu"
                          onClick={toggleDrawer1(true)}
                          sx={{ mr: 2}}
              >
                <MenuIcon sx={{ fontSize: 25 }}/>
              </IconButton>
              
              {/********************************************************************************************/  
              /*************************        ЛЕВОЕ БОКОВОЕ МЕНЮ        *********************************/  
              /********************************************************************************************/  }
              <React.Fragment key={'left'}>
                <Drawer
                  anchor={'left'}
                  open={stateLeftMenu}
                  onClose={toggleDrawer1(false)}>
                  {list('left')}
                </Drawer>
              </React.Fragment>

              {/********************************************************************************************/  
              /*************************        LOGO besthashtag.ru       *********************************/  
              /********************************************************************************************/  }
              <Typography variant="h6" align="center" component={Link} to={"/"} sx={{ flexGrow: 1, mt:1.5 }} color="titleMain.main">
                   <img src={logo} alt="logo" width={250} height={34}/>
              </Typography>

              {/* <Typography variant="h6" align="center" component="div" sx={{ flexGrow: 1, mt:1.5 }} color="titleMain.main">
                   <img src={logo} alt="logo" width={250} height={34}/>
              </Typography> */}


              {/********************************************************************************************/  
              /*************************        ГОСТЬ или электронная почта       *************************/  
              /********************************************************************************************/}
              {/* <Typography variant="caption" 
                          className='noselect'
                          color={store.isAuth ? 'loginUser.main' : 'errorUser.main'}
                          sx={{ mr: 1, mt:0.5,
                                 fontSize: 16, 
                                 fontFamily: "century gothic",
                              }}>
                {(store.isMobile) ? '' : (!store.isAuth && !store.isMobile && !openRightMenu) ? 'Гость' : (store.isAuth && !store.isMobile && !openRightMenu) ? store.user.email : ""}              
               
              </Typography> */}
              <IconButton
                size="large"
                sx={{ fontSize: 40 }}
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                aria-expanded={openRightMenu ? 'true' : undefined}
                onClick={handleMenu}
                color={userStatusColor}
                //color={store.isAuth ? 'loginUser' : 'errorUser'}
              >
                <PersonOutlineOutlinedIcon sx={{ fontSize: 30 }} />
              </IconButton>

                  {!store.isAuth && //*************************   ГОСТЕВОЕ МЕНЮ   ************************/
                      <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            keepMounted
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            open={openRightMenu}
                            onClose={handleClose}
                            PaperProps={{
                                         
                                        //borderRadius: 4,
                                        elevation: 0,
                                        //bgcolor: 'n3.main', 
                                        sx: {
                                          //bgcolor: 'n3.main', 
                                          borderRadius: 2,
                                          overflow: 'visible',
                                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                          mt: 0,
                                          '& .MuiAvatar-root': {
                                            
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                          },
                                          '&:before': {
                                            //bgcolor: 'n3.main', 
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 25,
                                            width: 10,
                                            height: 10,
                                            //bgcolor: 'error',
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                          },
                                        },
                                        }}
                              //  sx={{
                              //   bgcolor: 'n3.main',
                              //   borderRadius: 4,
                              //  }}         
                      >
                        {/* /********************  ГОСТЬ **************************/}
                        <MenuItem //onClick={handleClose} 
                                  //disabled="true"
                                  sx={{
                                      height: 35 ,
                                      justifyContent: 'center',
                                      alignContent: 'space-between',
                                      // "&.Mui-disabled": {
                                      //         borderBottom: '2px dotted',
                                      //         color: 'n5.main'
                                      //                    },
                                     }}>
                          <Typography variant="button" 
                                      noWrap 
                                      sx={{ 
                                          mt: 1,        
                                          height: 35 , 
                                          color: "errorUser.main",  
                                          fontSize: 18, fontFamily: "Eurofurence, Arial",  fontWeight: 400,
                                          }}>
                            {!(store.user?.email) ? "ГОСТЬ" : store.user.email}
                          </Typography>
                        </MenuItem>
                        <Divider /> 

                        <MenuItem component={Link} 
                                  to={"/login"} 
                                  sx={{ color: "n5.main", 
                                  fontSize: 18, fontFamily: "Eurofurence, Arial",  fontWeight: 400,
                                      }}
                                  onClick={handleClose}>
                          <ListItemIcon>
                            <PetsOutlinedIcon fontSize="small"    sx={{ color: "n5.main", fontSize: 20, }}/>
                          </ListItemIcon>
                          <Typography variant="inherit" noWrap>
                            ВОЙТИ
                          </Typography>
                        </MenuItem>
                        
                        <MenuItem component={Link} 
                                  to={"/registration"} 
                                  sx={{ color: "n5.main", 
                                  fontSize: 18, fontFamily: "Eurofurence, Arial",  fontWeight: 400, 
                                      }}
                                  onClick={handleClose}
                          >
                          <ListItemIcon>
                            <AppRegistrationRoundedIcon fontSize="small"   sx={{ color: "n5.main", fontSize: 20, }}/>
                          </ListItemIcon>
                          <Typography variant="inherit" noWrap>
                            РЕГИСТРАЦИЯ
                          </Typography>
                        </MenuItem>
                      </Menu>
                  }

                  {store.isAuth && //*************************   ЗАЛОГИНИНЫ     ********************/
                      <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            keepMounted
                            open={openRightMenu}
                            onClose={handleClose}
                            PaperProps={{
                                        elevation: 0,
                                        sx: {
                                           borderRadius: 2,
                                          overflow: 'visible',
                                          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                          mt: 0,
                                          '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                          },
                                          '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 25,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                          },
                                        },
                                        }}
                      >
                        {/* /********************  ИМЯ ПОЛЬЗОВАТЕЛЯ **************************/}
                        <MenuItem //onClick={handleClose} 
                                  sx={{
                                      height: 35 ,
                                      justifyContent: 'center',
                                      alignContent: 'space-between',
                                     }}>
                          <Typography variant="button" 
                                      noWrap 
                                      sx={{ 
                                          mt: 1,        
                                          height: 35 , 
                                          color: "loginUser.main", 
                                          fontSize: 18, 
                                          fontFamily: "Eurofurence, Arial",  fontWeight: 400,
                                          }}>
                            {!(store.user?.email) ? "Гость" : store.user.email}
                          </Typography>
                        </MenuItem>
                        <Divider /> 
                        
                        {/* /********************  ЛИЧНЫЙ КАБИНЕТ **************************/}     
                        <MenuItem component={Link} 
                                  to={"/profile"}
                                  onClick={handleClose}>
                          <ListItemIcon>
                            <AccountCircleIcon fontSize="small" sx={{ color: "n5.main", }} />
                          </ListItemIcon>
                          <Typography variant="body1" noWrap  sx={{ color: "n5.main", fontSize: 18, fontFamily: "Eurofurence, Arial",  fontWeight: 400,}}>
                            ЛИЧНЫЙ КАБИНЕТ
                          </Typography>  
                        </MenuItem>

                        {/* /********************  ВЫЙТИ *************************  */}
                        <MenuItem onClick={handleLogout}>
                          <ListItemIcon>
                            <Logout fontSize="small" sx={{ color: "n5.main" }} />
                          </ListItemIcon>
                          <Typography variant="body1" noWrap  sx={{ color: "n5.main", fontSize: 18, fontFamily: "Eurofurence, Arial",  fontWeight: 400,}}>
                            ВЫЙТИ
                          </Typography>
                        </MenuItem>
                      </Menu>
                  }  
            </Toolbar>
          </AppBar> 
        </Box>
                
                    <Outlet  />

        {/****************************************************************************************/
         /********************************   Футер                  ****************************/}      
        <Box sx={{ flexGrow: 1 }} marginTop={10}>
          <AppBar  position="fixed" sx={{ top: 'auto', bottom: 0 }} color="n1" > 
          <CookieConsent
                //debug={true}
                location="top"
                overlay="true"  
                buttonText="Я согласен"
                cookieName="CookieAgry"
                style={{ background: "#2B373B" }}
                buttonStyle={{ align: "right", color: "#4e503b", fontSize: "12px" }}
                expires={365}
          >
            Наш сайт использует куки. Продолжая им пользоваться, вы соглашаетесь на обработку персональных данных
          </CookieConsent>
            <Typography variant="h7" 
                        component="div" sx={{ 
                                            flexGrow: 1,
                                            color: 'n5.main',
                                            fontSize: '18px', fontFamily: 'Clear Sans, Arial, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                                            }} 
                        align="center" 
                        marginTop={2} 
                        marginBottom={2}>
              <Copyright />
            </Typography>
          </AppBar>
          <Toolbar />
        </Box>
        </ThemeProvider>
      </div>
  )
    
}    
    
export default observer(Layout);