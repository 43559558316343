import React from "react";
import Typography from '@mui/material/Typography';
import { observer } from "mobx-react-lite"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { themeCol } from '../../css/themes';
import { ButtonLogin } from "../../component/ButtonLogin"

import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import "../../fonts/akrobat.css";
import "../../fonts/gilroy.css";


const SuccessRecoveryPassword = () => {
    
    //const {store} = useContext(ContextStore);
    const themeColor = createTheme(themeCol);

    return(
        <div align="center">
        <ThemeProvider theme={themeColor}>
        <Avatar sx={{ mb: 4, marginTop: 16, bgcolor: 'errorUser.main' }}>
                <AccountCircleIcon />
            </Avatar>
            <Typography variant="body1" 
                                gutterBottom 
                                align="center" 
                                sx={{marginTop: 0, 
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    color: 'errorUser.main',
                                    fontSize: '40px', fontFamily: 'Akrobat, Helvetica, sans-serif;', fontWeight: 200,
                                }}>
                Пароль изменён
            </Typography>
            <Typography variant="body1" 
                                gutterBottom 
                                align="center" 
                                sx={{marginTop: 3, 
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    color: 'n5.main',
                                    fontSize: '18px', fontFamily: 'Gilroy, Arial, sans-serif;', fontWeight: 400, fontStyle: 'normal',
                                }}>
                Новый пароль выслан вам на почту.
            </Typography>
        
        <ButtonLogin />       
        
        </ThemeProvider>    
        </div>
    )
}

export default observer(SuccessRecoveryPassword);